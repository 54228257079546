const DeniedImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      height="200"
      width={width || "360"}
      className={classes}
      viewBox="0 0 360 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M266 15.204L274.796 24L278 12L266 15.204Z" fill="#91BEFD" />
      <path
        d="M273.5 152C275.433 152 277 150.433 277 148.5C277 146.567 275.433 145 273.5 145C271.567 145 270 146.567 270 148.5C270 150.433 271.567 152 273.5 152Z"
        fill="#FFCC75"
      />
      <path
        d="M75.8726 38.0178C75.0781 38.0178 74.4453 37.3704 74.4453 36.5906V33.4272C74.4453 32.6327 75.0928 32 75.8726 32C76.6672 32 77.2998 32.6474 77.2998 33.4272V36.5906C77.3145 37.3704 76.6672 38.0178 75.8726 38.0178Z"
        fill="#66DCAB"
      />
      <path
        d="M75.8726 47.7404C75.0781 47.7404 74.4453 47.093 74.4453 46.3132V43.1499C74.4453 42.3553 75.0928 41.7227 75.8726 41.7227C76.6672 41.7227 77.2998 42.37 77.2998 43.1499V46.3132C77.3145 47.093 76.6672 47.7404 75.8726 47.7404Z"
        fill="#66DCAB"
      />
      <path
        d="M77.7246 39.8725C77.7246 39.078 78.372 38.4453 79.1518 38.4453H82.3152C83.1097 38.4453 83.7423 39.0927 83.7423 39.8725C83.7423 40.667 83.095 41.2997 82.3152 41.2997H79.1518C78.372 41.2997 77.7246 40.6523 77.7246 39.8725Z"
        fill="#66DCAB"
      />
      <path
        d="M68 39.8725C68 39.078 68.6475 38.4453 69.4273 38.4453H72.5907C73.3853 38.4453 74.0179 39.0927 74.0179 39.8725C74.0179 40.667 73.3705 41.2997 72.5907 41.2997H69.4273C68.6475 41.2997 68 40.6523 68 39.8725Z"
        fill="#66DCAB"
      />
      <rect x="100" y="47" width="160" height="106" fill="#F5F8FB" />
      <path
        d="M100 44C100 39.5817 103.582 36 108 36H252C256.418 36 260 39.5817 260 44V47H100V44Z"
        fill="#6B7684"
      />
      <path
        d="M260 156C260 160.418 256.418 164 252 164L108 164C103.582 164 100 160.418 100 156L100 153L260 153L260 156Z"
        fill="#CCD1D9"
      />
      <circle cx="108.948" cy="41.4324" r="2.63158" fill="#F6705E" />
      <circle cx="117.368" cy="41.4324" r="2.63158" fill="#FFC34E" />
      <circle cx="125.79" cy="41.4324" r="2.63158" fill="#F5F8FB" />
      <circle
        cx="180"
        cy="100"
        r="33"
        fill="#F5F8FB"
        stroke="#F77E6E"
        stroke-width="8"
      />
      <path
        d="M201 80L160 121"
        stroke="#F77E6E"
        stroke-width="8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="52.0254"
        y="128"
        width="8.52084"
        height="8.52084"
        transform="rotate(45 52.0254 128)"
        fill="#FFE0A3"
      />
      <rect
        x="303.949"
        y="63"
        width="7"
        height="7"
        transform="rotate(45 303.949 63)"
        fill="#64D2D0"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default DeniedImage;
