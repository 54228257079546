const NoDashboardImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "360"}
      height="200"
      className={classes}
      viewBox="0 0 361 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="174.5" y="27" width="84" height="108" fill="#DDE1E7" />
      <circle cx="191" cy="41.5" r="6.5" fill="#64D2D0" />
      <rect
        width="84"
        height="12"
        transform="matrix(1 0 1.04361e-06 -1 174.5 135)"
        fill="#DDE1E7"
      />
      <rect x="184.5" y="68" width="64" height="2.99999" fill="#DDE1E7" />
      <rect x="184.5" y="59" width="64" height="2.99999" fill="#DDE1E7" />
      <rect x="184.5" y="98" width="64" height="2.99999" fill="#DDE1E7" />
      <rect x="184.5" y="89" width="64" height="2.99999" fill="#DDE1E7" />
      <rect
        x="102.984"
        y="47"
        width="12"
        height="12"
        transform="rotate(45 102.984 47)"
        fill="#FFC34E"
      />
      <rect x="164.5" y="17" width="84" height="108" fill="#EBF0F2" />
      <circle cx="182.5" cy="33" r="8" fill="#64D2D0" />
      <rect
        width="84"
        height="12"
        transform="matrix(1 0 1.04361e-06 -1 164.5 125)"
        fill="#CCD1D9"
      />
      <rect x="174.5" y="59" width="64" height="4" fill="#D0D5D8" />
      <rect x="174.5" y="49" width="64" height="4" fill="#D0D5D8" />
      <rect x="174.5" y="88" width="64" height="2.99999" fill="#D0D5D8" />
      <rect x="174.5" y="79" width="64" height="2.99999" fill="#D0D5D8" />
      <rect x="108.5" y="75" width="118" height="84" rx="6" fill="#4593FC" />
      <rect
        x="216.5"
        y="145"
        width="50"
        height="6"
        transform="rotate(-180 216.5 145)"
        fill="#1B64DA"
      />
      <rect
        x="216.5"
        y="109"
        width="88"
        height="5.99999"
        transform="rotate(-180 216.5 109)"
        fill="#1B64DA"
      />
      <rect
        x="124.5"
        y="109"
        width="5.99877"
        height="5.99877"
        transform="rotate(-180 124.5 109)"
        fill="#1B64DA"
      />
      <rect x="128.5" y="89" width="51" height="6" fill="#1B64DA" />
      <rect
        x="124.5"
        y="95"
        width="5.99971"
        height="5.99971"
        transform="rotate(-180 124.5 95)"
        fill="#1B64DA"
      />
      <rect
        x="279.449"
        y="125"
        width="7"
        height="7"
        transform="rotate(45 279.449 125)"
        fill="#FFE0A3"
      />
      <rect
        x="131.449"
        y="7"
        width="7"
        height="7"
        transform="rotate(45 131.449 7)"
        fill="#FFE0A3"
      />
      <circle cx="291.5" cy="43" r="12" fill="#A6C7FF" />
      <path
        d="M290.282 44.9718C290.282 44.2068 290.375 43.5976 290.561 43.1441C290.746 42.6906 291.085 42.2452 291.577 41.8081C292.074 41.3655 292.405 41.0076 292.569 40.7344C292.732 40.4558 292.814 40.1635 292.814 39.8575C292.814 38.9341 292.388 38.4723 291.536 38.4723C291.131 38.4723 290.806 38.598 290.561 38.8494C290.32 39.0952 290.194 39.4367 290.183 39.8739H287.807C287.818 38.8302 288.154 38.0134 288.815 37.4233C289.481 36.8331 290.388 36.5381 291.536 36.5381C292.694 36.5381 293.593 36.8195 294.232 37.3823C294.872 37.9396 295.191 38.7292 295.191 39.7509C295.191 40.2154 295.087 40.6552 294.88 41.0705C294.672 41.4803 294.309 41.9365 293.79 42.4392L293.126 43.0703C292.711 43.4692 292.473 43.9364 292.413 44.4718L292.38 44.9718H290.282ZM290.044 47.488C290.044 47.1219 290.167 46.8214 290.413 46.5864C290.664 46.346 290.984 46.2258 291.372 46.2258C291.76 46.2258 292.077 46.346 292.323 46.5864C292.574 46.8214 292.7 47.1219 292.7 47.488C292.7 47.8486 292.577 48.1464 292.331 48.3813C292.09 48.6163 291.771 48.7338 291.372 48.7338C290.973 48.7338 290.651 48.6163 290.405 48.3813C290.164 48.1464 290.044 47.8486 290.044 47.488Z"
        fill="white"
      />
      <circle cx="152.81" cy="182.31" r="9.31034" fill="#4593FC" />
      <path
        d="M151.866 183.841C151.866 183.247 151.938 182.774 152.082 182.422C152.226 182.071 152.489 181.725 152.87 181.386C153.256 181.043 153.513 180.765 153.64 180.553C153.767 180.337 153.831 180.11 153.831 179.873C153.831 179.156 153.5 178.798 152.839 178.798C152.525 178.798 152.273 178.895 152.082 179.09C151.895 179.281 151.798 179.546 151.789 179.885H149.945C149.954 179.076 150.215 178.442 150.727 177.984C151.245 177.526 151.948 177.297 152.839 177.297C153.737 177.297 154.435 177.515 154.931 177.952C155.427 178.385 155.675 178.997 155.675 179.79C155.675 180.15 155.594 180.491 155.433 180.814C155.272 181.132 154.99 181.486 154.587 181.876L154.072 182.365C153.75 182.675 153.566 183.037 153.519 183.453L153.494 183.841H151.866ZM151.681 185.793C151.681 185.509 151.777 185.276 151.967 185.093C152.162 184.907 152.41 184.813 152.711 184.813C153.012 184.813 153.258 184.907 153.449 185.093C153.644 185.276 153.742 185.509 153.742 185.793C153.742 186.073 153.646 186.304 153.455 186.486C153.269 186.668 153.021 186.759 152.711 186.759C152.402 186.759 152.152 186.668 151.961 186.486C151.775 186.304 151.681 186.073 151.681 185.793Z"
        fill="white"
      />
      <circle cx="82.7069" cy="81.2069" r="6.2069" fill="#C5DAFF" />
      <path
        d="M82.0791 82.2275C82.0791 81.8318 82.1271 81.5167 82.2232 81.2821C82.3193 81.0476 82.4946 80.8172 82.7489 80.5911C83.0061 80.3622 83.1771 80.1771 83.2619 80.0358C83.3467 79.8916 83.3891 79.7404 83.3891 79.5822C83.3891 79.1045 83.1686 78.8657 82.7277 78.8657C82.5186 78.8657 82.3504 78.9307 82.2232 79.0607C82.0989 79.1879 82.0339 79.3645 82.0282 79.5906H80.7988C80.8045 79.0508 80.9783 78.6283 81.3203 78.3231C81.6651 78.0178 82.1342 77.8652 82.7277 77.8652C83.3269 77.8652 83.7918 78.0108 84.1225 78.3019C84.4531 78.5902 84.6185 78.9985 84.6185 79.527C84.6185 79.7673 84.5648 79.9948 84.4574 80.2096C84.35 80.4215 84.162 80.6575 83.8935 80.9175L83.5501 81.244C83.3354 81.4503 83.2124 81.6919 83.1813 81.9689L83.1644 82.2275H82.0791ZM81.9562 83.529C81.9562 83.3396 82.0198 83.1842 82.1469 83.0626C82.2769 82.9383 82.4423 82.8761 82.6429 82.8761C82.8436 82.8761 83.0075 82.9383 83.1347 83.0626C83.2647 83.1842 83.3297 83.3396 83.3297 83.529C83.3297 83.7155 83.2661 83.8695 83.1389 83.9911C83.0146 84.1126 82.8492 84.1733 82.6429 84.1733C82.4366 84.1733 82.2699 84.1126 82.1427 83.9911C82.0183 83.8695 81.9562 83.7155 81.9562 83.529Z"
        fill="white"
      />
      <path d="M102 163L81.5 183.5" stroke="#64D2D0" stroke-width="4" />
      <circle
        cx="125"
        cy="141"
        r="31"
        fill="white"
        fill-opacity="0.2"
        stroke="#D1D6DB"
        stroke-width="4"
      />
      <path
        d="M124.855 123L144.855 143"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M115.5 131.25L125.5 141.25"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NoDashboardImage;
