const envPublicUrl =
  process.env.PUBLIC_URL && process.env.PUBLIC_URL !== "/"
    ? process.env.PUBLIC_URL
    : "";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contextPath: envPublicUrl ? envPublicUrl : "/",
  url: (subUrl: string) => `${envPublicUrl}${subUrl}`,
  servers: {
    gateway: {
      enabled: true,
      // url: "http://192.168.20.23:10060",
      //url: "",
      //url: "http://127.0.0.1:10060",
      //  url: "http://146.56.156.112:10060",
      url: "",
    },
  },
};
