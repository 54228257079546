import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkCheckMailExist,
  // trunkGetSendHistoryById,
  trunkGetTrainingResult,
  trunkReportSendHistoryById,
  trunkResendQuizSendHistory,
  trunkSendResultMail,
} from "../store/sendHistory";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useSendHistoryStore = () => {
  const sendHistoryState = useRootState((state) => state.sendHistoryStore);

  const dispatch = useAppDispatch();

  const boundGetTrainingResult = useMemo(
    () => bindActionCreators(trunkGetTrainingResult, dispatch),
    [dispatch]
  );

  // const boundGetSendHistoryById = useMemo(
  //   () => bindActionCreators(trunkGetSendHistoryById, dispatch),
  //   [dispatch]
  // );
  const boundReportSendHistoryById = useMemo(
    () => bindActionCreators(trunkReportSendHistoryById, dispatch),
    [dispatch]
  );
  const boundCheckMailExist = useMemo(
    () => bindActionCreators(trunkCheckMailExist, dispatch),
    [dispatch]
  );
  const boundSendResultMail = useMemo(
    () => bindActionCreators(trunkSendResultMail, dispatch),
    [dispatch]
  );

  const boundResendQuizSendHistory = useMemo(
    () => bindActionCreators(trunkResendQuizSendHistory, dispatch),
    [dispatch]
  );

  return {
    sendHistoryState,
    getTrainingResult: boundGetTrainingResult,
    // getSendHistoryById: boundGetSendHistoryById,
    reportSendHistory: boundReportSendHistoryById,
    checkMailExist: boundCheckMailExist,
    sendResultMail: boundSendResultMail,
    resendQuizSendHistory: boundResendQuizSendHistory,
  };
};

export default useSendHistoryStore;
