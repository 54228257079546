import {
  Box,
  Button,
  Checkbox,
  Modal,
  ScrollBox,
  TextField,
  ToolTip,
  Typography,
} from "fasoo-ui-component-next";
import { QuizContentDTO } from "../../../types/Quiz";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { useTranslation } from "react-i18next";
import NoInfoImage from "../../../shared/image/NoInfoImage";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";

interface QuizAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedQuizList: QuizContentDTO[];
  setSelectedQuizList: Dispatch<SetStateAction<QuizContentDTO[]>>;
}
const QuizModal: React.FC<QuizAddModalProps> = ({
  isOpen,
  onClose,
  selectedQuizList,
  setSelectedQuizList,
}) => {
  const { t } = useTranslation();
  const [categoryInput, setCategoryInput] = useState<string>();
  const [quizInput, setQuizInput] = useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [quizContents, setQuizContents] = useState<QuizContentDTO[]>([]);

  const checkboxCateGroup = useCheckboxGroup();
  const checkboxQuizGroup = useCheckboxGroup();

  const { getQuizContentList, getQuizCategory, quizState } = useQuizStore();

  useEffect(() => {
    getQuizCategory();
    if (selectedQuizList.length > 0) {
      checkboxQuizGroup.handleAllClick(
        selectedQuizList.map((item) => item.quizId)
      );
    }
    getQuizContentList({});
  }, []);

  useEffect(() => {
    if (
      quizState.quizContentCategory &&
      quizState.quizContentCategory.length > 0
    ) {
      setCategories(quizState.quizContentCategory);
    }
  }, [quizState.quizContentCategory]);

  useEffect(() => {
    if (
      quizState.quizContentList &&
      quizState.quizContentList.list.length > 0
    ) {
      setQuizContents(quizState.quizContentList.list);
    }
  }, [quizState.quizContentList]);

  useEffect(() => {
    if (checkboxCateGroup.selected.length === 0) {
      setQuizContents(quizState.quizContentList.list);
    } else {
      setQuizContents(
        quizState.quizContentList.list.filter((item: QuizContentDTO) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        })
      );
    }
  }, [checkboxCateGroup.selected]);

  useEffect(() => {
    let newCategory = categoryInput?.trim();
    if (newCategory && newCategory.length > 0) {
      setCategories(
        quizState.quizContentCategory
          .filter((item: string) => item.includes(newCategory))
          ?.map((c: string) => c) ?? []
      );
    } else {
      setCategories(
        quizState.quizContentCategory?.map((item: string) => item) ?? []
      );
    }
  }, [categoryInput]);

  useEffect(() => {
    if (quizInput && quizInput.length > 0) {
      setQuizContents(
        quizState.quizContentList.list
          .filter((item: QuizContentDTO) => {
            return checkboxCateGroup.selected?.length > 0
              ? item.categorys &&
                  isSubset(checkboxCateGroup.selected, item.categorys)
              : item;
          })
          .filter(
            (item: QuizContentDTO) =>
              item.quizName && item.quizName.includes(quizInput)
          )
      );
    } else {
      setQuizContents(
        quizState.quizContentList.list.filter((item: QuizContentDTO) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        })
      );
    }
  }, [quizInput]);

  const isSubset = (subset: any[], set: any[]) => {
    return subset.every((val) => set.includes(val));
  };

  const onSubmit = () => {
    if (checkboxQuizGroup.selected.length === 0) {
      setSelectedQuizList(quizContents);
    } else {
      setSelectedQuizList(
        quizContents.filter((item) => checkboxQuizGroup.isSelected(item.quizId))
      );
    }
    onClose();
  };

  const footer = (
    <Box justifyContent="flex-end" height={80}>
      <Button color="secondary" onClick={onClose}>
        {t("common.button.cancel")}
      </Button>

      <Button
        classes="mg ml-8"
        onClick={onSubmit}
        //   disabled={
        //     !programInfo.configName ||
        //     !programInfo.sendMailCount ||
        //     programInfo?.sendMailCount < 1 ||
        //     programInfo?.sendMailCount > 100 ||
        //     userCheckboxGroup.selected.length === 0 ||
        //     (checkboxScenarioGroup.selected.length === 0 &&
        //       templates.length === 0)
        //   }
      >
        {t("common.button.create")}
      </Button>
    </Box>
  );
  return (
    <Modal
      open={isOpen}
      title={t("quiz.label.choiceQuizContent")}
      titleFontSize={20}
      width={720}
      height={720}
      isDivider={false}
      onClose={onClose}
      footer={footer}
      classes="quizSelectModal"
    >
      <Box>
        <Box direction="column" classes="mg mr-8">
          <Box direction="column">
            <Box classes="mg mb-16">
              <Typography color={"secondary"} fontWeight={700}>
                {t("quiz.label.category")}
              </Typography>
            </Box>
            <TextField
              width={328}
              placeholder={t("quiz.label.categoryHolder")}
              height={40}
              borderRadius={8}
              text={categoryInput && categoryInput}
              onChange={(e) => setCategoryInput(e.target.value)}
            ></TextField>
          </Box>
          <ScrollBox
            style={{
              borderRight: "1px solid gray",
              height: "448px",
            }}
            classes="mg mt-24 "
            maxHeight={448}
            height={436}
          >
            {categories && categories?.length > 0 ? (
              <>
                {!categoryInput && (
                  <Box
                    style={{
                      backgroundColor:
                        checkboxCateGroup.selected.length === 0
                          ? "#EAF4FF"
                          : "#FFFFFF",
                      display: "flex",
                      height: "48px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      padding: "8px",
                    }}
                  >
                    <Box alignItems="center">
                      <Typography classes="mg ml-8">
                        {t("common.auth.all")}
                      </Typography>
                    </Box>
                    <Checkbox
                      radio={true}
                      onChange={() => {
                        checkboxCateGroup.handleAllClick([]);
                      }}
                      checked={
                        checkboxCateGroup.selected.length > 0 ? false : true
                      }
                    />
                  </Box>
                )}

                {categories?.map((item) => (
                  <Box
                    key={item}
                    style={{
                      backgroundColor: checkboxCateGroup.isSelected(item)
                        ? "#EAF4FF"
                        : "#FFFFFF",
                      display: "flex",
                      height: "48px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      padding: "8px",
                    }}
                  >
                    <ToolTip message={item}>
                      <Typography
                        classes="mg ml-8"
                        ellipsis
                        maxWidth="280px"
                        whiteSpace="pre"
                      >
                        {item}
                      </Typography>
                    </ToolTip>

                    <Checkbox
                      radio={true}
                      onChange={() => {
                        checkboxCateGroup.handleClick(item);
                        setQuizInput("");
                      }}
                      checked={checkboxCateGroup.isSelected(item)}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Box
                alignItems="center"
                justifyContent="center"
                direction="column"
                height={"100%"}
                width="100%"
              >
                <NoInfoImage width={200} />
                <Typography size={14} classes="mg mt-16">
                  {t("common.msg.noHistoryMsg")}
                </Typography>
              </Box>
            )}
          </ScrollBox>
        </Box>
        <Box direction="column">
          <Box direction="column">
            <Box classes="mg mb-16">
              <Typography color={"secondary"} fontWeight={700}>
                {t("quiz.label.quizQuestions")}
              </Typography>
            </Box>
            <TextField
              width={328}
              placeholder={t("quiz.label.quizQuestionsHolder")}
              height={40}
              borderRadius={8}
              text={quizInput && quizInput}
              onChange={(e) => setQuizInput(e.target.value)}
            ></TextField>
          </Box>
          <ScrollBox
            style={{ borderRight: "1px solid gray", height: "448px" }}
            classes="mg mt-24"
            // maxHeight={464}
            height={436}
          >
            {quizContents && quizContents?.length > 0 ? (
              <>
                {!quizInput && (
                  <Box
                    style={{
                      backgroundColor:
                        checkboxQuizGroup.selected.length === 0
                          ? "#EAF4FF"
                          : "#FFFFFF",
                      display: "flex",
                      height: "48px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      padding: "8px",
                    }}
                  >
                    <Box alignItems="center">
                      <Typography classes="mg ml-8">
                        {t("common.auth.all")}
                      </Typography>
                    </Box>
                    <Checkbox
                      radio={true}
                      onChange={() => {
                        checkboxQuizGroup.handleAllClick([]);
                      }}
                      checked={
                        checkboxQuizGroup.selected.length > 0 ? false : true
                      }
                    />
                  </Box>
                )}

                {quizContents?.map((item) => (
                  <Box
                    key={item.quizId}
                    style={{
                      backgroundColor: checkboxQuizGroup.isSelected(item.quizId)
                        ? "#EAF4FF"
                        : "#FFFFFF",
                      display: "flex",
                      height: "48px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      padding: "8px",
                    }}
                    width={"328px"}
                  >
                    <ToolTip message={item.quizName}>
                      <Typography
                        classes="mg ml-8"
                        ellipsis
                        maxWidth="280px"
                        whiteSpace="pre"
                      >
                        {item.quizName}
                      </Typography>
                    </ToolTip>
                    {}
                    <Checkbox
                      radio={true}
                      onChange={() => {
                        checkboxQuizGroup.handleClick(item.quizId);
                        setQuizInput("");
                      }}
                      checked={checkboxQuizGroup.isSelected(item.quizId)}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Box
                alignItems="center"
                justifyContent="center"
                direction="column"
                height={"100%"}
                width="100%"
              >
                <NoInfoImage width={200} />
                <Typography size={14} classes="mg mt-16">
                  {t("common.msg.noHistoryMsg")}
                </Typography>
              </Box>
            )}
          </ScrollBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default QuizModal;
