import "./index.scss";
import {
  Box,
  Button,
  Checkbox,
  FormInput,
  Icons,
  Radio,
  RadioGroup,
  ScrollBox,
  TextArea,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useTranslation } from "react-i18next";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CategoryTag from "../../../component/CategoryTag";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { QuizContentReqDTO, QuizContentDTO } from "../../../types/Quiz";
import { nanoid } from "@reduxjs/toolkit";
import { Category } from "../../../types/Info";
import AIPromptModal from "./AIPromptModal";

const WriteQuiz: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    insertQuizContent,
    updateQuizContent,
    getQuizCategory,
    getQuizContentDetail,
    quizState,
  } = useQuizStore();
  const toast = useToast();
  const quizId = searchParams.get("quiz_id");

  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  // 1: O/X
  // 2: 선다형
  const [quizType, setQuizType] = useState<string>("1");

  // 사용자 입력값
  const [categoryInput, setCategoryInput] = useState<string>("");
  //사용자 입력값에 대한 기존 category 검색 리스트
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const categoryCheckboxGroup = useCheckboxGroup();
  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );

  const [quizContent, setQuizContent] = useState<QuizContentDTO>({
    quizName: "",
    quizType: 1,
    quizAnswer: 1,
    quizChoices: [],
    quizBody: "",
    quizComments: "",
  });

  useEffect(() => {
    getQuizCategory();
    if (quizId) {
      changeType("UPDATE");
      getQuizContentDetail({ quizId: parseInt(quizId) });
    } else {
      setQuizContent({
        quizName: "",
        quizType: 1,
        quizAnswer: 1,
        quizChoices: [],
        quizBody: "",
        quizComments: "",
      });
      setQuizType("1");
      setSelectedCategoryList([]);
    }
  }, []);

  useEffect(() => {
    if (quizId && quizState.quizContent) {
      setQuizContent({
        ...quizState.quizContent,
        // quizAnswer: quizState.quizContent.quizAnswer.toString(),
      });
      setQuizType(quizState.quizContent.quizType.toString());
      setSelectedCategoryList(quizState.quizContent.categorys);
    }
  }, [quizState.quizContent]);

  useEffect(() => {
    const newCatName = categoryInput.trim();
    if (newCatName.length > 0) {
      setCategoryList(
        quizState.quizContentCategory
          .filter((item: string) => item.includes(newCatName))
          .map((c: string) => c)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);
  const handleSetQuiz = (quiz: QuizContentDTO) => {
    setQuizContent(quiz);
    setQuizType(quiz.quizType + "");
  };
  const addCategory = (e: KeyboardEvent<HTMLInputElement>) => {
    const newCategoryName = categoryInput.trim();
    if (newCategoryName.length > 30) {
      toast.toastMsg(
        nanoid(),
        t("category.msg.categoryNameLengthLimitMsg"),
        "error"
      );
      return;
    }
    if (newCategoryName === "") {
      return;
    }

    if (/^\s*$/.test(newCategoryName)) {
      return;
    }

    if (e.key === "Enter") {
      if (!selectedCategoryList.some((item) => item === newCategoryName)) {
        setSelectedCategoryList((prev) => [...prev, newCategoryName]);
        if (categoryList.includes(newCategoryName)) {
          categoryCheckboxGroup.handleClick(newCategoryName);
        }
        setCategoryInput("");
        setCategoryList([]);
        // e.target.value("");
      } else {
        setCategoryInput("");
      }
    }
  };

  const clickCategory = (e: any, cate: string) => {
    if (!selectedCategoryList.some((item) => item === cate)) {
      setSelectedCategoryList((prev) => [...prev, cate]);
      setCategoryInput("");
      setCategoryList([]);
      categoryCheckboxGroup.handleClick(cate);
    } else {
      setCategoryInput("");
    }
  };

  const deleteCategory = (name: string) => {
    if (name && selectedCategoryList.some((item) => item === name)) {
      setSelectedCategoryList((prev) => {
        return prev.filter((p) => p !== name);
      });
    }
  };

  const insertQuiz = async () => {
    // validation

    const resp: any = await insertQuizContent({
      quizName: quizContent.quizName,
      quizType: parseInt(quizType),
      quizBody: quizContent.quizBody,
      quizComments: quizContent.quizComments,
      quizAnswer: quizContent.quizAnswer,
      quizChoices: quizContent.quizChoices,
      categorys: selectedCategoryList,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("quiz.msg.addQuizContentSuccess"), "success");
      navigate("/quiz/content");
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.duplicateQuizName"), "error");
    }
  };

  const updateQuiz = async () => {
    //validation

    if (quizId) {
      const resp: any = await updateQuizContent({
        quizId: parseInt(quizId),
        quizName: quizContent.quizName,
        quizType: parseInt(quizType),
        quizBody: quizContent.quizBody,
        quizComments: quizContent.quizComments,
        quizAnswer: quizContent.quizAnswer,
        quizChoices: quizContent.quizChoices,
        categorys: selectedCategoryList,
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.updateQuizContentSuccess"),
          "success"
        );
        navigate("/quiz/content");
      } else {
        toast.toastMsg(nanoid(), t("quiz.msg.duplicateQuizName"), "error");
      }
    }
  };

  const onChangeChoices = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (quizContent.quizChoices) {
      const temp = [...quizContent.quizChoices];
      temp[index] = e.target.value;
      setQuizContent((prev) => ({ ...prev, quizChoices: temp }));
    }
  };

  const [isAIModalOpen, setIsAIModalOpen] = useState<boolean>(false);

  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {/* 훈련 시나리오 생성 */}
            {type === "UPDATE"
              ? t("quiz.label.editQuizContent")
              : t("quiz.label.addQuizContent")}
          </Typography>
        </Box>
        {type === "CREATE" && (
          <Box>
            <Button
              startIcon={
                <Icons
                  variant="file"
                  label="summaryHeader"
                  stroke="#252d38"
                  width={20}
                />
              }
              color="secondary"
              onClick={() => setIsAIModalOpen(true)}
              classes="mg mr-10"
            >
              {t("quiz.label.addAiQuiz")}
            </Button>
          </Box>
        )}
      </Box>

      {quizContent && (
        <Box direction="column">
          <Box classes="mg mt-24">
            <Box width={248} alignItems="center" height={40}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.quizName")}
              </Typography>
            </Box>
            <TextField
              height={40}
              borderRadius={8}
              width={598}
              placeholder={t("quiz.label.quizNameHolder")}
              text={quizContent.quizName}
              hasText
              maxLength={128}
              onChange={(e) =>
                setQuizContent((prev) => ({
                  ...prev,
                  quizName: e.target.value,
                }))
              }
            />
          </Box>

          <Box classes="mg mt-24">
            <Box width={248} alignItems="center" height={40}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.question")}
              </Typography>
            </Box>
            <TextField
              height={40}
              borderRadius={8}
              width={598}
              placeholder={t("quiz.label.questionHolder")}
              text={quizContent.quizBody}
              hasText
              maxLength={1000}
              onChange={(e) =>
                setQuizContent((prev) => ({
                  ...prev,
                  quizBody: e.target.value,
                }))
              }
            />
          </Box>

          <Box classes="mg mt-24">
            <Box width={248} alignItems="center" height={40}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.format")}
              </Typography>
            </Box>
            <Box height={40} alignItems="center">
              <RadioGroup
                onChange={(e) => {
                  setQuizType(e.target.value);
                  if (e.target.value === "1") {
                    setQuizContent((prev) => ({
                      ...prev,
                      quizAnswer: 1,
                      quizChoices: ["", ""],
                    }));
                  } else {
                    setQuizContent((prev) => ({
                      ...prev,
                      quizAnswer: 0,
                      quizChoices: ["", "", "", "", ""],
                    }));
                  }
                }}
              >
                <Radio
                  label={t("quiz.label.formatTF")}
                  value="1"
                  checked={quizType === "1"}
                />
                <Radio
                  label={t("quiz.label.formatChoice")}
                  value="2"
                  checked={quizType === "2"}
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#F6F8FA",
              marginLeft: "248px",
              marginTop: "24px",
              width: "598px",
              padding: "16px 20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography color="secondary" fontWeight={700} classes="mg mb-12">
              {quizType === "1"
                ? t("quiz.label.answer")
                : t("quiz.label.choice")}
            </Typography>
            <Box
              style={{
                borderBottom: "1px solid  #E9ECEF",
                marginBottom: "12px",
              }}
            ></Box>
            {quizType === "1" ? (
              <Box alignItems="center">
                <RadioGroup
                  onChange={(e) =>
                    setQuizContent((prev) => ({
                      ...prev,
                      quizAnswer: parseInt(e.target.value),
                    }))
                  }
                >
                  <Radio
                    label="O"
                    value="0"
                    checked={quizContent.quizAnswer?.toString() === "0"}
                  />
                  <Radio
                    label="X"
                    value="1"
                    checked={quizContent.quizAnswer?.toString() === "1"}
                  />
                </RadioGroup>
              </Box>
            ) : (
              <Box direction="column">
                <Box>
                  <Box
                    width={106}
                    alignItems="center"
                    height={40}
                    classes="mg mb-12"
                  >
                    <Typography color="secondary">
                      {t("quiz.label.answer")}
                    </Typography>
                  </Box>
                  <Box alignItems="center" height={40} classes="mg mb-12">
                    <Typography color="secondary">
                      {t("quiz.label.text")}
                    </Typography>
                  </Box>
                </Box>
                {quizContent.quizChoices &&
                  quizContent.quizChoices.map((c, index) => (
                    <Box classes="mg mb-12" height={40} key={index}>
                      <Box width={106} alignItems="center">
                        <Box
                          width={20}
                          height={20}
                          classes="mg mr-8"
                          alignItems="center"
                        >
                          <Radio
                            onChange={() =>
                              setQuizContent((prev) => ({
                                ...prev,
                                quizAnswer: index,
                              }))
                            }
                            checked={quizContent.quizAnswer === index}
                            classes="quiz__radio"
                          />
                        </Box>
                        <Typography color="secondary">{index + 1}</Typography>
                      </Box>
                      <TextField
                        width={452}
                        height={40}
                        borderRadius={8}
                        hasText
                        maxLength={1000}
                        text={
                          quizContent.quizChoices &&
                          quizContent.quizChoices[index]
                        }
                        onChange={(e) => onChangeChoices(e, index)}
                      ></TextField>
                    </Box>
                  ))}
              </Box>
            )}
          </Box>

          <Box classes="mg mt-24" height={80}>
            <Box width={248} alignItems="center" height={40}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.answerEx")}
              </Typography>
            </Box>
            <TextArea
              // height={80}
              borderRadius={8}
              rows={3}
              padding={8}
              minWidth={598}
              maxLength={2000}
              fullWidth={false}
              placeholder={t("quiz.label.answerExHolder")}
              onChange={(e) =>
                setQuizContent((prev) => ({
                  ...prev,
                  quizComments: e.target.value,
                }))
              }
              // hasText
              value={quizContent.quizComments && quizContent.quizComments}
            />
          </Box>

          {/* category start */}
          <Box
            style={{
              marginBottom: "62px",
              marginTop: "24px",
            }}
          >
            <Box width={248} alignItems="center" height={40}>
              <Typography fontWeight={700} classes="mg mb-12" color="secondary">
                {t("quiz.label.category")}
              </Typography>
            </Box>

            <Box direction="column" width={598}>
              <Box classes="category__select" width={598}>
                <TextField
                  placeholder={t("quiz.label.categoryHolder")}
                  width={598}
                  height={40}
                  borderRadius={8}
                  text={categoryInput}
                  hasText={true}
                  onChange={(e) => setCategoryInput(e.target.value)}
                  onKeyPress={(e) => addCategory(e)}
                  maxLength={30}
                />

                {categoryList.length > 0 && (
                  <Box classes="mg category__select__list">
                    {categoryList.map((item) => (
                      <Box
                        classes="category__select__item"
                        key={item}
                        style={{
                          backgroundColor: categoryCheckboxGroup.isSelected(
                            item
                          )
                            ? "#EAF4FF"
                            : "#FFFFFF",
                        }}
                      >
                        <Typography>{item}</Typography>
                        <Checkbox
                          radio={true}
                          onChange={(e: any) => clickCategory(e, item)}
                          checked={categoryCheckboxGroup.isSelected(item)}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>

              <Box classes="mg mt-24">
                {selectedCategoryList.length > 0 && (
                  <Box classes="write__catgeory__list">
                    {selectedCategoryList.map((item) => (
                      // <Box key={item}>{item}</Box>
                      <CategoryTag
                        endIcon={<Icons variant="common" label="delete" />}
                        key={item}
                        text={item}
                        onClick={() => deleteCategory(item)}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {/* category end  */}

          {/* footer start */}

          <Box width={"100%"} justifyContent="flex-end">
            <Button
              color="secondary"
              classes="mg mr-8"
              onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/content`)}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              onClick={() => {
                if (type === "UPDATE") updateQuiz();
                else insertQuiz();
              }}
              disabled={
                !quizContent.quizName ||
                !quizContent.quizBody ||
                (quizType === "2" &&
                  !!quizContent.quizChoices &&
                  quizContent.quizChoices?.filter((item) => item !== "")
                    ?.length < 5)
              }
            >
              {type === "UPDATE"
                ? t("common.button.update")
                : t("quiz.label.save")}
            </Button>
          </Box>
          {/* footer end */}
        </Box>
      )}

      {isAIModalOpen && (
        <AIPromptModal
          isOpen={isAIModalOpen}
          setQuizContent={handleSetQuiz}
          onClose={() => setIsAIModalOpen(false)}
        />
      )}
    </ScrollBox>
  );
};

export default WriteQuiz;
