import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import { BaseResponseArrDTO, BaseResponseBean } from "../../types/Common";
import { TrainingResultReqType, TrainingResultType } from "../../types/Send";

const GATEWAY_URL = config.servers.gateway.url;

interface SendHistoryApi {
  getTrainingResult(
    req: TrainingResultReqType
  ): Promise<BaseResponseArrDTO<TrainingResultType[]>>;
  // getSendHistoryById(configId: number): Promise<TrainingResultReqType[]>;
  reportSendHistory(req: {
    sid: number;
    isReported: boolean;
  }): Promise<boolean>;
  sentResultMail(configId: number): Promise<BaseResponseBean<null>>;
  checkMailExist(configId: number): Promise<number>;
  resendQuizSendHistory(req?: TrainingResultReqType): Promise<any>;
}
class SendHistoryServerApi implements SendHistoryApi {
  async getTrainingResult(
    req: TrainingResultReqType
  ): Promise<BaseResponseArrDTO<TrainingResultType[]>> {
    const res = await axios.post<BaseResponseArrDTO<TrainingResultType[]>>(
      `${GATEWAY_URL}/api/v2/training/result`,
      req
    );
    return Promise.resolve(res.data);
  }
  // async getSendHistoryById(configId: number): Promise<SendHistoryType[]> {
  //   const res = await axios.post<SendHistoryType[]>(
  //     `/sendHistory/${configId}`,
  //     {}
  //   );
  //   return Promise.resolve(res.data);
  // }
  async reportSendHistory(req: {
    sid: number;
    isReported: boolean;
  }): Promise<boolean> {
    const res = await axios.post<boolean>(
      `/sendHistory/report?sid=${req.sid}&isReported=${req.isReported}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async sentResultMail(configId: number): Promise<BaseResponseBean<null>> {
    const res = await axios.post<BaseResponseBean<null>>(
      `${GATEWAY_URL}/send/result?configId=${configId}`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async checkMailExist(configId: number): Promise<number> {
    const res = await axios.get<number>(`/mail/exists?configId=${configId}`);
    return Promise.resolve(res.data);
  }

  async resendQuizSendHistory(req?: TrainingResultReqType): Promise<any> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/training/result/resend`,
      req ?? {}
    );

    return Promise.resolve(res.data);
  }
}

const sendHistoryApi: SendHistoryApi = new SendHistoryServerApi();

export default sendHistoryApi;
