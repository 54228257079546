import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Checkbox,
  Icons,
  ScrollBox,
  Table,
  Tag,
  Typography,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { useEffect, useState } from "react";
import { QuizContentDTO, QuizCourseDTO } from "../../../types/Quiz";
import utilsCommon from "../../../shared/utils/common";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import { SimpleTreeNode, UserType } from "../../../types/User";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import TreeSimple from "../../../component/TreeSimple";

const QuizCourseDetail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("course_id");
  const [course, setCourse] = useState<QuizCourseDTO>();

  const { quizState, getQuizCourseDetail, getQuizContentList } = useQuizStore();
  const { deptState, getDeptList } = useDeptStore();

  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );

  const [userList, setUserList] = useState<UserType[]>();

  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const userCheckGroup = useCheckboxGroup<string>();
  const deptCheckGroup = useCheckboxGroup<string>();

  const getCourseDetail = async () => {
    if (courseId) {
      const response: any = await getQuizCourseDetail({
        courseId: parseInt(courseId),
      });

      if (response.payload.data.userList.length > 0) {
        setUserList(response.payload.data.userList);
      } else {
        if (response.payload.data.courseInfo.deptIds.length > 0)
          deptCheckGroup.handleAllClick(
            response.payload.data.courseInfo.deptIds
          );

        let resp: any = await getDeptList(
          response.payload.data.courseInfo.deptIds
        );

        if (resp.payload) {
          let newUsers =
            resp.payload?.data?.list?.map(
              (item: SimpleTreeNode) => item.deptCode
            ) ?? [];

          userCheckGroup.handleAllClick([
            ...response.payload.data.courseInfo.userIds,
            ...newUsers,
          ]);
        }

        // changeOrganTree(
        //   deptState.organList?.filter((item: SimpleTreeNode) =>
        //     [
        //       response.payload.data.courseInfo.deptIds,
        //       response.payload.data.courseInfo.userIds,
        //     ].includes(item.deptCode)
        //   )
        // );
      }
    }
  };

  useEffect(() => {
    if (courseId) {
      getQuizContentList({});
      getCourseDetail();
      getDeptList();
    }
  }, []);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  useEffect(() => {
    if (quizState.quizCourse && quizState.quizContentList.list.length > 0) {
      setCourse(quizState.quizCourse);
      setSelectedQuizList(
        quizState.quizContentList.list.filter(
          (q: QuizContentDTO) =>
            q.quizId &&
            quizState.quizCourse?.quizIds?.includes(q.quizId.toString())
        )
      );
    }
  }, [quizState.quizCourse, quizState.quizContentList]);

  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizCourse")]} />
          <Box alignItems="center" classes="mg mt-8">
            <Box
              onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/course`)}
              style={{ cursor: "pointer" }}
            >
              <Icons variant="arrow" label="left" classes="mg mr-8" />
            </Box>
            <Typography fontWeight={700} size={24} lineHeight="32px">
              {t("quiz.label.details")}
              {/* {type === "UPDATE" ? t("template.updateSimple") : t("template.add")} */}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* main start */}
      {course && selectedQuizList.length > 0 && (
        <Box classes="mg mt-24" direction="column">
          {/* basic info start  */}
          <Box>
            <Accordion>
              <AccordionItem
                title={t("quiz.label.basicInfo")}
                expandedProp={true}
                type="CLOSEINFO"
                classes="training__accordian"
                iconLeft={true}
              >
                <Box
                  alignItems="center"
                  classes="mg mb-36 ml-16 mt-28"
                  height={20}
                >
                  <Box width={220} classes="mg mr-28">
                    <Typography color="secondary" fontWeight={700}>
                      {t("quiz.label.courseTitle")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{course.courseName}</Typography>
                  </Box>
                </Box>

                <Box classes="mg mb-36 ml-16" height={20}>
                  <Box width={220} classes="mg mr-28">
                    <Typography color="secondary" fontWeight={700}>
                      {t("quiz.label.period")}
                    </Typography>
                  </Box>
                  <Box direction="column" justifyContent="space-between">
                    <Typography>
                      {`${utilsCommon.getFullDateStr(course.sendStartDate)} ~
                      ${utilsCommon.getFullDateStr(course.sendEndDate)}`}
                    </Typography>
                  </Box>
                </Box>

                <Box classes="mg mb-36 ml-16" height={20}>
                  <Box
                    direction="column"
                    justifyContent="space-between"
                    width={220}
                    classes="mg mr-28"
                  >
                    <Typography color="secondary" fontWeight={700}>
                      {t("quiz.label.emailsPerTarget")}
                    </Typography>
                  </Box>

                  <Box classes="mg ml-8" alignItems="center">
                    <Typography>{`${course.sendMailCount}건`}</Typography>
                  </Box>
                </Box>

                <Box classes="mg mb-36 ml-16" height={20}>
                  <Box
                    direction="column"
                    justifyContent="space-between"
                    width={220}
                    classes="mg mr-28"
                  >
                    <Typography color="secondary" fontWeight={700}>
                      {t("quiz.label.quizPerMail")}
                    </Typography>
                  </Box>

                  <Box classes="mg ml-8" alignItems="center">
                    <Typography>{`${course.quizCount}개`}</Typography>
                  </Box>
                </Box>

                <Box classes="mg mb-36 ml-16" height={20}>
                  <Box width={220} classes="mg mr-28">
                    <Typography color="secondary" fontWeight={700}>
                      {t("quiz.label.courseStatus")}
                    </Typography>
                  </Box>
                  <Box classes="mg ml-8">
                    <Typography>
                      {course.activateFlag ? "ON" : "OFF"}
                    </Typography>
                  </Box>
                </Box>

                <Box></Box>
              </AccordionItem>
            </Accordion>
          </Box>
          {/* basic info end   */}

          {/* user info start  */}
          <Box>
            <Accordion>
              <AccordionItem
                title={t("quiz.label.targets")}
                expandedProp={true}
                type="CLOSEINFO"
                classes="training__accordian"
                iconLeft={true}
              >
                <Box>
                  <Box classes="mg mb-28 ml-16" width={500}>
                    {userList && userList.length > 0 ? (
                      <Box>
                        <Box width={220} classes="mg mr-28">
                          <Typography color="secondary" fontWeight={700}>
                            {t("quiz.label.user")}
                          </Typography>
                        </Box>
                        <Box
                          classes="mg ml-8"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 300px)",
                          }}
                        >
                          {userList.map((u) => (
                            <Box alignItems="center" width={292} height={32}>
                              <Icons
                                variant="chat"
                                label="user"
                                stroke="#5E92F5"
                              />
                              <Box classes="mg ml-4">
                                <Typography>{`${u.userName} (${u.userEmail})`}</Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      // 시작 x 조직도
                      <>
                        {organTree && (
                          <TreeSimple
                            rootCode={"COMPANY"}
                            treeNodes={organTree}
                            depth={0}
                            disableCheck
                            checkUserIds={userCheckGroup.selected}
                            checkDeptIds={deptCheckGroup.selected}
                            checkboxUserGroup={userCheckGroup}
                            checkboxDeptGroup={deptCheckGroup}
                            isUserCheck
                            isDeptCheck
                            classes="mg mt-8"
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </AccordionItem>
            </Accordion>
          </Box>
          {/* user info end   */}

          {/* quiz  info start  */}
          <Box>
            <Accordion>
              <AccordionItem
                title={t("quiz.label.quizQuestions")}
                expandedProp={true}
                type="CLOSEINFO"
                classes="training__accordian"
                iconLeft={true}
              >
                <Box classes="mg ml-16" direction="column">
                  <Box alignItems="center">
                    <Box classes="mg ml-12">
                      <Typography>{t("quiz.label.added")}</Typography>
                    </Box>
                    <Box classes="mg ml-4">
                      <Typography color="green">
                        {course.quizIds?.length}
                      </Typography>
                    </Box>
                  </Box>
                  <Table classes="mg mt-12">
                    <colgroup>
                      <col style={{ width: "44px" }} />
                      <col style={{ width: "72px" }} />
                      <col style={{ width: "360px" }} />
                      <col style={{ width: "*" }} />
                      <col style={{ width: "160px" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{/* <Checkbox /> */}</th>
                        <th>{t("quiz.table.type")}</th>
                        <th>{t("quiz.table.quizName")}</th>
                        <th>{t("quiz.table.category")}</th>
                        <th>{t("quiz.table.formal")}</th>
                      </tr>
                    </thead>
                  </Table>
                  <Box classes="table-scroll">
                    <Table>
                      <colgroup>
                        <col style={{ width: "44px" }} />
                        <col style={{ width: "72px" }} />
                        <col style={{ width: "360px" }} />
                        <col style={{ width: "*" }} />
                        <col style={{ width: "160px" }} />
                      </colgroup>
                      <tbody>
                        {selectedQuizList.length > 0 &&
                          selectedQuizList.map((q) => (
                            <tr>
                              <td></td>
                              <td>
                                {q.commonId ? (
                                  <Tag
                                    name={t("quiz.label.public")}
                                    size={i18n.language === "ko" ? "ms" : "sm"}
                                    color="blue"
                                    radius={6}
                                  />
                                ) : (
                                  <Tag
                                    name={t("quiz.label.private")}
                                    size={i18n.language === "ko" ? "ms" : "sm"}
                                    color="pink"
                                    radius={6}
                                  />
                                )}
                              </td>
                              <td>{q.quizName}</td>
                              <td>
                                {q.categorys && (
                                  <CategoryTagList categorys={q.categorys} />
                                )}
                              </td>

                              <td>
                                {q.quizType === 1
                                  ? t("quiz.label.formatTF")
                                  : t("quiz.label.formatChoice")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Box>
                </Box>
              </AccordionItem>
            </Accordion>
          </Box>
          {/* quiz  info end   */}

          <Box justifyContent="flex-end" classes="mg mt-12">
            <Button
              classes="mg ml-8"
              onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/result`)}
              disabled={
                !(course.sendMailCount ?? 0) ||
                !(course.quizCount ?? 0) ||
                selectedQuizList.length <
                  (course.sendMailCount ?? 0) * (course.quizCount ?? 0)
              }
            >
              {t("quiz.label.sentQuizResults")}
            </Button>
          </Box>
        </Box>
      )}
      {/* main end  */}
    </ScrollBox>
  );
};

export default QuizCourseDetail;
