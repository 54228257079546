import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Calendar,
  Checkbox,
  Divider,
  FormInput,
  Icons,
  Modal,
  Radio,
  RadioGroup,
  ScrollBox,
  Select,
  Switch,
  Table,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import { useEffect, useState } from "react";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { SimpleTreeNode } from "../../../types/User";
import TreeSimple from "../../../component/TreeSimple";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import {
  Category,
  ProgramAddBean,
  templateListBean,
  userListBean,
} from "../../../types/Info";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario } from "../../../types/Scenario";
import { nanoid } from "@reduxjs/toolkit";
import NoInfoImage from "../../../shared/image/NoInfoImage";
import dayjs from "dayjs";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import SmallUserChip from "../../../component/SmallUserChip";
import { useTranslation } from "react-i18next";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MailBody from "../../../component/MailBody";
import { useNavigate, useSearchParams } from "react-router-dom";
import TitlePath from "../../../component/TitlePath";
import NoQuizImage from "../../../shared/image/NoQuizImage";
import { QuizContentDTO, QuizTrainingInfoReq } from "../../../types/Quiz";
import QuizModal from "../../QuizCourse/Write/QuizModal";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
const WriteTraining: React.FC = ({}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  // step
  const { t, i18n } = useTranslation();
  const timeTyps = [
    { id: "0000", text: `${t("common.info.commonAm")} 00:00` },
    { id: "0015", text: `${t("common.info.commonAm")} 00:15` },
    { id: "0030", text: `${t("common.info.commonAm")} 00:30` },
    { id: "0045", text: `${t("common.info.commonAm")} 00:45` },
    { id: "0100", text: `${t("common.info.commonAm")} 01:00` },
    { id: "0115", text: `${t("common.info.commonAm")} 01:15` },
    { id: "0130", text: `${t("common.info.commonAm")} 01:30` },
    { id: "0145", text: `${t("common.info.commonAm")} 01:45` },
    { id: "0200", text: `${t("common.info.commonAm")} 02:00` },
    { id: "0215", text: `${t("common.info.commonAm")} 02:15` },
    { id: "0230", text: `${t("common.info.commonAm")} 02:30` },
    { id: "0245", text: `${t("common.info.commonAm")} 02:45` },
    { id: "0300", text: `${t("common.info.commonAm")} 03:00` },
    { id: "0315", text: `${t("common.info.commonAm")} 03:15` },
    { id: "0330", text: `${t("common.info.commonAm")} 03:30` },
    { id: "0345", text: `${t("common.info.commonAm")} 03:45` },
    { id: "0400", text: `${t("common.info.commonAm")} 04:00` },
    { id: "0415", text: `${t("common.info.commonAm")} 04:15` },
    { id: "0430", text: `${t("common.info.commonAm")} 04:30` },
    { id: "0445", text: `${t("common.info.commonAm")} 04:45` },
    { id: "0500", text: `${t("common.info.commonAm")} 05:00` },
    { id: "0515", text: `${t("common.info.commonAm")} 05:15` },
    { id: "0530", text: `${t("common.info.commonAm")} 05:30` },
    { id: "0545", text: `${t("common.info.commonAm")} 05:45` },
    { id: "0600", text: `${t("common.info.commonAm")} 06:00` },
    { id: "0615", text: `${t("common.info.commonAm")} 06:15` },
    { id: "0630", text: `${t("common.info.commonAm")} 06:30` },
    { id: "0645", text: `${t("common.info.commonAm")} 06:45` },
    { id: "0700", text: `${t("common.info.commonAm")} 07:00` },
    { id: "0715", text: `${t("common.info.commonAm")} 07:15` },
    { id: "0730", text: `${t("common.info.commonAm")} 07:30` },
    { id: "0745", text: `${t("common.info.commonAm")} 07:45` },
    { id: "0800", text: `${t("common.info.commonAm")} 08:00` },
    { id: "0815", text: `${t("common.info.commonAm")} 08:15` },
    { id: "0830", text: `${t("common.info.commonAm")} 08:30` },
    { id: "0845", text: `${t("common.info.commonAm")} 08:45` },
    { id: "0900", text: `${t("common.info.commonAm")} 09:00` },
    { id: "0915", text: `${t("common.info.commonAm")} 09:15` },
    { id: "0930", text: `${t("common.info.commonAm")} 09:30` },
    { id: "0945", text: `${t("common.info.commonAm")} 09:45` },
    { id: "1000", text: `${t("common.info.commonAm")} 10:00` },
    { id: "1015", text: `${t("common.info.commonAm")} 10:15` },
    { id: "1030", text: `${t("common.info.commonAm")} 10:30` },
    { id: "1045", text: `${t("common.info.commonAm")} 10:45` },
    { id: "1100", text: `${t("common.info.commonAm")} 11:00` },
    { id: "1115", text: `${t("common.info.commonAm")} 11:15` },
    { id: "1130", text: `${t("common.info.commonAm")} 11:30` },
    { id: "1145", text: `${t("common.info.commonAm")} 11:45` },
    { id: "1200", text: `${t("common.info.commonPm")} 12:00` },
    { id: "1215", text: `${t("common.info.commonPm")} 12:15` },
    { id: "1230", text: `${t("common.info.commonPm")} 12:30` },
    { id: "1245", text: `${t("common.info.commonPm")} 12:45` },
    { id: "1300", text: `${t("common.info.commonPm")} 01:00` },
    { id: "1315", text: `${t("common.info.commonPm")} 01:15` },
    { id: "1330", text: `${t("common.info.commonPm")} 01:30` },
    { id: "1345", text: `${t("common.info.commonPm")} 01:45` },
    { id: "1400", text: `${t("common.info.commonPm")} 02:00` },
    { id: "1415", text: `${t("common.info.commonPm")} 02:15` },
    { id: "1430", text: `${t("common.info.commonPm")} 02:30` },
    { id: "1445", text: `${t("common.info.commonPm")} 02:45` },
    { id: "1500", text: `${t("common.info.commonPm")} 03:00` },
    { id: "1515", text: `${t("common.info.commonPm")} 03:15` },
    { id: "1530", text: `${t("common.info.commonPm")} 03:30` },
    { id: "1545", text: `${t("common.info.commonPm")} 03:45` },
    { id: "1600", text: `${t("common.info.commonPm")} 04:00` },
    { id: "1615", text: `${t("common.info.commonPm")} 04:15` },
    { id: "1630", text: `${t("common.info.commonPm")} 04:30` },
    { id: "1645", text: `${t("common.info.commonPm")} 04:45` },
    { id: "1700", text: `${t("common.info.commonPm")} 05:00` },
    { id: "1715", text: `${t("common.info.commonPm")} 05:15` },
    { id: "1730", text: `${t("common.info.commonPm")} 05:30` },
    { id: "1745", text: `${t("common.info.commonPm")} 05:45` },
    { id: "1800", text: `${t("common.info.commonPm")} 06:00` },
    { id: "1815", text: `${t("common.info.commonPm")} 06:15` },
    { id: "1830", text: `${t("common.info.commonPm")} 06:30` },
    { id: "1845", text: `${t("common.info.commonPm")} 06:45` },
    { id: "1900", text: `${t("common.info.commonPm")} 07:00` },
    { id: "1915", text: `${t("common.info.commonPm")} 07:15` },
    { id: "1930", text: `${t("common.info.commonPm")} 07:30` },
    { id: "1945", text: `${t("common.info.commonPm")} 07:45` },
    { id: "2000", text: `${t("common.info.commonPm")} 08:00` },
    { id: "2015", text: `${t("common.info.commonPm")} 08:15` },
    { id: "2030", text: `${t("common.info.commonPm")} 08:30` },
    { id: "2045", text: `${t("common.info.commonPm")} 08:45` },
    { id: "2100", text: `${t("common.info.commonPm")} 09:00` },
    { id: "2115", text: `${t("common.info.commonPm")} 09:15` },
    { id: "2130", text: `${t("common.info.commonPm")} 09:30` },
    { id: "2145", text: `${t("common.info.commonPm")} 09:45` },
    { id: "2200", text: `${t("common.info.commonPm")} 10:00` },
    { id: "2215", text: `${t("common.info.commonPm")} 10:15` },
    { id: "2230", text: `${t("common.info.commonPm")} 10:30` },
    { id: "2245", text: `${t("common.info.commonPm")} 10:45` },
    { id: "2300", text: `${t("common.info.commonPm")} 11:00` },
    { id: "2315", text: `${t("common.info.commonPm")} 11:15` },
    { id: "2330", text: `${t("common.info.commonPm")} 11:30` },
    { id: "2345", text: `${t("common.info.commonPm")} 11:45` },
  ];

  const { deptState, getDeptList } = useDeptStore();
  const { categoryState, getCategories } = useCategoryStore();
  const { programState, addNewProgram, getProgramDetail, updateProgram } =
    useProgramStore();
  const { mailTemplateState, getMailTemplateList, getMailTemplateData } =
    useMailTemplateStore();
  const { quizState, getQuizContentList } = useQuizStore();
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [configId, setConfigId] = useState<number | undefined>();

  const [templates, setTemplates] = useState<Scenario[]>([]);
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const [categoryInput, setCategoryInput] = useState<string>();
  const [categories, setCategories] = useState<string[]>([]);
  const [scenarioInput, setScenarioInput] = useState<string>();

  const [selectedThumbnail, setSelectedThumbnail] = useState<Scenario | null>();

  const checkboxScenarioGroup = useCheckboxGroup<string>();
  const checkboxCateGroup = useCheckboxGroup<string>();
  const userCheckboxGroup = useCheckboxGroup<string>();
  const deptCheckboxGroup = useCheckboxGroup<string>();

  const [searchDeptUserTxt, changeSearchDeptUserText] = useState("");

  // 훈련 정보
  const initializeDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);
  const [isAllDayDate, setIsAllDayDate] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [isAllDayTime, setIsAllDayTime] = useState<boolean>(false);

  const [programInfo, setProgramInfo] = useState<ProgramAddBean>({
    configName: "",
    sendStartDate: new Date().getTime() / 1000,
    sendEndDate: new Date().getTime() / 1000,
    sendMailCount: 0,
    templateIds: "",
    sendStartTime: null,
    sendEndTime: null,
    autoReplyFlag: false,
    realTrainingFlag: false,
    activateFlag: false,
  });
  const [selctedUserListInDetail, changeSelctedUserListInDetail] = useState<
    userListBean[]
  >([]);
  const [selctedTemplateInDetail, changeSelctedTempalteInDetail] = useState<
    string[]
  >([]);
  const [isStarted, changeIsStared] = useState(false);

  useEffect(() => {
    let newCategory = categoryInput?.trim();
    if (newCategory && newCategory.length > 0) {
      setCategories(
        categoryState.categoryList
          .filter((item: Category) => item.categoryName.includes(newCategory))
          ?.map((c: Category) => c.categoryName) ?? []
      );
    } else {
      setCategories(
        categoryState.categoryList?.map(
          (item: Category) => item.categoryName
        ) ?? []
      );
    }
  }, [categoryInput]);

  const isSubset = (subset: any[], set: any[]) => {
    return subset.every((val) => set.includes(val));
  };

  useEffect(() => {
    if (scenarioInput && scenarioInput.length > 0) {
      setTemplates(
        mailTemplateState.mailTemplateList
          .filter((item: Scenario) => {
            return checkboxCateGroup.selected?.length > 0
              ? item.categorys &&
                  isSubset(checkboxCateGroup.selected, item.categorys)
              : item;
          })
          .filter((item: Scenario) => item.templateName.includes(scenarioInput))
      );
    } else {
      setTemplates(
        mailTemplateState.mailTemplateList.filter((item: Scenario) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        })
      );
    }
  }, [scenarioInput]);

  const formatTime = (str: string) => {
    const timePart = str.split("T")[1]; // "00:45:08"
    const [hours, minutes] = timePart.split(":"); // ["00", "45"]
    return `${hours}${minutes}`;
  };

  useEffect(() => {
    if (configId && programState?.configDetail) {
      setProgramInfo({
        ...programState?.configDetail?.trainingInfo,
      });
      userCheckboxGroup.handleAllClick(
        programState?.configDetail?.userList
          ? programState?.configDetail?.userList.map(
              (item: userListBean) => item.userEmail ?? ""
            )
          : programState?.configDetail?.trainingInfo.userIds.filter(
              (item: string) => item
            )
      );
      changeIsStared(
        programState?.configDetail?.trainingInfo?.sendStartDate
          ? !!configId &&
              dayjs(
                programState?.configDetail?.trainingInfo?.sendStartDate +
                  "+00:00"
              ).valueOf() < dayjs().valueOf()
          : false
      );
      changeSelctedUserListInDetail(programState?.configDetail?.userList ?? []);

      changeSelctedTempalteInDetail(
        programState?.configDetail?.templateList.map(
          (item: templateListBean) => item.templateName
        ) ?? []
      );

      checkboxScenarioGroup.handleAllClick(
        programState?.configDetail?.trainingInfo?.templateIds ?? []
      );
      deptCheckboxGroup.handleAllClick(
        programState?.configDetail?.trainingInfo.deptIds
      );
      initializeUserCheckbox();
      // console.log(1);
      setStartDate(
        new Date(
          programState?.configDetail?.trainingInfo.sendStartDate + "+00:00"
        )
      );
      setEndDate(
        new Date(
          programState?.configDetail?.trainingInfo.sendEndDate + "+00:00"
        )
      );

      //훈련 기간 종일 여부
      if (
        programState?.configDetail?.trainingInfo.sendEndDate.endsWith(
          "23:59:59"
        )
      ) {
        setIsAllDayDate(true);
      } else {
        setIsAllDayDate(false);
        setStartDateTime(
          formatTime(programState?.configDetail?.trainingInfo.sendStartDate)
        );
        setEndDateTime(
          formatTime(programState?.configDetail?.trainingInfo.sendEndDate)
        );
      }

      // 발송 시간 있는 경우
      if (programState?.configDetail?.trainingInfo.sendStartTime) {
        setStartTime(
          adjustTime(
            programState?.configDetail?.trainingInfo.sendStartTime,
            9
          ).replace(":", "")
        );

        setEndTime(
          adjustTime(
            programState?.configDetail?.trainingInfo.sendEndTime,
            9
          ).replace(":", "")
        );
        setIsAllDayTime(false);
      } else {
        setIsAllDayTime(true);
      }

      if (programState?.configDetail.trainingInfo.quizType) {
        setQuizFlag(true);
        setQuizInfo({
          quizCount: programState?.configDetail.trainingInfo.quizCount,
          quizIds: programState?.configDetail.trainingInfo.quizIds,
          quizType: programState?.configDetail.trainingInfo.quizType,
        });
        getQuizContentList({});
      }
    }
  }, [programState.configDetail]);

  useEffect(() => {
    if (configId) {
      if (
        quizState.quizContentList &&
        quizState.quizContentList.list.length > 0
      ) {
        setSelectedQuizList(
          quizState.quizContentList.list.filter((t: QuizContentDTO) =>
            programState?.configDetail.trainingInfo.quizIds.some(
              (q: string) => parseInt(q) === t.quizId
            )
          )
        );
      }
    }
  }, [quizState.quizContentList]);

  useEffect(() => {
    getDeptList();
    getCategories();
    getMailTemplateList({});

    if (configId) {
      getProgramDetail(configId);
    }
  }, [configId]);

  useEffect(() => {
    const tempConfigId = searchParams.get("config_id");
    if (tempConfigId) {
      setConfigId(parseInt(tempConfigId));
    }
    setProgramInfo({});
    setStartDate(null);
    setEndDate(null);
    setStartDateTime(null);
    setEndDateTime(null);
    setStartTime("");
    setEndTime("");
    userCheckboxGroup.handleAllClick([]);
    deptCheckboxGroup.handleAllClick([]);
    checkboxCateGroup.handleAllClick([]);
    checkboxScenarioGroup.handleAllClick([]);

    //   const tempConfigId = searchParams.get("config_id")
    //   if(tempConfigId) {
    //     setConfigId(parseInt(tempConfigId));
    //   }
  }, []);

  useEffect(() => {
    setTemplates(mailTemplateState.mailTemplateList);
    if (selctedTemplateInDetail.length === 0)
      changeSelctedTempalteInDetail(
        mailTemplateState.mailTemplateList
          .filter((item1: Scenario) =>
            checkboxScenarioGroup.selected.includes(item1.templateId + "")
          )
          .map((item: Scenario) => item.templateName)
      );
  }, [mailTemplateState.mailTemplateList]);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  useEffect(() => {
    setCategories(
      categoryState.categoryList?.map((item: Category) => item.categoryName) ??
        []
    );
  }, [categoryState.categoryList]);

  const onClose = () => {
    navigate(`${process.env.PUBLIC_URL}/manageTrain/list`);
  };

  const handleDate = (key: string, type: string, target: "hour" | "min") => {
    if (type === "start") {
      setStartDateTime(key);
      const tempDate = startDate ?? initializeDate();
      target === "hour" && tempDate.setHours(parseInt(key, 10));
      target === "min" && tempDate.setMinutes(parseInt(key, 10));
      tempDate.setMilliseconds(0);
      // console.log(2);

      setStartDate(tempDate);
    } else {
      setEndDateTime(key);
      const tempDate = endDate ? endDate : initializeDate();
      target === "hour" && tempDate.setHours(parseInt(key, 10));
      target === "min" && tempDate.setMinutes(parseInt(key, 10));
      tempDate.setMilliseconds(0);
      setEndDate(tempDate);
    }
  };

  const adjustTime = (time: string, hoursToSubtract: number): string => {
    const hours = time.includes(":")
      ? parseInt(time.slice(0, 2), 10)
      : parseInt(time.slice(0, 2), 10);
    const minutes = time.includes(":")
      ? parseInt(time.slice(3, 5), 10)
      : parseInt(time.slice(2, 4), 10);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    date.setHours(date.getHours() + hoursToSubtract);

    const adjustedHours = date.getHours().toString().padStart(2, "0");
    const adjustedMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${adjustedHours}:${adjustedMinutes}`;
  };

  const onSubmit = async () => {
    // 훈련 기본 정보

    // 유효성 검사
    if (!startDate || !endDate) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.notSelectedDurationMsg"),
        "error"
      );

      return;
    }
    const tempStartDate = new Date(dayjs(startDate).valueOf());
    const tempEndDate = new Date(dayjs(endDate).valueOf());
    // 시나리오
    if (isAllDayDate) {
      tempStartDate.setHours(0);
      tempStartDate.setMinutes(0);
      tempStartDate.setSeconds(0);
      tempEndDate.setHours(23);
      tempEndDate.setMinutes(59);
      tempEndDate.setSeconds(59);
    }

    if (startDate >= endDate) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programEndBeforeStartMsg"),
        "error"
      );
      return;
    }
    if (
      (!isStarted && dayjs(tempStartDate).valueOf() < dayjs().valueOf()) ||
      dayjs(tempEndDate).valueOf() < dayjs().valueOf()
    ) {
      toast.toastMsg(nanoid(), t("program.msg.programNowFailMsg"), "error");
      return;
    }
    if (!isAllDayTime && startTime >= endTime) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programStartTimeExceedMsg"),
        "error"
      );
      return;
    }
    if (
      programInfo.sendMailCount &&
      programInfo.sendMailCount >
        (checkboxScenarioGroup.selected.length === 0
          ? templates.length
          : checkboxScenarioGroup.selected.length)
    ) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programLessTemplateMsg"),
        "error"
      );
      return;
    }

    // if (startTime) {
    //   console.log(adjustTime(startTime, 9));

    //   return;
    // }

    if (
      programInfo.configName &&
      startDate &&
      endDate &&
      programInfo.sendMailCount
    ) {
      const params = quizFlag
        ? {
            configName: programInfo.configName,
            sendStartDate: tempStartDate?.getTime() / 1000,
            sendEndDate: tempEndDate?.getTime() / 1000,
            sendMailCount: programInfo.sendMailCount,
            templateIds:
              checkboxScenarioGroup.selected.length === 0
                ? templates.map((t) => t.templateId)
                : checkboxScenarioGroup.selected,
            sendStartTime: isAllDayTime ? "" : adjustTime(startTime, -9),
            sendEndTime: isAllDayTime ? "" : adjustTime(endTime, -9),
            deptIds: deptCheckboxGroup.selected.filter((item) => item),
            userIds: organTree
              ?.filter(
                (item) =>
                  item.type === "user" &&
                  userCheckboxGroup.selected.includes(item.deptCode) &&
                  !deptCheckboxGroup.selected.includes(item.parentCode)
              )
              .map((item1) => item1?.deptCode),
            autoReplyFlag: !programInfo.activateFlag
              ? false
              : programInfo.autoReplyFlag,
            activateFlag: programInfo.activateFlag,
            realTrainingFlag: programInfo.realTrainingFlag
              ? programInfo.realTrainingFlag
              : false,
            quizIds: selectedQuizList
              .filter((q) => q.quizId != null)
              .map((item) => (item.quizId ? item.quizId.toString() : "")),
            quizCount: quizInfo.quizCount,
            quizType: quizInfo.quizType,
          }
        : {
            configName: programInfo.configName,
            sendStartDate: tempStartDate?.getTime() / 1000,
            sendEndDate: tempEndDate?.getTime() / 1000,
            sendMailCount: programInfo.sendMailCount,
            templateIds:
              checkboxScenarioGroup.selected.length === 0
                ? templates.map((t) => t.templateId)
                : checkboxScenarioGroup.selected,
            sendStartTime: isAllDayTime ? "" : adjustTime(startTime, -9),
            sendEndTime: isAllDayTime ? "" : adjustTime(endTime, -9),
            deptIds: deptCheckboxGroup.selected.filter((item) => item),
            userIds: organTree
              ?.filter(
                (item) =>
                  item.type === "user" &&
                  userCheckboxGroup.selected.includes(item.deptCode) &&
                  !deptCheckboxGroup.selected.includes(item.parentCode)
              )
              .map((item1) => item1?.deptCode),
            autoReplyFlag: !programInfo.activateFlag
              ? false
              : programInfo.autoReplyFlag,
            activateFlag: programInfo.activateFlag,
            realTrainingFlag: programInfo.realTrainingFlag
              ? programInfo.realTrainingFlag
              : false,
          };

      if (configId) {
        const resp: any = await updateProgram({
          ...params,
          configId,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("program.msg.programUpdateSuccessMsg"),
            "success"
          );
          navigate(`${process.env.PUBLIC_URL}/manageTrain/list`);
          // refreshList();
          // onClose();
        } else {
          toast.toastMsg(
            nanoid(),
            t("program.msg.programUpdateFailMsg"),
            "error"
          );
        }
      } else {
        const resp: any = await addNewProgram(params);
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("program.msg.programAddSuccessMsg"),
            "success"
          );
          navigate(`${process.env.PUBLIC_URL}/manageTrain/list`);
          // onClose();
        } else {
          toast.toastMsg(nanoid(), t("program.msg.programAddFailMsg"), "error");
        }
      }
    }
  };
  const initializeUserCheckbox = async () => {
    let resp: any = await getDeptList(
      programState?.configDetail?.trainingInfo.deptIds
    );
    if (resp.payload) {
      let newUsers =
        resp.payload?.data?.list?.map(
          (item: SimpleTreeNode) => item.deptCode
        ) ?? [];
      newUsers = newUsers.concat(
        ...programState?.configDetail?.trainingInfo.userIds.filter(
          (item: string) => item
        )
      );
      userCheckboxGroup.handleAllClick(
        newUsers.filter((item: string, index: number) => {
          return newUsers.indexOf(item) === index;
        })
      );
    }
  };
  const viewThumbnail = async (id: number) => {
    const resp: any = await getMailTemplateData(id);
    setSelectedThumbnail(resp.payload?.data ?? null);
  };

  const searchTree = async () => {
    // 검색을 해서 보여주자
  };

  const footer = (
    <Box justifyContent="flex-end" height={80}>
      <Button color="secondary" onClick={onClose}>
        {t("common.button.cancel")}
      </Button>
      {configId ? (
        <Button classes="mg ml-8" onClick={onSubmit}>
          {t("common.button.update")}
        </Button>
      ) : (
        <Button
          classes="mg ml-8"
          onClick={onSubmit}
          disabled={
            !programInfo.configName ||
            !programInfo.sendMailCount ||
            programInfo?.sendMailCount < 1 ||
            programInfo?.sendMailCount > 100 ||
            userCheckboxGroup.selected.length === 0 ||
            (checkboxScenarioGroup.selected.length === 0 &&
              templates.length === 0)
          }
        >
          {t("common.button.create")}
        </Button>
      )}
    </Box>
  );

  // 훈련 설정 body
  const infoBody = (
    <Box
      direction="column"
      // width={400}
      height={450}
      classes="mg mt-8 ml-10 mr-10"
    >
      {/* training name start */}
      <Box direction="column" height={72} classes="mg mb-28">
        <Typography fontWeight={700} classes="mg mb-12">
          {t("program.programName")}
        </Typography>
        <TextField
          placeholder={t("program.msg.programUndefinedNameMsg")}
          height={40}
          borderRadius={8}
          hasText
          disabled={isStarted}
          maxLength={128}
          text={programInfo.configName ?? ""}
          onChange={(e) =>
            setProgramInfo((prev) => ({ ...prev, configName: e.target.value }))
          }
        />
      </Box>
      {/* training name end */}
      <Box>
        {/* trianing period start */}
        <Box direction="column" classes="mg mb-28">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("program.trainingDuration")}
          </Typography>
          <Box>
            <Calendar
              onSubmit={(date) => setStartDate(date)}
              placeholder={t("common.info.startDate")}
              dateFormat="yyyy-MM-dd"
              width={210}
              height={40}
              minDate={new Date(dayjs().subtract(1, "D").valueOf())}
              maxDate={endDate ? endDate : undefined}
              selected={startDate ? startDate : undefined}
              disable={isStarted}
              lang={i18n.language}
            />
            {/* <Select
                classes="mg ml-8"
                width={210}
                height={40}
                disabled={isAllDayDate || isStarted}
                onClick={(key: string) => handleDate(key, "start")}
                selectedEventKey={startDateTime !== null ? startDateTime : ""}
                // placeholder="오전 00:00"
              >
                {timeTyps.map((time) => (
                  <SelectItem key={time.id} eventKey={time.id}>
                    {time.text}
                  </SelectItem>
                ))}
              </Select> */}
            <Select
              width={100}
              height={40}
              classes="mg ml-8"
              disabled={isAllDayDate || isStarted}
              selectedEventKey={dayjs(startDate).get("hour") + ""}
              onClick={(key: string) => handleDate(key, "start", "hour")}
            >
              {Array(24)
                .fill("")
                .map((item, index) => (
                  <SelectItem eventKey={index + ""}>
                    {index < 10 ? "0" + index : index}
                  </SelectItem>
                ))}
            </Select>
            <Select
              width={100}
              classes="mg ml-8"
              height={40}
              disabled={isAllDayDate || isStarted}
              selectedEventKey={dayjs(startDate).get("minute") + ""}
              onClick={(key: string) => handleDate(key, "start", "min")}
            >
              {Array(60)
                .fill("")
                .map((item, index) => (
                  <SelectItem eventKey={index + ""}>
                    {index < 10 ? "0" + index : index}
                  </SelectItem>
                ))}
            </Select>
            <Box alignItems="center" classes="mg ml-8 mr-8">
              <Typography>~</Typography>
            </Box>
            <Calendar
              onSubmit={(date) => setEndDate(date)}
              placeholder={t("common.info.endDate")}
              dateFormat="yyyy-MM-dd"
              width={210}
              height={40}
              minDate={
                startDate ?? new Date(dayjs().subtract(1, "D").valueOf())
              }
              disable={isStarted}
              selected={endDate ? endDate : undefined}
              lang={i18n.language}
            />
            <Select
              width={100}
              height={40}
              classes="mg ml-8"
              selectedEventKey={dayjs(endDate).get("hour") + ""}
              disabled={isAllDayDate || isStarted}
              onClick={(key: string) => handleDate(key, "end", "hour")}
            >
              {Array(24)
                .fill("")
                .map((item, index) => (
                  <SelectItem eventKey={index + ""}>
                    {index < 10 ? "0" + index : index}
                  </SelectItem>
                ))}
            </Select>
            <Select
              width={100}
              classes="mg ml-8"
              height={40}
              disabled={isAllDayDate || isStarted}
              selectedEventKey={dayjs(endDate).get("minute") + ""}
              onClick={(key: string) => handleDate(key, "end", "min")}
            >
              {Array(60)
                .fill("")
                .map((item, index) => (
                  <SelectItem eventKey={index + ""}>
                    {index < 10 ? "0" + index : index}
                  </SelectItem>
                ))}
            </Select>
          </Box>
          <Box classes="mg mt-18" alignItems="center">
            <Checkbox
              checked={isAllDayDate}
              onChange={() => setIsAllDayDate((prev) => !prev)}
              disabled={isStarted}
            />
            <Typography classes="mg ml-8">
              {t("program.programAllDay")}
            </Typography>
          </Box>
        </Box>
        {/* trining period end */}
      </Box>

      <Box>
        <Box direction="column" classes="mg mb-28 mr-24">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("program.sendingMailTime")}
          </Typography>

          <Box>
            <Select
              classes="mg"
              width={202}
              height={40}
              disabled={isAllDayTime}
              onClick={(key: string) => setStartTime(key)}
              selectedEventKey={startTime}
              title={
                startTime === "" ? t("program.selectStartTime") : undefined
              }
            >
              {timeTyps
                // .filter((item1) => (endTime ? item1.id < endTime : true))
                .map((time) => (
                  <SelectItem key={time.id} eventKey={time.id}>
                    {time.text}
                  </SelectItem>
                ))}
            </Select>
            <Box alignItems="center" classes="mg ml-8 mr-8">
              <Typography>~</Typography>
            </Box>
            <Select
              classes="mg"
              width={202}
              height={40}
              disabled={isAllDayTime}
              onClick={(key: string) => setEndTime(key)}
              selectedEventKey={endTime}
              title={endTime === "" ? t("program.selectEndTime") : undefined}
            >
              {timeTyps
                // .filter((item1) => (startTime ? item1.id > startTime : true))
                .map((time) => (
                  <SelectItem key={time.id} eventKey={time.id}>
                    {time.text}
                  </SelectItem>
                ))}
            </Select>
          </Box>

          <Box classes="mg mt-18" alignItems="center">
            <Checkbox
              checked={isAllDayTime}
              onChange={() => setIsAllDayTime((prev) => !prev)}
            />
            <Typography classes="mg ml-8">
              {" "}
              {t("program.programAllDay")}
            </Typography>
          </Box>
        </Box>

        <Box direction="column">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("program.sendMailCnt")}
          </Typography>
          <Box width={"250px"}>
            <FormInput
              size={"lg"}
              borderRadius={8}
              wordAfterValue={t("program.programDataUnit")}
              maxLength={2}
              hasText
              fullWidth
              type="localeNumber"
              text={programInfo.sendMailCount?.toString() ?? "0"}
              onChange={(e) =>
                setProgramInfo((prev) => ({
                  ...prev,
                  sendMailCount: parseInt(e.target.value),
                }))
              }
              disabled={isStarted}
              isError={
                programInfo?.sendMailCount
                  ? programInfo?.sendMailCount < 1 ||
                    programInfo?.sendMailCount > 100
                  : false
              }
            />
          </Box>

          <Box classes="mg mt-14">
            <Icons
              variant="common"
              label="important"
              width={16}
              stroke="#717985"
            />
            <Typography size={12} color="secondary" classes="mg ml-4">
              {t("program.cntInfo")}
            </Typography>
          </Box>
          {programInfo?.sendMailCount &&
          (programInfo?.sendMailCount < 1 ||
            programInfo?.sendMailCount > 100) ? (
            <Box classes="mg mt-4">
              <Icons
                variant="common"
                label="important"
                width={16}
                stroke="red"
              />
              <Typography size={12} color="red" classes="mg ml-4">
                {t("program.msg.cntInfoMsg")}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box width="100%">
        <Box direction="column" classes="mg mb-28" width={"33%"}>
          <Typography fontWeight={700} classes="mg mb-12">
            {t("template.type")}
          </Typography>
          <RadioGroup
            onChange={(e) => {
              if (e.target.value === "모의")
                setProgramInfo((prev) => ({
                  ...prev,
                  realTrainingFlag: false,
                }));
              else
                setProgramInfo((prev) => ({ ...prev, realTrainingFlag: true }));
            }}
          >
            <Radio
              label={t("program.mockTrainingLabel")}
              checked={!programInfo.realTrainingFlag}
              value="모의"
              disabled={isStarted}
            />
            <Radio
              label={t("program.realTrainingLabel")}
              checked={programInfo.realTrainingFlag}
              value="실전"
              disabled={isStarted}
            />
          </RadioGroup>
        </Box>
        <Box direction="column" classes="mg mb-28 ml-12" width={"33%"}>
          <Typography fontWeight={700} classes="mg mb-12">
            {t("program.setActivateFlag")}
          </Typography>
          <Switch
            checked={programInfo.activateFlag}
            onChange={() =>
              setProgramInfo((prev) => ({
                ...prev,
                activateFlag: !prev.activateFlag,
              }))
            }
            // disabled={isStarted}
          />
        </Box>
        <Box direction="column" classes="mg mb-28 ml-12">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("reply.isActivateAutoReply")}
          </Typography>
          <Switch
            checked={
              !programInfo.activateFlag ? false : programInfo.autoReplyFlag
            }
            onChange={() =>
              setProgramInfo((prev) => ({
                ...prev,
                autoReplyFlag: !prev.autoReplyFlag,
              }))
            }
            // disabled={!programInfo.activateFlag || isStarted}
            disabled={!programInfo.activateFlag}
          />
        </Box>
      </Box>
    </Box>
  );
  // 대상자 선택 body
  const userBody = (
    <Box classes="mg ml-12 mb-10" direction="column">
      {selctedUserListInDetail?.length === 0 && (
        <Box
          classes="mg mt-24"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography fontWeight={700}>
              {t("program.selectedUser")}
            </Typography>
            <Typography fontWeight={700} color="green" classes="mg ml-8">
              {userCheckboxGroup.selected.length}
              {t("program.programPeopleUnit")}
            </Typography>
          </Box>
          <Box>
            {/*
              검색 기능 추가
              <TextField
                width={200}
                height={40}
                borderRadius={8}
                placeholder={t("dept.dept") + " / " + t("user.user")}
                hasText
                text={searchDeptUserTxt}
                onChange={(e) => changeSearchDeptUserText(e.target.value)}
              />
              <Button
                classes="mg ml-8"
                text={t("common.button.search")}
                onClick={() => searchTree()}
              />
              */}
          </Box>
        </Box>
      )}
      {/* 조직도 */}
      <Box classes="mg mt-8">
        <ScrollBox maxHeight={356} width={904} classes="pd pb-4">
          {selctedUserListInDetail.length > 0 ? (
            <Box width={"100%"} classes="gridList">
              {selctedUserListInDetail.map((item) => (
                <SmallUserChip
                  userId={item.userEmail ?? ""}
                  userName={item.userName}
                  classes="mg mr-4 "
                />
              ))}
            </Box>
          ) : (
            organTree && (
              <TreeSimple
                rootCode={"COMPANY"}
                treeNodes={organTree}
                depth={0}
                checkboxUserGroup={userCheckboxGroup}
                checkboxDeptGroup={deptCheckboxGroup}
                checkUserIds={userCheckboxGroup.selected}
                isUserCheck
                isDeptCheck
                disableCheck={
                  configId &&
                  programState.configDetail?.trainingInfo &&
                  programState?.configDetail?.trainingInfo.startedFlag
                }
                // isShowUserCnt
                classes="mg mt-8"
              />
            )
          )}
        </ScrollBox>
      </Box>
    </Box>
  );

  useEffect(() => {
    if (checkboxCateGroup.selected.length === 0) {
      setTemplates(mailTemplateState.mailTemplateList);
    } else {
      setTemplates(
        mailTemplateState.mailTemplateList.filter((item: Scenario) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        })
      );
      // setTemplates(
      //   mailTemplateState.mailTemplateList.filter((item: Scenario) => {
      //     return item.categorys?.some((c) =>
      //       checkboxCateGroup.selected.includes(c)
      //     );
      //   })
      // );
    }
  }, [checkboxCateGroup.selected]);

  // 훈련 시나리오 선택 body
  const templateSelectBody = (
    <Box height={528} classes="mg ml-12 mt-8">
      {/* category start */}
      <Box direction="column" width={270} classes="mg mr-16">
        <Box direction="column" classes="mg mb-28">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("category.selectCategory")}
          </Typography>
          <TextField
            width={270}
            height={40}
            borderRadius={8}
            placeholder={t("template.msg.categoryPlaceholder")}
            hasText
            text={categoryInput ?? ""}
            onChange={(e) => setCategoryInput(e.target.value)}
          />
          <ScrollBox
            style={{ borderRight: "1px solid gray", height: "448px" }}
            classes="mg mt-24"
            maxHeight={448}
          >
            {categories && categories?.length > 0 ? (
              <>
                {!categoryInput && (
                  <Box
                    style={{
                      backgroundColor:
                        checkboxCateGroup.selected.length === 0
                          ? "#EAF4FF"
                          : "#FFFFFF",
                      display: "flex",
                      height: "48px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      padding: "8px",
                    }}
                  >
                    <Box alignItems="center">
                      <Typography classes="mg ml-8">
                        {t("common.auth.all")}
                      </Typography>
                    </Box>
                    <Checkbox
                      radio={true}
                      onChange={() => {
                        checkboxCateGroup.handleAllClick([]);
                      }}
                      checked={
                        checkboxCateGroup.selected.length > 0 ? false : true
                      }
                    />
                  </Box>
                )}

                {categories?.map((item) => (
                  <Box
                    key={item}
                    style={{
                      backgroundColor: checkboxCateGroup.isSelected(item)
                        ? "#EAF4FF"
                        : "#FFFFFF",
                      display: "flex",
                      height: "48px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      padding: "8px",
                    }}
                  >
                    <Box alignItems="center">
                      <Typography classes="mg ml-8">{item}</Typography>
                    </Box>
                    <Checkbox
                      radio={true}
                      onChange={() => {
                        checkboxCateGroup.handleClick(item);
                        setScenarioInput("");
                      }}
                      checked={checkboxCateGroup.isSelected(item)}
                    />
                  </Box>
                ))}
              </>
            ) : categoryInput?.length &&
              categoryInput?.length > 0 &&
              categories.length === 0 ? (
              <Box
                alignItems="center"
                justifyContent="center"
                direction="column"
                height={"100%"}
              >
                <NoSearchResultImage width={200} classes="mg mt-120" />
                <Typography size={14} classes="mg mt-16">
                  {t("common.msg.noSearchMsg")}
                </Typography>
              </Box>
            ) : (
              <Box
                alignItems="center"
                justifyContent="center"
                direction="column"
                height={"100%"}
              >
                <NoInfoImage width={200} classes="mg mt-120" />
                <Typography size={14} classes="mg mt-16">
                  {t("common.msg.noHistoryMsg")}
                </Typography>
              </Box>
            )}
          </ScrollBox>
        </Box>
      </Box>
      {/* category end */}
      {/* template start*/}
      <Box direction="column" classes="mg mr-16" width={270}>
        <Typography fontWeight={700} classes="mg mb-12">
          {t("program.selectScenario")}
        </Typography>
        <TextField
          width={270}
          height={40}
          borderRadius={8}
          placeholder={t("template.msg.templateSimplePlaceholder")}
          hasText
          text={scenarioInput ?? ""}
          onChange={(e) => setScenarioInput(e.target.value)}
        />
        <ScrollBox style={{ borderRight: "1px solid gray" }} classes="mg mt-24">
          {templates && templates.length > 0 ? (
            <>
              {!scenarioInput && (
                <Box
                  style={{
                    backgroundColor:
                      checkboxScenarioGroup.selected.length === 0
                        ? "#EAF4FF"
                        : "#FFFFFF",
                    display: "flex",
                    height: "48px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    padding: "8px",
                  }}
                >
                  <Box alignItems="center">
                    <Typography classes="mg ml-8">
                      {t("common.auth.all")}
                    </Typography>
                  </Box>
                  <Checkbox
                    radio={true}
                    onChange={() => {
                      checkboxScenarioGroup.handleAllClick([]);
                      setSelectedThumbnail(undefined);
                    }}
                    checked={
                      checkboxScenarioGroup.selected.length > 0 ? false : true
                    }
                  />
                </Box>
              )}

              {templates.map((item) => (
                <Box
                  key={item.templateId}
                  style={{
                    backgroundColor: checkboxScenarioGroup.isSelected(
                      item.templateId.toString()
                    )
                      ? "#EAF4FF"
                      : "#FFFFFF",
                    display: "flex",
                    height: "48px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    padding: "8px",
                  }}
                  onClick={() => viewThumbnail(item.templateId)}
                >
                  <Box alignItems="center" width={"80%"}>
                    <ToolTip message={item.templateName} maxWidth={"100%"}>
                      <Typography
                        classes="mg ml-8 overflow-hidden scenario__select__item"
                        lineHeight="normal"
                        maxWidth={"100%"}
                      >
                        {item.templateName}
                      </Typography>
                    </ToolTip>
                  </Box>
                  <Checkbox
                    radio={true}
                    onChange={() => {
                      // e.stopPropagaiton();

                      let last = checkboxScenarioGroup.selected.findLast(
                        (item1) => item1 !== item.templateId + ""
                      );
                      viewThumbnail(
                        checkboxScenarioGroup.isSelected(
                          item.templateId + ""
                        ) &&
                          checkboxScenarioGroup.selected?.length > 0 &&
                          last
                          ? parseInt(last)
                          : item.templateId
                      );

                      checkboxScenarioGroup.handleClick(
                        item.templateId.toString()
                      );
                    }}
                    checked={checkboxScenarioGroup.isSelected(
                      item.templateId.toString()
                    )}
                  />
                </Box>
              ))}
            </>
          ) : scenarioInput &&
            scenarioInput.length > 0 &&
            templates.length === 0 ? (
            <Box
              alignItems="center"
              justifyContent="center"
              direction="column"
              height={"100%"}
            >
              <NoSearchResultImage width={200} classes="mg mt-120" />
              <Typography size={14} classes="mg mt-16">
                {t("common.msg.noSearchMsg")}
              </Typography>
            </Box>
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              direction="column"
              height={"100%"}
            >
              <NoInfoImage width={200} classes="mg mt-120" />
              <Typography size={14} classes="mg mt-16">
                {t("common.msg.noHistoryMsg")}
              </Typography>
            </Box>
          )}
        </ScrollBox>
      </Box>
      {/* template end */}
      {/* thumbnail start */}
      <Box width={280} classes="mg ml-16" direction="column">
        <Typography fontWeight={700} classes="mg mb-12">
          {t("template.preview")}
        </Typography>

        {selectedThumbnail &&
        !checkboxScenarioGroup.isSelected("0") &&
        checkboxScenarioGroup.selected.length > 0 ? (
          <Box
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            classes="no__selected__scenario__box"
          >
            {/* <HTMLThumbnail
                mail={{
                  ...selectedThumbnail,
                  templateHtml: selectedThumbnail.imagePath
                    ? selectedThumbnail.templateHtml.replace(
                        "##IMAGE##",
                        selectedThumbnail.imagePath
                      )
                    : selectedThumbnail.templateHtml,
                  // ...selectedThumbnail,
                }}
              /> */}
            <MailBody
              width={"248px"}
              body={
                selectedThumbnail.imagePath
                  ? selectedThumbnail.templateHtml.replaceAll(
                      "##IMAGE##",
                      selectedThumbnail.imagePath
                    )
                  : selectedThumbnail.templateHtml
              }
              id={selectedThumbnail.templateId}
            />
          </Box>
        ) : (
          <Box
            direction="column"
            classes="no__selected__scenario__box"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <NoInfoImage />
            <Typography classes="mg mt-16" color="secondary">
              {t("program.msg.notSelectedTemplateMsg")}
            </Typography>
          </Box>
        )}
      </Box>
      {/* thumbnail end  */}
    </Box>
  );
  const templateBody = () => {
    return (
      <ScrollBox maxHeight={307} classes="mg ml-10 mr-10 gridList">
        {selctedTemplateInDetail &&
          selctedTemplateInDetail.map((template) => (
            <Box classes="mg mr-4 scenario__tag">
              <Typography>{template}</Typography>
            </Box>
          ))}
      </ScrollBox>
    );
  };

  const [quizFlag, setQuizFlag] = useState<boolean>(false);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState<boolean>(false);
  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );
  const [quizInfo, setQuizInfo] = useState<QuizTrainingInfoReq>({
    quizType: 1,
    quizIds: [],
    quizCount: 0,
  });

  const quizSelectBody = (
    <Box classes="mg ml-8 mt-12" direction="column">
      <Box classes="mg mb-28">
        <Typography color="secondary" size={13}>
          훈련을 위반한 사용자에게 자동으로 보안 퀴즈를 전송할 수 있습니다.
          ‘메일 발송 시점’이 ‘훈련 종료 시’인 경우 훈련 종료 즉시 훈련 위반자를
          대상으로 퀴즈 코스가 생성돼 자동으로 학습 이메일이 발송됩니다(발송
          기간: 1주).
        </Typography>
      </Box>
      <Box classes="mg mb-28">
        <Box width={220} direction="column" classes="mg mr-28">
          <Box style={{ marginBottom: "4px" }}>
            <Typography color="secondary" fontWeight={700}>
              퀴즈 코스 연계
            </Typography>
          </Box>
          <Typography size={12} color="secondary">
            훈련 위반자에게 퀴즈 메일을 전송합니다.
          </Typography>
        </Box>
        <Box>
          <Switch
            label={quizFlag ? "ON" : "OFF"}
            onChange={() => setQuizFlag((prev) => !prev)}
            checked={quizFlag}
          />
        </Box>
      </Box>
      <Box classes="mg mb-28">
        <Box width={220} direction="column" classes="mg mr-28">
          <Box style={{ marginBottom: "4px" }}>
            <Typography color="secondary" fontWeight={700}>
              메일 발송 시점
            </Typography>
          </Box>
        </Box>
        <Box>
          <RadioGroup
            disabled={!quizFlag}
            onChange={(e) =>
              setQuizInfo((prev) => ({
                ...prev,
                quizType: parseInt(e.target.value),
              }))
            }
          >
            <Radio
              label="위반 행위 발생 시"
              value="1"
              checked={quizInfo.quizType === 1}
            ></Radio>
            <Radio
              label="훈련 종료 시"
              value="2"
              checked={quizInfo.quizType === 2}
            ></Radio>
          </RadioGroup>
        </Box>
      </Box>
      <Box classes="mg mb-40">
        <Box width={220} direction="column" classes="mg mr-28">
          <Box style={{ marginBottom: "4px" }}>
            <Typography color="secondary" fontWeight={700}>
              전송 퀴즈 수
            </Typography>
          </Box>
          <Typography size={12} color="secondary">
            메일 1건에 전송할 퀴즈 수입니다.
          </Typography>
        </Box>
        <Box alignItems="center">
          <TextField
            disabled={!quizFlag}
            width={66}
            height={40}
            borderRadius={8}
            type="positive-integer"
            classes="mg mr-8"
            hasText
            text={quizInfo.quizCount ? quizInfo.quizCount.toString() : ""}
            onChange={(e) => {
              if (
                !e.target.value ||
                (parseInt(e.target.value) >= 0 &&
                  parseInt(e.target.value) <= 10)
              ) {
                setQuizInfo((prev) => ({
                  ...prev,
                  quizCount: parseInt(e.target.value ? e.target.value : "0"),
                }));
              }
            }}
          />
          건
        </Box>
      </Box>
      <Box classes="mg mb-28">
        <Box width={220} direction="column" classes="mg mr-28">
          <Box style={{ marginBottom: "4px" }}>
            <Typography color="secondary" fontWeight={700}>
              퀴즈 문제
            </Typography>
          </Box>
          <Typography size={12} color="secondary">
            퀴즈 문제 수는 ‘ 대상자별 발송 메일 수 X 전송 퀴즈 수' 이상이어야
            합니다.
          </Typography>
        </Box>

        <Box alignItems="center" direction="column" width={590}>
          <Box alignItems="center" justifyContent="flex-start" width={"100%"}>
            <Button
              startIcon={
                <Icons
                  variant="common"
                  label="plus"
                  stroke={quizFlag ? "#252d38" : "#D1D6DB"}
                />
              }
              // color={quizFlag ? "secondary" : }
              disabled={!quizFlag}
              color="secondary"
              textColor="#D1D6DB"
              text="퀴즈 선택"
              onClick={() => setIsQuizModalOpen(true)}
              classes="quiz__select__button"
            ></Button>
            <Box classes="mg ml-12">
              <Typography color={quizFlag ? "primary" : "textGrey"}>
                추가된 대상
              </Typography>
            </Box>
            <Box classes="mg ml-4">
              <Typography
                color={
                  !quizFlag
                    ? "textGrey"
                    : programInfo.sendMailCount &&
                      selectedQuizList.length <
                        programInfo.sendMailCount * quizInfo.quizCount
                    ? "red"
                    : "blue"
                }
              >
                {selectedQuizList.length}
              </Typography>
              <Box classes="mg mr-4"></Box>
              <Typography>
                {quizFlag &&
                  `/ ${
                    (programInfo.sendMailCount ?? 0) * (quizInfo.quizCount ?? 0)
                  }`}
              </Typography>
            </Box>
          </Box>
          <Table classes="mg mt-12">
            <colgroup>
              <col style={{ width: "44px" }} />
              <col style={{ width: "72px" }} />
              <col style={{ width: "142px" }} />
              <col style={{ width: "*" }} />
              <col style={{ width: "80px" }} />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <Checkbox />
                </th>
                <th>구분</th>
                <th>퀴즈 이름</th>
                <th>카테고리</th>
                <th>유형</th>
              </tr>
            </thead>
          </Table>
          <Box classes="table-scroll">
            {selectedQuizList.length === 0 ? (
              <Box
                direction="column"
                alignItems="center"
                justifyContent="center"
                width={"100%"}
                height={154}
              >
                <NoQuizImage />
                <Typography>
                  '퀴즈 선택' 버튼을 클릭해 문제를 선택하세요.
                </Typography>
              </Box>
            ) : (
              <Table>
                <colgroup>
                  <col style={{ width: "44px" }} />
                  <col style={{ width: "72px" }} />
                  <col style={{ width: "142px" }} />
                  <col style={{ width: "*" }} />
                  <col style={{ width: "80px" }} />
                </colgroup>
                <tbody>
                  {" "}
                  {selectedQuizList.map((q) => (
                    <tr>
                      <td></td>
                      <td>
                        {q.commonId ? (
                          <Tag
                            name={t("template.public")}
                            size={i18n.language === "ko" ? "ms" : "sm"}
                            color="blue"
                            radius={6}
                          />
                        ) : (
                          <Tag
                            name={t("template.private")}
                            size={i18n.language === "ko" ? "ms" : "sm"}
                            color="pink"
                            radius={6}
                          />
                        )}
                      </td>
                      <td>{q.quizName}</td>
                      <td>{q.categorys}</td>
                      <td>{q.quizType === 1 ? "O/X" : "5지 선다"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const quizBody = () => {
    return (
      <Box classes="mg ml-8 mt-12" direction="column">
        <Box classes="mg mb-28">
          <Box width={220} direction="column" classes="mg mr-28">
            <Box style={{ marginBottom: "4px" }}>
              <Typography color="secondary" fontWeight={700}>
                퀴즈 코스 연계
              </Typography>
            </Box>
            <Typography size={12} color="secondary">
              훈련 위반자에게 퀴즈 메일을 전송합니다.
            </Typography>
          </Box>
          <Box>
            <Switch
              label={quizFlag ? "ON" : "OFF"}
              onChange={() => setQuizFlag((prev) => !prev)}
              checked={quizFlag}
              disabled
            />
          </Box>
        </Box>
        <Box classes="mg mb-28">
          <Box width={220} direction="column" classes="mg mr-28">
            <Box style={{ marginBottom: "4px" }}>
              <Typography color="secondary" fontWeight={700}>
                메일 발송 시점
              </Typography>
            </Box>
          </Box>
          <Box>
            <RadioGroup
              onChange={(e) =>
                setQuizInfo((prev) => ({
                  ...prev,
                  quizType: parseInt(e.target.value),
                }))
              }
              disabled
            >
              <Radio
                label="위반 행위 발생 시"
                value="1"
                checked={quizInfo.quizType === 1}
              ></Radio>
              <Radio
                label="훈련 종료 시"
                value="2"
                checked={quizInfo.quizType === 2}
              ></Radio>
            </RadioGroup>
          </Box>
        </Box>
        <Box classes="mg mb-40">
          <Box width={220} direction="column" classes="mg mr-28">
            <Box style={{ marginBottom: "4px" }}>
              <Typography color="secondary" fontWeight={700}>
                전송 퀴즈 수
              </Typography>
            </Box>
            <Typography size={12} color="secondary">
              메일 1건에 전송할 퀴즈 수입니다.
            </Typography>
          </Box>
          <Box alignItems="center">{quizInfo.quizCount}건</Box>
        </Box>
        <Box classes="mg mb-28">
          <Box width={220} direction="column" classes="mg mr-28">
            <Box style={{ marginBottom: "4px" }}>
              <Typography color="secondary" fontWeight={700}>
                퀴즈 문제
              </Typography>
            </Box>
            <Typography size={12} color="secondary">
              퀴즈 문제 수는 ‘ 대상자별 발송 메일 수 X 전송 퀴즈 수' 이상이어야
              합니다.
            </Typography>
          </Box>

          <Box alignItems="center" direction="column" width={590}>
            <Box alignItems="center" justifyContent="flex-start" width={"100%"}>
              <Button
                classes="quiz__select__button"
                startIcon={
                  <Icons variant="common" label="plus" stroke={"#D1D6DB"} />
                }
                // color={quizFlag ? "secondary" : }
                disabled
                color="secondary"
                textColor="#D1D6DB"
                text="퀴즈 선택"
                onClick={() => setIsQuizModalOpen(true)}
              ></Button>
              <Box classes="mg ml-12">
                <Typography color={quizFlag ? "primary" : "textGrey"}>
                  추가된 대상
                </Typography>
              </Box>
              <Box classes="mg ml-4">
                <Typography
                  color={
                    !quizFlag
                      ? "textGrey"
                      : programInfo.sendMailCount &&
                        selectedQuizList.length <
                          programInfo.sendMailCount * quizInfo.quizCount
                      ? "red"
                      : "blue"
                  }
                >
                  {selectedQuizList.length}
                </Typography>
                <Box classes="mg mr-4"></Box>
                <Typography>
                  {quizFlag &&
                    `/ ${
                      (programInfo.sendMailCount ?? 0) *
                      (quizInfo.quizCount ?? 0)
                    }`}
                </Typography>
              </Box>
            </Box>
            <Table classes="mg mt-12">
              <colgroup>
                <col style={{ width: "44px" }} />
                <col style={{ width: "72px" }} />
                <col style={{ width: "142px" }} />
                <col style={{ width: "*" }} />
                <col style={{ width: "80px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Checkbox />
                  </th>
                  <th>구분</th>
                  <th>퀴즈 이름</th>
                  <th>카테고리</th>
                  <th>유형</th>
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              {selectedQuizList.length === 0 ? (
                <Box
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  width={"100%"}
                  height={154}
                >
                  <NoQuizImage />
                  <Typography>
                    '퀴즈 선택' 버튼을 클릭해 문제를 선택하세요.
                  </Typography>
                </Box>
              ) : (
                <Table>
                  <colgroup>
                    <col style={{ width: "44px" }} />
                    <col style={{ width: "72px" }} />
                    <col style={{ width: "142px" }} />
                    <col style={{ width: "*" }} />
                    <col style={{ width: "80px" }} />
                  </colgroup>
                  <tbody>
                    {selectedQuizList.map((q) => (
                      <tr>
                        <td></td>
                        <td>
                          {q.commonId ? (
                            <Tag
                              name={t("template.public")}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="blue"
                              radius={6}
                            />
                          ) : (
                            <Tag
                              name={t("template.private")}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="pink"
                              radius={6}
                            />
                          )}
                        </td>
                        <td>{q.quizName}</td>
                        <td>{q.categorys}</td>
                        <td>{q.quizType === 1 ? "O/X" : "5지 선다"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.trainingManagement")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {configId ? t("program.update") : t("menu.createTraining")}
          </Typography>
        </Box>
      </Box>
      <ScrollBox width={"100%"}>
        <Box alignItems="center" justifyContent="space-between">
          <Accordion>
            <AccordionItem
              title={t("program.basicInfo")}
              expandedProp={true}
              // type={"CLOSEINFO"}
              title2={programInfo.configName}
              classes="training__accordian"
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-28" />
              {infoBody}
            </AccordionItem>
          </Accordion>
        </Box>

        <Box
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
          classes="mg mt-2"
          // direction="column"
        >
          <Accordion>
            <AccordionItem
              title={t("dashboard.user")}
              classes="training__accordian"
              // type={"CLOSEINFO"}
              title2={
                userCheckboxGroup.selected.length > 0
                  ? userCheckboxGroup.selected.length +
                    t("program.programPeopleUnit")
                  : ""
              }
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-12" />
              {userBody}
            </AccordionItem>
          </Accordion>
        </Box>

        <Box
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
          classes="mg mt-2"
          // direction="column"
        >
          <Accordion>
            <AccordionItem
              title={t("menu.template")}
              classes="training__accordian"
              // type={"CLOSEINFO"}
              // title2={programInfo.configName}
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-20" />
              {isStarted ? templateBody() : templateSelectBody}
            </AccordionItem>
          </Accordion>
        </Box>
        <Box
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
          classes="mg mt-2"
          // direction="column"
        >
          <Accordion>
            <AccordionItem
              title={"퀴즈 코스 연계"}
              classes="training__accordian"
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-28" />
              {isStarted ? quizBody() : quizSelectBody}
            </AccordionItem>
          </Accordion>
        </Box>
        <Box justifyContent="flex-end" classes="mg mt-12">
          <Button
            color="secondary"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/manageTrain/list`)
            }
          >
            {t("common.button.cancel")}
          </Button>

          {configId ? (
            <Button classes="mg ml-8" onClick={onSubmit}>
              {t("common.button.update")}
            </Button>
          ) : (
            <Button
              classes="mg ml-8"
              onClick={onSubmit}
              disabled={
                !programInfo.configName ||
                !programInfo.sendMailCount ||
                programInfo?.sendMailCount < 1 ||
                programInfo?.sendMailCount > 100 ||
                userCheckboxGroup.selected.length === 0 ||
                (checkboxScenarioGroup.selected.length === 0 &&
                  templates.length === 0)
              }
            >
              {t("common.button.create")}
            </Button>
          )}
        </Box>
      </ScrollBox>
      {isQuizModalOpen && (
        <QuizModal
          isOpen={isQuizModalOpen}
          onClose={() => {
            setIsQuizModalOpen(false);
          }}
          selectedQuizList={selectedQuizList}
          setSelectedQuizList={setSelectedQuizList}
        />
      )}

      {/* </Modal> */}
    </Box>
  );
};

export default WriteTraining;
