import "./index.scss";
import {
  Box,
  Button,
  Card,
  CardList,
  Checkbox,
  DropdownItem,
  Header,
  IconButton,
  Icons,
  ScrollBox,
  SettingButton,
  Table,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import NoTemplateImage from "../../../shared/image/NoTemplateImage";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario, ScenarioListReqDto } from "../../../types/Scenario";
import SettingChildren from "../../../component/SettingChildren";

import ScenarioDetailModal from "./ScenarioDetailModal";
import HTMLThumbnail from "../../../component/HTMLThumbnail";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import NoInfo from "../../../component/NoInfo";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { Category } from "../../../types/Info";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import { useTranslation } from "react-i18next";

export const ManageScenario: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<Scenario[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<number | null>(
    null
  );
  const [detailModalType, setDetailModalType] = useState<
    "DETAIL" | "USED" | "NONE"
  >("NONE");

  const [isListView, setIsListView] = useState<boolean>(true);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [hasData, setHasData] = useState(false);

  const { categoryState, getCategories } = useCategoryStore();
  const { mailTemplateState, getMailTemplateList, deleteMailTemplate } =
    useMailTemplateStore();
  const { syncMasterToCustomer } = useCustomerStore();
  const localCustomerCode = getLocalCustomerCode();

  const toast = useToast();

  const [scenarioType, setScenarioType] = useState({
    id: 0,
    text: t("common.auth.all"),
  });
  const [nameInput, setNameInput] = useState<string | null>(null);
  const [searchFilter, setSearchFilter] = useState<ScenarioListReqDto>({
    sendName: "",
    categorys: [],
    siteId: true,
    imageId: true,
    documentId: true,
    orderId: "templateName",
    orderType: "desc",
    isCommon: null,
  });

  const checkboxGroup = useCheckboxGroup();

  const filterCateCheckbox = useCheckboxGroup();
  const filterContentsCheckbox = useCheckboxGroup();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<
    "USED" | "NOT_USED" | null
  >(null);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>([]);

  const [isUsedModal, setIsUsedModal] = useState<number>(0);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState<boolean>(false);

  const [scenarioTypes, setScenarioTypes] = useState([
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("template.public") },
    { id: 2, text: t("template.private") },
  ]);

  const onSync = async () => {
    const resp: any = await syncMasterToCustomer();

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("sync.msg.successMsg"), "success");
      getList();
    } else {
      toast.toastMsg(nanoid(), t("sync.msg.failMsg"), "error");
    }

    setIsSyncModalOpen(false);
  };

  const onDelete = async () => {
    const resp: any = await deleteMailTemplate({
      request: {
        templateIds:
          selectedTemplates.length > 0
            ? selectedTemplates
            : (checkboxGroup.selected as number[]),
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNoDeletedTemplateMsg"),
        "error"
      );
    }

    checkboxGroup.handleAllClick([]);
    setSelectedTemplates([]);
    setIsDeleteModalOpen(null);
  };

  const getList = async () => {
    const resp: any = await getMailTemplateList(searchFilter);
    if (
      resp.payload &&
      resp.payload.code === "SUCCESS" &&
      resp.payload.data.total > 0
    ) {
      setHasData(true);
    }
  };

  useEffect(() => {
    filterContentsCheckbox.handleAllClick(["link", "image", "attach"]);
    getList();
  }, []);

  useEffect(() => {
    getMailTemplateList(searchFilter);
    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
  }, [searchFilter]);

  const onSetFilter = () => {
    const filters = {
      ...searchFilter,
      categorys:
        filterCateCheckbox?.selected?.length === categoryList?.length
          ? []
          : (filterCateCheckbox.selected as string[]),
      siteId: filterContentsCheckbox.isSelected("link"),
      imageId: filterContentsCheckbox.isSelected("image"),
      documentId: filterContentsCheckbox.isSelected("attach"),
      isCommon:
        scenarioType.id === 0 ? null : scenarioType.id === 1 ? true : false,
      templateName: nameInput !== null && nameInput !== "" ? nameInput : null,
    };
    setSearchFilter(filters);
    if (filterCateCheckbox.selected?.length === 0) {
      filterCateCheckbox.handleAllClick(
        categoryList?.map((c) => c.categoryName) ?? []
      );
    }
    if (filterContentsCheckbox.selected.length === 0) {
      filterContentsCheckbox.handleAllClick(["link", "image", "attach"]);
    }
  };

  useEffect(() => {
    onSetFilter();
  }, [scenarioType]);

  useEffect(() => {
    setTemplates(mailTemplateState.mailTemplateList);
    getCategories();
  }, [mailTemplateState.mailTemplateList]);

  useEffect(() => {
    setCategoryList(categoryState.categoryList);
    if (searchFilter.categorys?.length === 0) {
      filterCateCheckbox.handleAllClick(
        categoryState.categoryList?.map((item: Category) => item.categoryName)
      );
    }
  }, [categoryState.categoryList]);

  useEffect(() => {
    setScenarioTypes([
      { id: 0, text: t("common.auth.all") },
      { id: 1, text: t("template.public") },
      { id: 2, text: t("template.private") },
    ]);
    setScenarioType((prev) => ({
      ...prev,
      text:
        prev.id === 0
          ? t("common.auth.all")
          : scenarioTypes.filter((s) => s.id === prev.id)[0].text,
    }));
  }, [i18n.language]);

  const resetFilter = () => {
    filterCateCheckbox.handleAllClick(categoryList?.map((c) => c.categoryName));
    filterContentsCheckbox.handleAllClick(["link", "image", "attach"]);
    setNameInput(null);

    getMailTemplateList({
      sendName: "",
      categorys: [],
      siteId: true,
      imageId: true,
      documentId: true,
      orderId: "templateName",
      orderType: "desc",
      isCommon: null,
      templateName: null,
    });
    setSearchFilter({
      sendName: "",
      categorys: [],
      siteId: true,
      imageId: true,
      documentId: true,
      orderId: "templateName",
      orderType: "desc",
      isCommon: null,
      templateName: null,
    });
    setScenarioType({
      id: 0,
      text: t("common.auth.all"),
    });
  };

  const cateFilter = () => {
    const selected = [];
    if (searchFilter.categorys) {
      selected.push(...searchFilter.categorys);
      if (selected.length === categoryList?.length || selected.length === 0) {
        return t("common.auth.all");
      } else if (selected.length === 1) {
        return selected[0].length > 12
          ? selected[0].substring(0, 12) + "..."
          : selected[0];
      } else {
        return `${
          selected[0].length > 12
            ? selected[0].substring(0, 12) + "..."
            : selected[0]
        } ${t("common.msg.moreCnt", {
          length: selected.length - 1,
        })}`;
      }
    }
  };

  const contentsFilter = () => {
    const selected = [];
    if (searchFilter.siteId) {
      selected.push(t("link.link"));
    }
    if (searchFilter.imageId) {
      selected.push(t("image.image"));
    }
    if (searchFilter.documentId) {
      selected.push(t("attach.attach"));
    }

    if (selected.length === 0 || selected.length === 3) {
      return t("common.auth.all");
    } else if (selected.length === 1) {
      return selected[0];
    } else if (selected.length > 1) {
      return `${selected[0]} ${t("common.msg.moreCnt", {
        length: selected.length - 1,
      })}`;
    }
  };

  const [buttonClick, setButtonClick] = useState<number>(0);

  const filter = (
    <Box classes="mg mt-12">
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={scenarioType.id === 0 ? "tertiary" : "tertiary-click"}
            classes="mg mr-8"
          >
            {t("template.type")} · {scenarioType.text}
          </Button>
        }
        listPosition="right"
      >
        {scenarioTypes.map((item) => (
          <DropdownItem
            isChecked={item.id === scenarioType.id}
            onClick={() => setScenarioType(item)}
            key={item.id}
          >
            {item.id === scenarioType.id ? (
              <Icons variant="common" label="check" stroke="#3182f6" />
            ) : (
              <Box width={28} />
            )}
            {item.text}
          </DropdownItem>
        ))}
      </SettingButton>

      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={nameInput ? "tertiary-click" : "tertiary"}
            classes="mg mr-8"
          >
            {/* <Typography maxWidth={220} ellipsis> */}
            {t("template.templateSimple")} ·{" "}
            {searchFilter.templateName
              ? searchFilter.templateName.length > 15
                ? searchFilter.templateName.slice(0, 15) + "..."
                : searchFilter.templateName
              : t("common.auth.all")}
            {/* </Typography> */}
          </Button>
        }
        listPosition={"right"}
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          width={280}
          height={40}
        >
          <TextField
            borderNone
            placeholder={t("template.msg.namePlaceholder")}
            hasText
            text={nameInput ?? ""}
            onChange={(e) => setNameInput(e.target.value)}
            onKeyPress={(e: any) => e.key === "Enter" && onSetFilter()}
          />
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setNameInput(searchFilter?.templateName ?? "");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                // setSearchFilter((prev) => {
                //   return {
                //     ...prev,
                //     templateName: null,
                //   };
                // });
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={onSetFilter}
            />
          </Box>
        </Box>
      </SettingButton>

      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              searchFilter.categorys?.length === 0 ||
              searchFilter.categorys?.length === categoryList?.length
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("category.categorySimple")} · {cateFilter()}
          </Button>
        }
        listPosition={"right"}
      >
        {categoryList?.length === 0 ? (
          <Box
            alignItems="center"
            classes="setting-button-list-body-item"
            height={40}
          >
            {t("category.msg.noCategoryMsg")}
          </Box>
        ) : (
          <>
            <Box
              alignItems="center"
              classes="setting-button-list-body-item"
              height={40}
            >
              <Checkbox
                checked={
                  filterCateCheckbox.selected?.length === categoryList?.length
                }
                onChange={() =>
                  filterCateCheckbox.selected?.length === categoryList.length
                    ? filterCateCheckbox.handleAllClick([])
                    : filterCateCheckbox.handleAllClick(
                        categoryList.map((c) => c.categoryName)
                      )
                }
              />
              <Typography classes="mg ml-8">{t("common.auth.all")}</Typography>
            </Box>
            <ScrollBox maxHeight={300}>
              {categoryList?.map((item) => (
                <Box
                  alignItems="center"
                  classes="setting-button-list-body-item"
                  height={40}
                  key={item.categoryName}
                >
                  <Checkbox
                    onChange={() =>
                      filterCateCheckbox.handleClick(item.categoryName)
                    }
                    checked={filterCateCheckbox.isSelected(item.categoryName)}
                  />
                  <Typography
                    classes="mg ml-8"
                    ellipsis
                    maxWidth={298}
                    lineHeight="normal"
                  >
                    {item.categoryName}
                  </Typography>
                </Box>
              ))}
            </ScrollBox>
            <Box
              alignItems="center"
              classes="setting-button-list-footer"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => {
                    filterCateCheckbox.handleAllClick(
                      searchFilter.categorys &&
                        searchFilter.categorys.length > 0
                        ? searchFilter.categorys
                        : categoryList.map((c) => c.categoryName)
                    );
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <Button
                  text={t("common.button.apply")}
                  classes={"mg ml-8"}
                  color="primary"
                  onClick={onSetFilter}
                  disabled={filterCateCheckbox.selected.length === 0}
                />
              </Box>
            </Box>
          </>
        )}
      </SettingButton>

      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterContentsCheckbox.selected.length === 3
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("template.contents")} · {contentsFilter()}
          </Button>
        }
        listPosition={"right"}
      >
        <Box classes="setting-button-list-body" height="fit-content">
          <Box
            alignItems="center"
            classes="setting-button-list-body-item"
            height={40}
          >
            <Checkbox
              checked={filterContentsCheckbox.selected.length === 3}
              onChange={() =>
                filterContentsCheckbox.selected.length === 3
                  ? filterContentsCheckbox.handleAllClick([])
                  : filterContentsCheckbox.handleAllClick([
                      "link",
                      "image",
                      "attach",
                    ])
              }
            />
            <Typography classes="mg ml-8">{t("common.auth.all")}</Typography>
          </Box>
          <Box
            alignItems="center"
            classes="setting-button-list-body-item"
            height={40}
          >
            <Checkbox
              onChange={() => filterContentsCheckbox.handleClick("link")}
              checked={filterContentsCheckbox.isSelected("link")}
            />
            <Typography classes="mg ml-8">{t("link.link")}</Typography>
          </Box>
          <Box
            alignItems="center"
            classes="setting-button-list-body-item"
            height={40}
          >
            <Checkbox
              onChange={() => filterContentsCheckbox.handleClick("image")}
              checked={filterContentsCheckbox.isSelected("image")}
            />
            <Typography classes="mg ml-8">{t("image.image")}</Typography>
          </Box>
          <Box
            alignItems="center"
            classes="setting-button-list-body-item"
            height={40}
          >
            <Checkbox
              onChange={() => filterContentsCheckbox.handleClick("attach")}
              checked={filterContentsCheckbox.isSelected("attach")}
            />
            <Typography classes="mg ml-8">{t("attach.attach")}</Typography>
          </Box>
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                let selected = [];
                if (searchFilter.documentId) selected.push("attach");
                if (searchFilter.siteId) selected.push("link");
                if (searchFilter.imageId) selected.push("image");
                filterContentsCheckbox.handleAllClick(
                  selected && selected.length > 0
                    ? selected
                    : ["link", "image", "attach"]
                );
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={onSetFilter}
              disabled={filterContentsCheckbox.selected.length === 0}
            />
          </Box>
        </Box>
      </SettingButton>
    </Box>
  );

  const header = (
    <Box classes="mg mt-8" justifyContent="space-between" width={"100%"}>
      <Box alignItems="center">
        <Typography fontWeight={700} size={16}>
          {t("template.template")}
        </Typography>
        <Typography classes="mg ml-8" size={16} color="green" fontWeight={700}>
          {mailTemplateState.total}
        </Typography>
      </Box>
      <Box>
        {checkboxGroup.selected.length > 0 && (
          <Box>
            {checkboxGroup.selected.length === 1 && (
              <Button
                color={"text-noline"}
                height={37}
                startIcon={
                  <Icons
                    variant="mindsat"
                    label="icon_edit3"
                    classes="mg mr-4"
                  />
                }
                onClick={() => {
                  const item: Scenario = templates.filter(
                    (t) => t.templateId === checkboxGroup.selected[0]
                  )[0];

                  if (item.commonId) {
                    toast.toastMsg(
                      nanoid(),
                      t("common.msg.cannotDeletePublicContentsMsg"),
                      "error"
                    );
                    return;
                  }

                  if (item.useConfig && parseInt(item.useConfig) > 0) {
                    setIsUsedModal(item.templateId);
                  } else {
                    navigate(`/manageTrain/scenario/update/${item.templateId}`);
                  }
                }}
              >
                {t("common.button.update")}
              </Button>
            )}
            <Button
              color={"text-noline"}
              textColor="red"
              height={37}
              startIcon={
                <Icons variant="mindsat" label="icon_trash" classes="mg mr-4" />
              }
              onClick={() => {
                let commonFlag = false;
                let usedFlag = false;
                checkboxGroup.selected.map((c) => {
                  const item: Scenario[] = templates.filter(
                    (t) => t.templateId === c
                  );

                  item.forEach((i) => {
                    console.log(i.useConfig);
                    if (i.commonId) {
                      commonFlag = true;
                    }
                    if (i.useConfig && parseInt(i.useConfig) > 0) {
                      usedFlag = true;
                    }
                  });
                });
                if (commonFlag) {
                  toast.toastMsg(
                    nanoid(),
                    t("template.msg.cannotDeletePublicScenarioMsg"),
                    "error"
                  );
                } else if (usedFlag) {
                  setIsDeleteModalOpen("USED");
                } else {
                  setIsDeleteModalOpen("NOT_USED");
                }
              }}
            >
              {t("common.button.delete")}
            </Button>
          </Box>
        )}
        <Button
          startIcon={<Icons variant="common" label="filter" stroke="#252d38" />}
          color="transparent"
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
        >
          {t("common.list.filter")}
        </Button>
      </Box>
    </Box>
  );

  // 리스트뷰
  const [placeholders, setPlaceholders] = useState<number[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<number | null>(null);
  const resizeTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        window.clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = window.setTimeout(() => {
        updatePlaceholders();
      }, 200); // 디바운스 시간 설정
    };

    const updatePlaceholders = () => {
      const container = containerRef.current;
      if (!container) return;

      const items = container.getElementsByClassName("card__item");
      if (items.length === 0) return;

      const itemCount = items.length;
      const containerWidth = container.clientWidth;

      const itemWidth = items[0].clientWidth;
      // +
      // parseFloat(getComputedStyle(items[0] as HTMLElement).marginRight) * 2;

      const itemsPerRow = Math.floor(containerWidth / itemWidth);

      const placeholdersNeeded =
        itemCount % itemsPerRow === 0
          ? 0
          : itemsPerRow - (itemCount % itemsPerRow);
      setPlaceholders(new Array(placeholdersNeeded).fill(0));
    };

    if (!isListView) {
      // 초기 호출
      updatePlaceholders();

      // 리사이즈 이벤트 리스너 등록
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        if (resizeTimeoutRef.current) {
          window.clearTimeout(resizeTimeoutRef.current);
        }
      };
    }
  }, [isListView]);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [templates]);

  return (
    <Box classes="main manageScenarioCategory">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[t("menu.templateManagement"), t("menu.template")]}
          />
          <Typography
            fontWeight={700}
            size={24}
            lineHeight="32px"
            classes="mg mt-8"
          >
            {t("menu.template")}
          </Typography>
        </Box>
        <Box>
          {/* <ToolTip message={t("sync.buttonTooltip")}> */}
          {localCustomerCode && (
            <Button
              startIcon={<Icons variant="common" label="sync" />}
              color="secondary"
              onClick={() => setIsSyncModalOpen(true)}
              classes="mg mr-10"
            >
              {t("sync.update")}
            </Button>
          )}
          {/* </ToolTip> */}
          <Button
            startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
            color="secondary"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/manageTrain/scenario/write`)
            }
          >
            {t("template.insert")}
          </Button>
        </Box>
      </Box>
      <Box width="100%" height={"calc(100% - 60px)"}>
        {templates.length === 0 ? (
          !hasData ? (
            <Box
              width={"100%"}
              direction="column"
              justifyContent="center"
              alignItems="center"
              classes="mg mt-24"
            >
              <NoTemplateImage />
              <Typography color="secondary" classes="mg mt-24 mb-24" size={16}>
                {t("template.msg.noTemplateMsg")}
              </Typography>
              <Button
                startIcon={
                  <Icons variant="common" label="plus" stroke="#FFFFFF" />
                }
                color="primary"
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/manageTrain/scenario/write`
                  )
                }
              >
                {t("template.insert")}
              </Button>
            </Box>
          ) : (
            <Box direction="column" width="100%">
              {header}
              {isFilterOpen && filter}
              <Box
                direction="column"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <NoSearchResultImage width={200} />
                <Typography classes="mg mt-36" size={16} color="secondary">
                  {t("template.msg.noFilterMsg")}
                </Typography>
                <Button
                  classes="mg mt-16"
                  startIcon={
                    <Icons variant="common" label="refresh" stroke="white" />
                  }
                  onClick={resetFilter}
                >
                  {t("common.button.resetFilter")}
                </Button>
              </Box>
            </Box>
          )
        ) : (
          <Box direction="column">
            {header}
            {isFilterOpen && filter}
            {isListView ? (
              templates && templates.length > 0 ? (
                <Box
                  direction="column"
                  height={
                    isFilterOpen ? "calc(100% - 85px)" : "calc(100% - 40px)"
                  }
                >
                  <Table classes="default-table mg mt-12">
                    <colgroup>
                      <col style={{ width: "52px" }}></col>
                      <col style={{ width: "72px" }}></col>
                      <col style={{ width: "360px" }}></col>
                      <col style={{ width: "*" }}></col>
                      <col style={{ width: "88px" }}></col>
                      <col style={{ width: "88px" }}></col>
                      <col style={{ width: "88px" }}></col>
                      <col
                        style={{ width: `calc(46px + ${scrollbarWidth}px)` }}
                      ></col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <Checkbox
                            checked={
                              checkboxGroup.selected.length === templates.length
                            }
                            onChange={() =>
                              checkboxGroup.handleAllClick(
                                checkboxGroup.selected.length ===
                                  templates.length
                                  ? []
                                  : templates.map((t) => t.templateId)
                              )
                            }
                          />
                        </th>
                        <th>{t("template.type")}</th>
                        <th>{t("template.name")}</th>
                        <th>{t("category.categorySimple")}</th>
                        <th>{t("link.linkSimple")}</th>
                        <th>{t("image.imageSimple")}</th>
                        <th>{t("attach.attachSimple")}</th>
                        <th>
                          {/* <IconButton
                            transparent
                            onClick={() => setIsListView(false)}
                          >
                            <Icons variant="file" label="list" />
                          </IconButton> */}
                        </th>
                      </tr>
                    </thead>
                  </Table>
                  <Box classes="table-scroll">
                    <Table>
                      <colgroup>
                        <col style={{ width: "52px" }}></col>
                        <col style={{ width: "72px" }}></col>
                        <col style={{ width: "360px" }}></col>
                        <col style={{ width: "*" }}></col>
                        <col style={{ width: "88px" }}></col>
                        <col style={{ width: "88px" }}></col>
                        <col style={{ width: "88px" }}></col>
                        <col style={{ width: "46px" }}></col>
                      </colgroup>
                      <tbody>
                        {templates.map((template) => (
                          <tr
                            key={template.templateId}
                            onClick={() => (
                              setIsDetailModalOpen(template.templateId),
                              setDetailModalType("DETAIL")
                            )}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <td>
                              <Checkbox
                                onChange={() =>
                                  checkboxGroup.handleClick(template.templateId)
                                }
                                checked={checkboxGroup.isSelected(
                                  template.templateId
                                )}
                              />
                            </td>
                            <td>
                              {localCustomerCode ? (
                                template.commonId ? (
                                  <Tag
                                    name={t("template.public")}
                                    size={i18n.language === "ko" ? "ms" : "sm"}
                                    color="blue"
                                    radius={6}
                                  />
                                ) : (
                                  <Tag
                                    name={t("template.private")}
                                    size={i18n.language === "ko" ? "ms" : "sm"}
                                    color="pink"
                                    radius={6}
                                  />
                                )
                              ) : (
                                <Tag
                                  name={t("template.public")}
                                  size={i18n.language === "ko" ? "ms" : "sm"}
                                  color="blue"
                                  radius={6}
                                />
                              )}
                            </td>
                            <td>{template.templateName}</td>
                            <td>
                              {template.categorys && (
                                <CategoryTagList
                                  categorys={template.categorys}
                                />
                              )}
                            </td>
                            <td>
                              {template.siteId ? (
                                <Icons
                                  variant="file"
                                  label="copyLink"
                                  width={16}
                                />
                              ) : (
                                <Icons
                                  variant="common"
                                  label="cancel"
                                  stroke="#B5BBC2"
                                />
                              )}
                            </td>
                            <td>
                              {template.imageId ? (
                                <Icons
                                  variant="mindsat"
                                  label="icon_gallery"
                                  width={16}
                                />
                              ) : (
                                <Icons
                                  variant="common"
                                  label="cancel"
                                  stroke="#B5BBC2"
                                />
                              )}
                            </td>
                            <td>
                              {template.documentId ? (
                                <Icons
                                  variant="mindsat"
                                  label="icon_clip"
                                  width={16}
                                />
                              ) : (
                                <Icons
                                  variant="common"
                                  label="cancel"
                                  stroke="#B5BBC2"
                                />
                              )}
                            </td>
                            <td>
                              {template.commonId ? (
                                <SettingButton
                                  hovered={true}
                                  children={
                                    <SettingChildren
                                      detailEvent={() => {
                                        setIsDetailModalOpen(
                                          template.templateId
                                        );
                                        setDetailModalType("DETAIL");
                                      }}
                                    />
                                  }
                                />
                              ) : (
                                <SettingButton
                                  hovered={true}
                                  children={
                                    <SettingChildren
                                      detailEvent={() => {
                                        setIsDetailModalOpen(
                                          template.templateId
                                        );
                                        setDetailModalType("DETAIL");
                                      }}
                                      editEvent={() => {
                                        if (
                                          template.useConfig &&
                                          parseInt(template.useConfig) > 0
                                        ) {
                                          setIsUsedModal(template.templateId);
                                        } else {
                                          navigate(
                                            `/manageTrain/scenario/update/${template.templateId}`
                                          );
                                        }
                                      }}
                                      deleteEvent={() => {
                                        setSelectedTemplates([
                                          template.templateId,
                                        ]);
                                        if (
                                          template.useConfig &&
                                          parseInt(template.useConfig) > 0
                                        ) {
                                          setIsDeleteModalOpen("USED");
                                        } else {
                                          setIsDeleteModalOpen("NOT_USED");
                                        }
                                      }}
                                    />
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Box>
                </Box>
              ) : (
                <NoInfo />
              )
            ) : (
              <Box direction="column" width="100%">
                <Header
                  classes="card__header mg mt-12"
                  left={
                    <Box alignItems="center">
                      <Checkbox
                        checked={
                          checkboxGroup.selected.length === templates.length
                        }
                        onChange={() =>
                          checkboxGroup.handleAllClick(
                            checkboxGroup.selected.length === templates.length
                              ? []
                              : templates.map((t) => t.templateId)
                          )
                        }
                      />
                      <Box classes="mg ml-20">
                        <Typography size={13} color="secondary">
                          {t("template.name")}
                        </Typography>
                      </Box>
                    </Box>
                  }
                  right={
                    <IconButton transparent onClick={() => setIsListView(true)}>
                      <Icons variant="file" label="mediumCard" />
                    </IconButton>
                  }
                />

                <Box width={"100%"} divRef={containerRef}>
                  <CardList classes="mg mt-12 scenario__card__list">
                    {templates.map((template) => (
                      <Box
                        classes="card__item"
                        key={template.templateId}
                        onMouseEnter={() => setIsHovered(template.templateId)}
                        onMouseLeave={() => setIsHovered(null)}
                      >
                        <Box
                          onClick={() => (
                            setIsDetailModalOpen(template.templateId),
                            setDetailModalType("DETAIL")
                          )}
                          direction="column"
                          width="100%"
                        >
                          <Card>
                            {isHovered === template.templateId ||
                            checkboxGroup.isSelected(template.templateId) ? (
                              <Checkbox
                                classes="mg mt-8"
                                onChange={() =>
                                  checkboxGroup.handleClick(template.templateId)
                                }
                                checked={checkboxGroup.isSelected(
                                  template.templateId
                                )}
                              />
                            ) : (
                              <Box width={23}></Box>
                            )}

                            <Box classes="thumbnail__container">
                              <HTMLThumbnail
                                width={"90%"}
                                height={270}
                                mail={template}
                              />
                            </Box>
                            {isHovered === template.templateId ? (
                              template.commonId ? (
                                <SettingButton
                                  classes="mg mr-8 mt-8"
                                  hovered={true}
                                  children={
                                    <SettingChildren
                                      detailEvent={() => {
                                        setIsDetailModalOpen(
                                          template.templateId
                                        );
                                        setDetailModalType("DETAIL");
                                      }}
                                    />
                                  }
                                />
                              ) : (
                                <SettingButton
                                  hovered={true}
                                  children={
                                    <SettingChildren
                                      detailEvent={() => {
                                        setIsDetailModalOpen(
                                          template.templateId
                                        );
                                        setDetailModalType("DETAIL");
                                      }}
                                      editEvent={() => {
                                        if (
                                          template.useConfig &&
                                          parseInt(template.useConfig) > 0
                                        ) {
                                          setIsUsedModal(template.templateId);
                                        } else {
                                          navigate(
                                            `/manageTrain/scenario/update/${template.templateId}`
                                          );
                                        }
                                      }}
                                      deleteEvent={() => {
                                        setSelectedTemplates([
                                          template.templateId,
                                        ]);
                                        if (
                                          template.useConfig &&
                                          parseInt(template.useConfig) > 0
                                        ) {
                                          setIsDeleteModalOpen("USED");
                                        } else {
                                          setIsDeleteModalOpen("NOT_USED");
                                        }
                                      }}
                                    />
                                  }
                                />
                              )
                            ) : (
                              <Box width={30}></Box>
                            )}
                          </Card>
                          <Box
                            alignItems="center"
                            classes="mg mt-8"
                            width={"100%"}
                          >
                            {template.commonId ? (
                              <Tag
                                name={t("template.public")}
                                size={i18n.language === "ko" ? "ms" : "sm"}
                                color="blue"
                                radius={6}
                              />
                            ) : (
                              <Tag
                                name={t("template.private")}
                                size={i18n.language === "ko" ? "ms" : "sm"}
                                color="pink"
                                radius={6}
                              />
                            )}
                            <ToolTip
                              message={template.templateName}
                              maxWidth={"calc(100% - 80px)"}
                            >
                              <Typography
                                classes="mg ml-8"
                                ellipsis
                                lineHeight="normal"
                              >
                                {template.templateName}
                              </Typography>
                            </ToolTip>
                          </Box>
                          <Box classes="mg mt-8">
                            {template.categorys && (
                              <CategoryTagList categorys={template.categorys} />
                            )}
                          </Box>
                          <Box classes="mg mt-8">
                            {template.siteId ? (
                              <Icons
                                variant="file"
                                label="copyLink"
                                width={16}
                                classes="mg ml-8"
                              />
                            ) : null}

                            {template.imageId ? (
                              <Icons
                                variant="mindsat"
                                label="icon_gallery"
                                width={16}
                                classes="mg ml-8"
                              />
                            ) : null}

                            {template.documentId ? (
                              <Icons
                                variant="mindsat"
                                label="icon_clip"
                                width={16}
                                classes="mg ml-8"
                              />
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    {placeholders.map((_, index) => (
                      <div
                        key={`placeholder-${index}`}
                        className="card__item__placeholder"
                      />
                    ))}
                  </CardList>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {/* detail modal */}
      {isDetailModalOpen && (
        <ScenarioDetailModal
          isOpen={true}
          templateId={isDetailModalOpen}
          onClose={() => (
            setIsDetailModalOpen(null), setDetailModalType("NONE")
          )}
          modalType={detailModalType}
          setModalType={setDetailModalType}
        />
      )}

      <CommonModal
        open={isDeleteModalOpen === null ? false : true}
        onClose={() => setIsDeleteModalOpen(null)}
        title={t("template.delete")}
        afterSubmitButton={onDelete}
        subMessage={
          isDeleteModalOpen === "USED"
            ? t("template.msg.templateDeleteUsedTemplateMsg")
            : t("common.msg.deleteConfirmMsg")
        }
        type="delete"
      />

      <CommonModal
        open={isUsedModal > 0}
        onClose={() => setIsUsedModal(0)}
        title={t("template.updateSimple")}
        afterSubmitButton={() =>
          navigate(`/manageTrain/scenario/update/${isUsedModal}`)
        }
        footer={
          <Box justifyContent="flex-end">
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => setIsUsedModal(0)}
            />

            <Button
              text={t("common.button.update")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                navigate(`/manageTrain/scenario/update/${isUsedModal}`);
              }}
            />
          </Box>
        }
        body={
          <Box direction="column" style={{ lineHeight: "1.5" }}>
            <Typography>
              {t("template.msg.templateUpdateUsedTemplateMsg")}
            </Typography>
          </Box>
        }
      />

      <CommonModal
        open={isSyncModalOpen}
        onClose={() => setIsSyncModalOpen(false)}
        afterSubmitButton={onSync}
        title={t("sync.update")}
        body={
          <Box direction="column">
            <Typography>{t("template.msg.syncScenarioMsg")}</Typography>
          </Box>
        }
        footer={
          <Box justifyContent="flex-end">
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => setIsSyncModalOpen(false)}
            />

            <Button
              text={t("sync.updateSimple")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                onSync();
                setIsSyncModalOpen(false);
              }}
            />
          </Box>
        }
      />
    </Box>
  );
};

export default ManageScenario;
