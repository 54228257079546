import "./index.scss";
import {
  Box,
  Button,
  IconButton,
  Icons,
  Radio,
  RadioGroup,
  Tag,
  Typography,
} from "fasoo-ui-component-next";
import { useEffect, useState } from "react";
import useQuizStore from "../../redux/dispatcher/useQuizStore";
import { QuizContentDTO, QuizSolvingReqDTO } from "../../types/Quiz";
import { useSearchParams } from "react-router-dom";
import MindsatIcon from "../../shared/image/logo/mindsat";
import DeniedImage from "../../shared/image/DeniedImage";

const QuizSolve: React.FC = () => {
  const { quizState, isValidQuizAccess, completeQuiz } = useQuizStore();
  const mindsatLogo = require("../../shared/image/logo/img_mindsat_bi.png");
  const [searchParams] = useSearchParams();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [quizList, setQuizList] = useState<QuizContentDTO[]>();
  const [step, setStep] = useState<number>(-1);

  const [userChoice, setUserChoice] = useState<number>(-1);

  useEffect(() => {
    onAccess();
  }, []);

  const onAccess = async () => {
    const sendHistoryId = searchParams.get("shistory_id");
    const cpCode = searchParams.get("cp_code");
    const token = searchParams.get("token");
    const type = searchParams.get("quiz_type");

    if (sendHistoryId && cpCode && token && type) {
      const params: QuizSolvingReqDTO = {
        sendHistoryId: parseInt(sendHistoryId),
        cpCode,
        token,
        type: parseInt(type),
      };
      const resp: any = await isValidQuizAccess(params);

      if (resp.payload && resp.payload.code === "SUCCESS") {
        setIsValid(true);
        setQuizList(resp.payload.data.quizList);
        setStep(0);
      } else if (resp.payload && resp.payload.code === "DATA_USED") {
        setIsValid(true);
        setQuizMode("ENTERED_COMPLETED");
      } else {
        // error
      }
    }
  };

  const [quizMode, setQuizMode] = useState<
    "CORRECT" | "WRONG" | "ENTERED_COMPLETED" | "COMPLETED" | "NONE"
  >("NONE");

  const onCheckAnswer = () => {
    if (quizList && userChoice === quizList[step].quizAnswer) {
      setQuizMode("CORRECT");
    } else {
      setQuizMode("WRONG");
    }
  };

  const onComplete = async () => {
    const sendHistoryId = searchParams.get("shistory_id");
    const cpCode = searchParams.get("cp_code");
    const token = searchParams.get("token");
    const type = searchParams.get("quiz_type");

    if (sendHistoryId && cpCode && token && type) {
      const params: QuizSolvingReqDTO = {
        sendHistoryId: parseInt(sendHistoryId),
        cpCode,
        token,
        type: parseInt(type),
      };
      const resp: any = await completeQuiz(params);

      if (resp.payload && resp.payload.code === "SUCCESS") {
        setQuizMode("COMPLETED");
      }
    }
  };

  return (
    <>
      {isValid ? (
        <Box
          classes="solve__quiz__main"
          width="100vw"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          {quizMode === "ENTERED_COMPLETED" ? ( // 이미 완료된 퀴즈
            <Box
              width={720}
              classes="solve__quiz__container"
              direction="column"
              // alignItems="center"
              justifyContent="center"
            >
              <Box height={71}>
                <MindsatIcon width="80px" />
              </Box>
              <Box
                alignItems="center"
                justifyContent="center"
                direction="column"
                classes="mg mt-28 mb-28"
              >
                <Box height={80}>
                  <Icons
                    variant="mindsat"
                    label="icon_check_circle_fill"
                    width={80}
                    stroke="#3182F6"
                  />
                </Box>
                <Box classes="mg mt-32 mb-28">
                  <Typography fontWeight={700} size={24}>
                    이미 완료한 퀴즈 코스입니다.
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            // 퀴즈 풀이
            <>
              {quizMode === "COMPLETED" ? (
                <Box
                  width={720}
                  classes="solve__quiz__container"
                  direction="column"
                  // alignItems="center"
                  justifyContent="center"
                >
                  <Box height={71}>
                    <MindsatIcon width="80px" />
                  </Box>
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    classes="mg mt-28 mb-28"
                  >
                    <Box height={80}>
                      <Icons
                        variant="mindsat"
                        label="icon_check_circle_fill"
                        width={80}
                        stroke="#3182F6"
                      />
                    </Box>
                    <Box classes="mg mt-32 mb-28">
                      <Typography fontWeight={700} size={24}>
                        퀴즈를 모두 풀었습니다!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box width={720} classes="solve__quiz__container">
                  {quizList && (
                    <Box direction="column">
                      <MindsatIcon width="80px" />
                      <Box alignItems="center" classes="mg mb-20">
                        <Box>
                          <Typography fontWeight={700} size={14}>
                            {quizList[step].quizName}
                          </Typography>
                        </Box>
                        <Box classes="mg ml-8">
                          <Typography color="secondary" fontWeight={700}>{`(${
                            step + 1
                          }/${quizList.length})`}</Typography>
                        </Box>
                      </Box>
                      <Box classes="mg mb-28" alignItems="center">
                        <Typography fontWeight={700} size={16}>
                          {`${step + 1}. ${quizList[step].quizBody}`}
                        </Typography>
                        {quizMode === "CORRECT" ? (
                          <Tag
                            name={"정답"}
                            size="sm"
                            classes="mg ml-8 quiz__solve__tag"
                            color="green"
                          />
                        ) : quizMode === "WRONG" ? (
                          <Tag
                            name={"오답"}
                            size="sm"
                            classes="mg ml-8"
                            color="red"
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box
                        style={{
                          width: "640px",
                        }}
                      >
                        {quizList[step].quizType === 1 ? (
                          <RadioGroup
                            flex={true}
                            horizontal={true}
                            onChange={(e) =>
                              setUserChoice(parseInt(e.target.value))
                            }
                          >
                            <Box
                              direction="column"
                              height={56}
                              justifyContent="space-between"
                            >
                              <Radio
                                label="O"
                                value="0"
                                classes={
                                  userChoice === 0
                                    ? quizMode === "CORRECT"
                                      ? "correct__answer"
                                      : quizMode === "WRONG"
                                      ? "wrong__answer"
                                      : ""
                                    : quizMode === "WRONG"
                                    ? "correct__answer"
                                    : ""
                                }
                                checked={userChoice === 0}
                              ></Radio>
                              <Radio
                                label="X"
                                value="1"
                                classes={`quiz__solve__OX 
                                ${
                                  userChoice === 1
                                    ? quizMode === "CORRECT"
                                      ? "correct__answer"
                                      : quizMode === "WRONG"
                                      ? "wrong__answer"
                                      : ""
                                    : quizMode === "WRONG"
                                    ? "correct__answer"
                                    : ""
                                }
                                  `}
                                checked={userChoice === 1}
                              ></Radio>
                            </Box>
                          </RadioGroup>
                        ) : (
                          <Box direction="column">
                            {quizList[step].quizChoices &&
                              quizList[step].quizChoices?.map((c, index) => (
                                <Box
                                  height={20}
                                  classes={
                                    quizList[step].quizChoices &&
                                    index !==
                                      (quizList[step].quizChoices
                                        ?.length as number) -
                                        1
                                      ? `mg mb-16`
                                      : ``
                                  }
                                >
                                  <Radio
                                    label={
                                      c.length > 45
                                        ? c.substring(0, 45) + "..."
                                        : c
                                    }
                                    value={index.toString()}
                                    disabled={
                                      userChoice !== index &&
                                      (quizMode === "CORRECT" ||
                                        quizMode === "WRONG")
                                    }
                                    checked={
                                      userChoice === index ||
                                      (["CORRECT", "WRONG"].includes(
                                        quizMode
                                      ) &&
                                        quizList[step]?.quizAnswer === index)
                                    }
                                    onChange={(e) => {
                                      setUserChoice(parseInt(e.target.value));
                                    }}
                                    classes={
                                      quizMode === "CORRECT"
                                        ? quizList[step].quizAnswer === index
                                          ? "correct__answer"
                                          : ""
                                        : quizMode === "WRONG"
                                        ? quizList[step].quizAnswer === index
                                          ? "correct__answer"
                                          : index === userChoice
                                          ? "wrong__answer"
                                          : ""
                                        : ""
                                    }
                                  />
                                </Box>
                              ))}
                          </Box>
                        )}
                      </Box>
                      {(quizMode === "CORRECT" || quizMode === "WRONG") && (
                        <Box
                          classes="quiz__comment__container"
                          direction="column"
                        >
                          <Box classes="mg  mb-20">
                            정답:{" "}
                            {quizList[step].quizType === 1
                              ? quizList[step].quizAnswer === 0
                                ? "O"
                                : "X"
                              : (quizList[step]?.quizAnswer as number) + 1}
                          </Box>
                          <Box classes="quiz__solve__comments__container">
                            {quizList[step].quizComments}
                          </Box>
                        </Box>
                      )}
                      <Box
                        classes="mg mt-24 pd pt-24"
                        style={{
                          borderTop: "1px solid #E9ECEF",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Button
                            text={"정답 확인"}
                            disabled={
                              userChoice === -1 ||
                              quizMode === "CORRECT" ||
                              quizMode === "WRONG"
                            }
                            onClick={onCheckAnswer}
                          />
                        </Box>

                        <Box>
                          <IconButton
                            height={40}
                            classes="quiz__solve__arrow__button"
                            borderd={false}
                            disabled={step === 0}
                            onClick={() => {
                              if (step === 0) {
                                return;
                              }
                              setUserChoice(-1);
                              setQuizMode("NONE");
                              setStep((prev) => prev - 1);
                            }}
                          >
                            <Icons
                              variant="arrow"
                              label="leftBigger"
                              width={20}
                            />
                          </IconButton>
                          <IconButton
                            height={40}
                            classes="quiz__solve__arrow__button mg ml-8"
                            borderd={false}
                            disabled={
                              userChoice === -1 ||
                              !(quizMode === "CORRECT" || quizMode === "WRONG")
                            }
                            onClick={() => {
                              if (step === quizList.length - 1) {
                                setQuizMode("COMPLETED");
                                onComplete();
                                return;
                              }
                              setUserChoice(-1);
                              setQuizMode("NONE");
                              setStep((prev) => prev + 1);
                            }}
                          >
                            <Icons
                              variant="arrow"
                              label="rightBigger"
                              width={20}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      ) : (
        <Box
          width="100%"
          direction="column"
          // justifyContent="center"
          alignItems="center"
          classes="mg mt-60"
        >
          <MindsatIcon width={213} />
          <Box style={{ marginTop: "80px" }}>
            <DeniedImage />
          </Box>
          <Box direction="column" justifyContent="center" alignItems="center">
            <Typography fontWeight={700} size={18}>
              잘못된 접근입니다.
            </Typography>
            <Typography fontWeight={700} size={18}>
              본문의 링크를 클릭해 다시 접속해 보세요.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default QuizSolve;
