const NoQuizImage = ({
  classes,
  width,
  height,
}: {
  classes?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width || "200"}
      height={height || "120"}
      viewBox="0 0 200 120"
      className={classes}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M150.74 99.6834L119.119 91.2147C114.185 89.8976 111.262 84.8257 112.579 79.8923L124.803 34.2563C126.12 29.323 131.192 26.4 136.125 27.7171L167.747 36.1858C172.68 37.5029 175.603 42.5748 174.286 47.5081L162.063 93.1441C160.734 98.0774 155.674 101 150.74 99.6834Z"
        fill="#EBF0F2"
      />
      <path
        d="M145.84 65.2063L153.731 67.2975L153.72 67.3321C152.553 71.7224 148.96 75.1769 144.466 75.8123C135.512 77.0832 129.238 68.9958 131.294 61.3128C132.947 55.1548 139.359 51.4577 145.517 53.0983L143.402 60.9893C142.906 62.8263 144.003 64.721 145.84 65.2063Z"
        fill="#D0D5D8"
      />
      <path
        d="M146.095 59.2666L148.036 52.0342C153.824 53.5939 157.302 59.6248 155.754 65.413V65.4246L148.521 63.4836C146.684 62.9868 145.598 61.0921 146.095 59.2666Z"
        fill="#D0D5D8"
      />
      <path
        d="M80.4833 91.2049L48.8618 99.6736C43.9285 100.991 38.868 98.0677 37.5394 93.1344L25.3157 47.4984C23.9987 42.5651 26.9218 37.5046 31.8552 36.176L63.4767 27.7074C68.41 26.3903 73.4705 29.3133 74.7991 34.2466L87.0228 79.8826C88.3398 84.8159 85.4166 89.8879 80.4833 91.2049Z"
        fill="#EBF0F2"
      />
      <path
        d="M71.7073 68.202L62.3258 59.4561C60.9971 58.2198 58.8251 58.7975 58.2936 60.5421L55.3937 70.039L51.0843 66.0184C50.0676 65.071 48.404 65.51 47.9881 66.8502L45.1113 76.2432C44.6838 77.6643 45.9778 79.0044 47.3988 78.6116L57.4503 75.9197C57.4618 75.9197 57.4734 75.9081 57.485 75.9081C57.4965 75.9081 57.5081 75.9081 57.5196 75.9081L70.6442 72.3959C72.5389 71.8875 73.1168 69.5306 71.7073 68.202Z"
        fill="#D0D5D8"
      />
      <path
        d="M47.3537 62.1702C48.9681 62.1702 50.2768 60.8616 50.2768 59.2472C50.2768 57.6329 48.9681 56.3242 47.3537 56.3242C45.7394 56.3242 44.4307 57.6329 44.4307 59.2472C44.4307 60.8616 45.7394 62.1702 47.3537 62.1702Z"
        fill="#D0D5D8"
      />
      <path
        d="M116.175 85.739H83.4439C78.3373 85.739 74.2012 81.6029 74.2012 76.4963V29.2427C74.2012 24.1361 78.3373 20 83.4439 20H116.175C121.281 20 125.418 24.1361 125.418 29.2427V76.4963C125.418 81.6029 121.281 85.739 116.175 85.739Z"
        fill="#ADCDFB"
      />
      <path
        d="M116.884 56.8896H82.7217C82.1694 56.8896 81.7217 57.3374 81.7217 57.8896V58.5238C81.7217 59.0761 82.1694 59.5238 82.7217 59.5238H116.884C117.436 59.5238 117.884 59.0761 117.884 58.5238V57.8896C117.884 57.3374 117.436 56.8896 116.884 56.8896Z"
        fill="white"
      />
      <path
        d="M116.919 64.8252H82.7568C82.2046 64.8252 81.7568 65.2729 81.7568 65.8252V66.4594C81.7568 67.0117 82.2046 67.4594 82.7568 67.4594H116.919C117.471 67.4594 117.919 67.0117 117.919 66.4594V65.8252C117.919 65.2729 117.471 64.8252 116.919 64.8252Z"
        fill="white"
      />
      <path
        d="M116.918 72.5557H103.055C102.502 72.5557 102.055 73.0034 102.055 73.5557V74.6057C102.055 75.158 102.502 75.6057 103.055 75.6057H116.918C117.47 75.6057 117.918 75.158 117.918 74.6057V73.5557C117.918 73.0034 117.47 72.5557 116.918 72.5557Z"
        fill="white"
      />
      <path
        d="M87.1107 31.3691H83.248C82.4196 31.3691 81.748 32.0407 81.748 32.8691V49.406C81.748 50.2344 82.4196 50.906 83.248 50.906H87.1107C87.9391 50.906 88.6107 50.2344 88.6107 49.406V32.8691C88.6107 32.0407 87.9391 31.3691 87.1107 31.3691Z"
        fill="#E8F3FF"
      />
      <path
        d="M98.0267 39.1104H94.1641C93.3356 39.1104 92.6641 39.7819 92.6641 40.6104V49.4064C92.6641 50.2348 93.3356 50.9064 94.1641 50.9064H98.0267C98.8552 50.9064 99.5267 50.2348 99.5267 49.4064V40.6104C99.5267 39.7819 98.8552 39.1104 98.0267 39.1104Z"
        fill="#E8F3FF"
      />
      <path
        d="M108.935 45.1855H105.072C104.244 45.1855 103.572 45.8571 103.572 46.6855V49.3929C103.572 50.2214 104.244 50.8929 105.072 50.8929H108.935C109.764 50.8929 110.435 50.2214 110.435 49.3929V46.6855C110.435 45.8571 109.764 45.1855 108.935 45.1855Z"
        fill="#E8F3FF"
      />
    </svg>
  );
};

export default NoQuizImage;
