import {
  Box,
  Button,
  Icons,
  ScrollBox,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizTemplateDTO } from "../../../types/Quiz";
import WSEdit from "../../../component/WSEdit";
import { useNavigate } from "react-router-dom";
import utilsCommon from "../../../shared/utils/common";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { nanoid } from "@reduxjs/toolkit";

const WriteQuizTemplate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  const [body, setBody] = useState<string>("");
  const [initBody, setInitBody] = useState<string>("");

  const { quizState, insertQuizTemplate, getQuizTemplate, updateQuizTemplate } =
    useQuizStore();

  const [templateInfo, setTemplateInfo] = useState<QuizTemplateDTO>({
    templateName: "",
    subject: "",
    sendName: "",
    sendEmail: "",
  });

  useEffect(() => {
    getQuizTemplate();
  }, []);

  useEffect(() => {
    if (quizState.quizTemplate) {
      setTemplateInfo(quizState.quizTemplate);
      setInitBody(quizState.quizTemplate.body);
      changeType("UPDATE");
    }
  }, [quizState.quizTemplate]);

  const toast = useToast();

  const insertTemplate = async () => {
    if (templateInfo) {
      const bodyFile = utilsCommon.generateHtmlFileFromString(
        templateInfo.templateName,
        body
      );

      const resp: any = await insertQuizTemplate({
        file: bodyFile,
        request: {
          templateName: templateInfo.templateName,
          subject: templateInfo.subject,
          sendEmail: templateInfo.sendEmail,
          sendName: templateInfo.sendName,
        },
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("template.msg.templateAddSuccessMsg"),
          "success"
        );
      } else {
        console.log("error..");
      }
    }
  };

  const updateTemplate = async () => {
    if (templateInfo) {
      const bodyFile = utilsCommon.generateHtmlFileFromString(
        templateInfo.templateName,
        body
      );

      const resp: any = await updateQuizTemplate({
        file: bodyFile,
        request: {
          templateName: templateInfo.templateName,
          subject: templateInfo.subject,
          sendEmail: templateInfo.sendEmail,
          sendName: templateInfo.sendName,
          templateId: templateInfo.templateId,
        },
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.updateMsg"), "success");
        navigate(`${process.env.PUBLIC_URL}/quiz/template`);
      } else {
        console.log("error..");
      }
    }
  };

  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {type === "UPDATE"
              ? t("quiz.label.editQuizMailTemp")
              : t("template.add")}
          </Typography>
        </Box>
      </Box>

      <Box classes="mg mt-16" direction="column">
        <Box width="100%" justifyContent="space-between">
          <Box direction="column" width="calc(100%/3)" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("template.subject")}
            </Typography>
            <TextField
              borderRadius={8}
              // width={338}
              height={40}
              hasText
              placeholder={t("template.msg.subjectPlaceholder")}
              text={templateInfo.subject ? templateInfo.subject : ""}
              onChange={(e: any) =>
                setTemplateInfo((prev) => {
                  return { ...prev, subject: e.target.value };
                })
              }
              //   disabled={isUsed && type === "UPDATE"}
              maxLength={255}
            />
          </Box>

          <Box direction="column" width="calc(100%/3)" classes="mg mr-24">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("template.senderName")}
            </Typography>
            <TextField
              borderRadius={8}
              // width={338}
              height={40}
              hasText
              placeholder={t("template.msg.senderNamePlaceholder")}
              text={templateInfo.sendName ? templateInfo.sendName : ""}
              onChange={(e: any) =>
                setTemplateInfo((prev) => {
                  return { ...prev, sendName: e.target.value };
                })
              }
              //   disabled={isUsed && type === "UPDATE"}
              maxLength={128}
            />
          </Box>
          <Box direction="column" width="calc(100%/3)">
            <Typography fontWeight={700} classes="mg mb-12">
              {t("template.senderEmail")}
            </Typography>
            <TextField
              borderRadius={8}
              // width={519}
              height={40}
              hasText
              placeholder={t("template.msg.senderEmailPlaceholder")}
              text={templateInfo.sendEmail ? templateInfo.sendEmail : ""}
              onChange={(e: any) =>
                setTemplateInfo((prev) => {
                  return { ...prev, sendEmail: e.target.value };
                })
              }
              //   disabled={isUsed && type === "UPDATE"}
              maxLength={128}
            />
          </Box>
        </Box>

        {/* editor start */}
        <Box classes="mg mt-36" direction="column">
          <Typography fontWeight={700} classes="mg mb-12">
            {t("template.body")}
          </Typography>
          <Box height={600}>
            <WSEdit
              onChange={(e) => {
                setBody(e);
                // if (e.includes("##IMAGE##")) {
                //   let imagePath = imageList.find(
                //     (i) => i.imageId === selectedImage
                //   )?.imagePath;
                //   setBody(e.replace("##IMAGE##", imagePath ?? "##IMAGE##"));
                // }
              }}
              initBody={initBody}
              isShowButtons={true}
            />
          </Box>
        </Box>
        {/* editor end */}

        {/* footer start */}

        <Box width={"100%"} justifyContent="flex-end">
          <Button
            color="secondary"
            classes="mg mr-8"
            onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/template`)}
          >
            {t("common.button.cancel")}
          </Button>
          <Button
            onClick={() => {
              if (type === "UPDATE") updateTemplate();
              else insertTemplate();
            }}
          >
            {type === "UPDATE"
              ? t("common.button.update")
              : t("quiz.label.save")}
          </Button>
        </Box>
        {/* footer end */}
      </Box>
    </ScrollBox>
  );
};

export default WriteQuizTemplate;
