import "./index.scss";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Icons,
  Table,
  Tag,
  ToolTip,
  Typography,
} from "fasoo-ui-component-next";
import { useEffect, useRef, useState } from "react";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import useDashboardStore from "../../redux/dispatcher/useDashboardStore";
import utilsCommon from "../../shared/utils/common";
import NoTemplateImage from "../../shared/image/NoTemplateImage";
import ScenarioInfoImage from "../../shared/image/ScenarioInfoImage";
import StatInfoImage from "../../shared/image/StatInfoImage";
import { useNavigate } from "react-router-dom";
import NoInfo from "../../component/NoInfo";
import { useTranslation } from "react-i18next";
import ScenarioDetailModal from "../ManageScenario/List/ScenarioDetailModal";

export interface TotalType {
  callAverage: number;
  callTotal: number;
  applyAverage: number;
  applyTotal: number;
  linkAverage: number;
  linkTotal: number;
  infoAverage: number;
  infoTotal: number;
  fileAverage: number;
  fileTotal: number;
  allLink: number;
  allInfo: number;
  allFile: number;
}
const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { dashboardState, getStatTraining, getStatTopTraining } =
    useDashboardStore();
  const [stats, setStats] = useState<StatTraining[]>([]);
  const [statsTop, setStatsTop] = useState<StatTopTraining>();
  const [totalData, setTotalData] = useState<TotalType | null>();
  const [targetConfigId, changeTargetConfigId] = useState(0);
  const [targetOpenSenarioId, changeOpenSenarioId] = useState(-1);
  const [scenarioModalType, setScenarioModalType] = useState<
    "DETAIL" | "USED" | "NONE"
  >("NONE");
  const { t, i18n } = useTranslation();
  const [topWidth, setTopWidth] = useState<number>(window.innerWidth - 320);
  const [middleWidth, setMiddleWidth] = useState<number>(
    (window.innerWidth - 400) / 2
  );
  useEffect(() => {
    getStatTraining({ realTrainingFlag: true });
    const handleResize = () => {
      setTopWidth(window.innerWidth - 310);
      setMiddleWidth((window.innerWidth - 400) / 2);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (targetConfigId > 0)
      getStatTopTraining({ configId: targetConfigId, intervalTime: 9 });
  }, [targetConfigId]);

  useEffect(() => {
    setStats(dashboardState.stats);
    if (dashboardState.stats.length > 0) {
      changeTargetConfigId(dashboardState.stats[0].configId);

      let totalType: TotalType = {
        callAverage: 0,
        callTotal: 0,
        applyAverage: 0,
        applyTotal: 0,
        linkAverage: 0,
        linkTotal: 0,
        infoAverage: 0,
        infoTotal: 0,
        fileAverage: 0,
        fileTotal: 0,
        allFile: 0,
        allLink: 0,
        allInfo: 0,
      };
      let allUser = 0;
      let allSendSuccess = 0;

      dashboardState.stats.forEach((item: StatTraining) => {
        totalType.callTotal += item.callResult;
        totalType.applyTotal += item.applyUser;
        totalType.linkTotal += item.clickLink;
        totalType.infoTotal += item.clickInfo;
        totalType.fileTotal += item.clickFile;
        allUser += item.sendUserCount;
        allSendSuccess += item.sendSuccess;
        totalType.allLink += item.totalClickLink;
        totalType.allFile += item.totalClickFile;
        // totalType.allInfo
      });

      totalType.callAverage =
        totalType.callTotal === 0 && allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.callTotal * 100) / allSendSuccess
            );
      totalType.applyAverage =
        totalType.applyTotal === 0 && allUser === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.applyTotal * 100) / allUser
            );
      totalType.linkAverage =
        totalType.linkTotal === 0 && totalType.allLink === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.linkTotal * 100) / totalType.allLink
            );

      totalType.infoAverage =
        totalType.infoTotal === 0 && allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.infoTotal * 100) / allSendSuccess
            );
      totalType.fileAverage =
        totalType.fileTotal === 0 && totalType.allFile === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.fileTotal * 100) / totalType.allFile
            );

      setTotalData(totalType);
    } else {
      setTotalData(null);
    }
  }, [dashboardState.stats]);

  useEffect(() => {
    setStatsTop(dashboardState.statsTop);
  }, [dashboardState.statsTop]);

  const getDateRange = (startDate: string, endDate: string) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const stopDate = new Date(endDate);
    while (currentDate <= stopDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates.map((date) => utilsCommon.getShortDate(date.toString()));
  };

  const mapDataToDates = (
    dates: string[],
    data: StatDate[],
    key: keyof StatDate
  ) => {
    const dataMap: { [key: string]: number } = data.reduce((acc, item) => {
      const date = utilsCommon.getShortDate(item.sendStartDate);
      acc[date] = (item[key] as number) || 0;
      return acc;
    }, {} as { [key: string]: number });
    return dates.map((date) => dataMap[date] || 0);
  };

  // useEffect(() => {
  //   // 데이터가 모두 0인 경우에 대한 처리
  //   const yAxisExtremesHandler = () => {
  //     const chart = (Highcharts as any).charts[1]; // 현재 차트 객체 가져오기
  //     if (chart) {
  //       const yAxis = chart.yAxis[0]; // 첫 번째 y축 가져오기
  //       const dataMin = yAxis.dataMin;
  //       const dataMax = yAxis.dataMax;

  //       // 데이터가 모두 0인 경우
  //       if (dataMin === 0 && dataMax === 0) {
  //         yAxis.setExtremes(0, 1, true, false); // y축 범위 설정
  //       }
  //     }
  //   };

  //   // 차트 렌더링 후 이벤트 핸들러 등록
  //   Highcharts.addEvent(Highcharts.Chart, "render", yAxisExtremesHandler);

  //   // 언마운트 시 이벤트 핸들러 제거
  //   return () => {
  //     Highcharts.removeEvent(Highcharts.Chart, "render", yAxisExtremesHandler);
  //   };
  // }, [statsTop]); // 빈 배열을 사용하여 초기 한 번만 등록

  useEffect(() => {
    if (statsTop) {
      const startDate = utilsCommon.getShortDate(
        dashboardState.stats.filter(
          (item: StatTraining) => item.configId === targetConfigId
        )[0]?.sendStartDate + "+00:00"
      );

      const endDate = utilsCommon.getShortDate(
        dashboardState.stats.filter(
          (item: StatTraining) => item.configId === targetConfigId
        )[0]?.sendEndDate + "+00:00"
      );

      const allDates = getDateRange(startDate, endDate);

      const reportData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "callResult"
      );
      const clickUserData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "applyUser"
      );
      const linkOpenData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "clickLink"
      );
      const attachOpenData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "clickFile"
      );
      setselectedLineOptions({
        chart: {
          type: "spline",
          width: middleWidth,
          height: 384,
          // style: { fontFamily: "Noto Sans KR" },
        },
        title: {
          text: "",
        },
        legend: {
          align: "left",
          verticalAlign: "top",
          margin: 30,
          itemStyle: {
            fontSize: "12px",
          },
        },
        xAxis: {
          // categories: statsTop?.statDate
          //   .filter((item) => item.applyUser !== 0)
          //   .map((item) => utilsCommon.getShortDate(item?.sendStartDate)),
          categories: allDates,
          title: {
            align: "high",
            style: {
              fontSize: "12px",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yAxis: {
          // max: 100,
          softMax: 1,
          title: {
            text: t("program.programDataUnit"),
            align: "high",
            rotation: 0,
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          style: {
            fontSize: "12px",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
          dataMin: 0,
          dataMax: 0,

          tickInterval: 1,
        },
        plotOptions: {
          series: {
            allowPointSelect: false,
            events: {},
          },
        },
        tooltip: {
          shared: true,
          style: {
            fontSize: "12px",
          },
          pointFormatter: function (
            this: TooltipFormatterContextObject
          ): string {
            return `<span style="color:${this.color}">\u25CF</span> ${
              this.series.name
            }: <b>${this.y} ${t("program.programDataUnit")}</b><br/>`;
          },
        },
        credits: { enabled: false },
        series: [
          {
            name: t("dashboard.report"),
            data: reportData,
            color: "#55a3ee",
          },
          {
            name: t("dashboard.clickUser"),
            // data: statsTop?.statDate
            //   .filter((item) => item.applyUser !== 0)
            //   .map((item) => item.applyUser),
            data: clickUserData,
            color: "#1bbb97",
          },
          {
            name: t("template.linkOpen"),
            // data: statsTop?.statDate
            //   .filter((item) => item.applyUser !== 0)
            //   .map((item) => item.clickLink),
            data: linkOpenData,
            color: "#ff9700",
          },
          // {
          //   name: t("template.personalInfo"),
          //   data: statsTop?.statDate
          //     .filter((item) => item.applyUser !== 0)
          //     .map((item) => item.clickInfo),
          //   color: "#fa504b",
          // },
          {
            name: t("dashboard.attachOpen"),
            // data: statsTop?.statDate
            //   .filter((item) => item.applyUser !== 0)
            //   .map((item) => item.clickFile),
            data: attachOpenData,
            color: "#9b77fc",
          },
        ],
      });
    }
  }, [statsTop, i18n.language, middleWidth]);

  const [lineOptions, setLineOptions] = useState<any>([]);
  const [selectedLineOptions, setselectedLineOptions] = useState<any>([]);
  const [chartKey, setChartKey] = useState(0);
  const [chartKey2, setChartKey2] = useState(0);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    setChartKey((prevKey) => prevKey + 1);
  }, [lineOptions]);

  useEffect(() => {
    setChartKey2((prevKey) => prevKey + 1);
  }, [selectedLineOptions]);
  useEffect(() => {
    if (chartComponentRef.current) {
      const chart = chartComponentRef.current.chart;
      chart.series.forEach((series) => {
        series.points.forEach((point) => {
          let category = point.category as string;
          if (
            parseInt(category.replace(t("program.configRound"), "")) ===
            targetConfigId
          ) {
            point.update({
              selected: true,
            });
          } else {
            point.update({
              selected: false,
            });
          }
        });
      });
    }
  }, [targetConfigId]);

  useEffect(() => {
    let nowConfig = targetConfigId;
    setLineOptions({
      chart: {
        type: "spline",
        width: topWidth,
        height: 280,
        // style: { fontFamily: "Noto Sans KR" },
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "12px",
        },
      },
      xAxis: {
        categories: stats
          .map((item) => t("report.reportConfigId", { round: item.configId }))
          .reverse(),
        title: {
          text: t("program.configRound"),
          align: "high",
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        max: 100,
        title: {
          text: "%",
          align: "high",
          rotation: 0,
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: false,

          point: {
            events: {
              click: function (this: Highcharts.Point) {
                const category: string = this.category as string; // 클릭된 포인트의 카테고리 값 가져오기
                if (
                  nowConfig ===
                  parseInt(category.replace(t("program.configRound"), ""))
                ) {
                  // nowConfig = 0;
                  // changeTargetConfigId(dashboardState.stats[0].configId);
                  // const chart = this.series.chart;
                  // chart.series.forEach((series) => {
                  //   series.points.forEach((point) => {
                  //     if (point.category === category) {
                  //       point.update({
                  //         selected: false,
                  //       });
                  //     }
                  //   });
                  // });
                } else {
                  nowConfig = parseInt(
                    category.replace(t("program.configRound"), "")
                  );
                  changeTargetConfigId(
                    parseInt(category.replace(t("program.configRound"), ""))
                  );
                  const chart = this.series.chart;
                  chart.series.forEach((series) => {
                    series.points.forEach((point) => {
                      if (point.category === category) {
                        point.update({
                          selected: true,
                        });
                      }
                      // else if (point.selected) {
                      //   point.update({
                      //     selected: false,
                      //   });
                      // }
                    });
                  });
                }
              },
            },
          },
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "14px",
        },
        pointFormatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`;
        },
      },
      credits: { enabled: false },

      series: [
        {
          name: t("dashboard.report"),
          data: stats
            .map((item) =>
              item.callResult === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.callResult * 100) / item.sendSuccess
                  )
            )
            .reverse(),

          color: "#55a3ee",
        },
        {
          name: t("dashboard.clickUser"),
          data: stats
            .map((item) =>
              item.applyUser === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.applyUser * 100) / item.sendUserCount
                  )
            )
            .reverse(),

          color: "#1bbb97",
        },
        {
          name: t("template.linkOpen"),
          data: stats
            .map((item) =>
              item.totalClickLink === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickLink * 100) / item.totalClickLink
                  )
            )

            .reverse(),

          color: "#ff9700",
        },
        // {
        //   name: t("template.personalInfo"),
        //   data: stats
        //     .map((item) =>
        //       item.clickInfo === 0
        //         ? 0
        //         : utilsCommon.truncateToTwoDecimals(
        //             (item.clickInfo * 100) / item.sendSuccess
        //           )
        //     )
        //     .reverse(),
        //   color: "#fa504b",
        // },
        {
          name: t("template.attachOpen"),
          data: stats
            .map((item) =>
              item.totalClickFile === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickFile * 100) / item.totalClickFile
                  )
            )
            .reverse(),
          color: "#9b77fc",
        },
      ],
    });
  }, [stats, i18n.language, topWidth]);

  return (
    <Box classes="main dashboard">
      {targetOpenSenarioId !== -1 && (
        <ScenarioDetailModal
          isOpen={targetOpenSenarioId !== -1}
          onClose={() => (
            changeOpenSenarioId(-1), setScenarioModalType("NONE")
          )}
          templateId={targetOpenSenarioId}
          isShowButtons={false}
          modalType={scenarioModalType}
          setModalType={setScenarioModalType}
        />
      )}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
        id={"dashboardPage"}
      >
        {stats?.length > 0 && (
          <Box direction="column">
            <Typography
              classes="mg mt-8"
              fontWeight={700}
              size={24}
              lineHeight="32px"
            >
              {t("menu.home")}
            </Typography>
          </Box>
        )}
      </Box>
      {stats?.length > 0 ? (
        <Box classes="mg mt-20" direction="column">
          {/* 최근 10회 훈련 평균 */}
          <Box direction="column" width={"100%"}>
            <Box alignItems="flex-end">
              <Typography size={16} fontWeight={700}>
                {t("dashboard.recentTenAverage")}
              </Typography>
            </Box>
            {totalData && (
              <Box
                justifyContent="space-between"
                width={"100%"}
                classes="dashboard__boxes"
              >
                <Box classes="dashboard__box blue__box">
                  <Box direction="column">
                    <Typography size={12} classes="mg mb-4" color="white">
                      {t("dashboard.report")}
                    </Typography>
                    <Typography size={32} fontWeight={900} color="white">
                      {totalData.callAverage} %
                    </Typography>
                  </Box>
                  <Icons variant="mindsat" label={"dashboardReport"} />
                </Box>
                <Box classes="dashboard__box mint__box">
                  <Box direction="column" justifyContent="space-between">
                    <Typography size={12} classes="mg mb-4" color="white">
                      {t("dashboard.clickUser")}
                    </Typography>
                    <Typography size={32} fontWeight={900} color="white">
                      {totalData.applyAverage} %
                    </Typography>
                  </Box>
                  <Icons variant="mindsat" label={"dashboardWarn"} />
                </Box>
                <Box classes="dashboard__box yellow__box">
                  <Box>
                    <Box direction="column">
                      <Typography size={12} classes="mg mb-4" color="white">
                        {`${t("dashboard.linkOpen")} / ${t(
                          "template.personalInfo"
                        )}`}
                      </Typography>
                      <Typography size={32} fontWeight={900} color="white">
                        {`${totalData.linkAverage} % / ${
                          totalData.infoTotal
                        } ${t("program.programDataUnit")}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Icons variant="mindsat" label={"dashboardLink"} />
                </Box>
                {/* <Box classes="dashboard__box red__box">
                  <Box direction="column">
                    <Typography size={12} classes="mg mb-4" color="white">
                      {t("template.personalInfo")}
                    </Typography>
                    <Typography size={32} fontWeight={900} color="white">
                      {totalData.infoAverage} %
                    </Typography>
                  </Box>
                  <Icons variant="mindsat" label={"dashboardProvide"} />
                </Box> */}
                <Box classes="dashboard__box purple__box">
                  <Box direction="column">
                    <Typography size={12} classes="mg mb-4" color="white">
                      {t("dashboard.attachOpen")}
                    </Typography>
                    <Typography size={32} fontWeight={900} color="white">
                      {totalData.fileAverage} %
                    </Typography>
                  </Box>
                  <Icons variant="mindsat" label={"dashboardAttach"} />
                </Box>
              </Box>
            )}
          </Box>

          {/* 최근 10회 훈련 추이 */}
          <Box direction="column" width={"100%"} classes="mg mt-40">
            <Box alignItems="flex-end" justifyContent="space-between">
              <Typography size={16} fontWeight={700}>
                {t("dashboard.recentTenTrainingTrend")}
              </Typography>
              {/* <Box>
                <IconButton
                  transparent
                  noHover
                  onClick={() =>
                    setLineOptions({
                      ...lineOptions,
                      chart: { ...lineOptions.chart, type: "spline" },
                    })
                  }
                >
                  <Icons
                    variant="mindsat"
                    label="lineChart"
                    stroke={
                      lineOptions?.chart?.type === "column"
                        ? "#717985"
                        : "#3182F6"
                    }
                  />
                </IconButton>
                <IconButton
                  transparent
                  noHover
                  onClick={() =>
                    setLineOptions({
                      ...lineOptions,
                      chart: { ...lineOptions.chart, type: "column" },
                    })
                  }
                >
                  <Icons
                    variant="common"
                    label="dashboard"
                    stroke={
                      lineOptions?.chart?.type === "spline"
                        ? "#717985"
                        : "#3182F6"
                    }
                  />
                </IconButton>
              </Box> */}
            </Box>
            <HighchartsReact
              highcharts={Highcharts}
              options={lineOptions}
              key={chartKey}
              ref={chartComponentRef}
            />
          </Box>

          {/* 최근 훈련 목록 */}
          <Box width={"100%"} direction="column">
            <Box alignItems="flex-end">
              <Typography size={16} fontWeight={700}>
                {t("dashboard.recentTrainingList")}
              </Typography>
            </Box>
            <Box width={"100%"} classes="mg mt-12">
              <Box width={"50%"}>
                <Table classes="default-table">
                  <colgroup>
                    <col style={{ width: "76px" }}></col>
                    <col style={{ width: "85px" }}></col>
                    <col style={{ width: "*" }}></col>
                    <col style={{ width: "72px" }}></col>
                    <col style={{ width: "72px" }}></col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{t("program.configRound")}</th>
                      <th>{t("template.type")}</th>
                      <th>{t("program.programName")}</th>
                      <th>{t("dashboard.user")}</th>
                      <th>{t("dashboard.clickCnt")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.map((item) => (
                      <tr
                        key={item.configId}
                        className={
                          targetConfigId === item.configId
                            ? "selected-table"
                            : "cursor-pointer"
                        }
                        onClick={() => changeTargetConfigId(item.configId)}
                      >
                        <td>{item.configId}</td>
                        <td>
                          <Tag
                            name={
                              item.realTrainingFlag
                                ? t("program.realTrainingLabel")
                                : t("program.mockTrainingLabel")
                            }
                            isBgFilled={false}
                            size={i18n.language === "ko" ? "ms" : "sm"}
                            color={item.realTrainingFlag ? "green" : "gray"}
                            radius={6}
                          />
                        </td>
                        <td>
                          <Box width={"100%"}>
                            <ToolTip message={item.configName} maxWidth="90%">
                              <Typography ellipsis lineHeight="normal">
                                {item.configName}
                              </Typography>
                            </ToolTip>
                          </Box>
                        </td>
                        <td>
                          {item.userCount} {t("program.programPeopleUnit")}
                        </td>
                        <td>
                          {item.clickFile + item.clickInfo + item.clickLink}{" "}
                          {t("program.programDataUnit")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
              <Box classes="configChart">
                <Typography size={14} isBold>
                  {t("dashboard.trainingTrend", { configId: targetConfigId })}
                </Typography>
                <Box classes="mg mt-12 configChart-body">
                  <Box
                    justifyContent="space-between"
                    height={32}
                    width={"100%"}
                  >
                    <Typography size={14} lineHeight="32px" color={"secondary"}>
                      {t("program.trainingDuration")}:{" "}
                      {utilsCommon.getShortDate(
                        dashboardState.stats.filter(
                          (item: StatTraining) =>
                            item.configId === targetConfigId
                        )[0]?.sendStartDate + "+00:00"
                      )}
                      ~{" "}
                      {utilsCommon.getShortDate(
                        dashboardState.stats.filter(
                          (item: StatTraining) =>
                            item.configId === targetConfigId
                        )[0]?.sendEndDate + "+00:00"
                      )}
                    </Typography>
                    {/* {!(
                      statsTop?.statDate?.length === 0 &&
                      statsTop?.clickedUser?.length === 0 &&
                      statsTop?.nothingUser?.length === 0 &&
                      statsTop?.scenario?.length === 0
                    ) && ( */}
                    {/* <Box>
                      <IconButton
                        transparent
                        noHover
                        onClick={() =>
                          setselectedLineOptions({
                            ...selectedLineOptions,
                            chart: {
                              ...selectedLineOptions.chart,
                              type: "spline",
                            },
                          })
                        }
                      >
                        <Icons
                          variant="mindsat"
                          label="lineChart"
                          stroke={
                            selectedLineOptions?.chart?.type === "column"
                              ? "#717985"
                              : "#3182F6"
                          }
                        />
                      </IconButton>
                      <IconButton
                        transparent
                        noHover
                        onClick={() =>
                          setselectedLineOptions({
                            ...selectedLineOptions,
                            chart: {
                              ...selectedLineOptions.chart,
                              type: "column",
                            },
                          })
                        }
                      >
                        <Icons
                          variant="common"
                          label="dashboard"
                          stroke={
                            selectedLineOptions?.chart?.type === "spline"
                              ? "#717985"
                              : "#3182F6"
                          }
                        />
                      </IconButton>
                    </Box> */}
                    {/* )} */}
                  </Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={selectedLineOptions}
                    key={chartKey2}
                  />
                  {/* {statsTop?.statDate?.length === 0 &&
                  statsTop?.clickedUser?.length === 0 &&
                  statsTop?.nothingUser?.length === 0 &&
                  statsTop?.scenario?.length === 0 ? (
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      height={"100%"}
                      direction="column"
                    >
                      <NoDashboardImage />
                      <Typography
                        classes="mg mt-20"
                        size={18}
                        lineHeight="normal"
                        fontWeight={700}
                      >
                        표시할 항목 없음
                      </Typography>
                      <Typography
                        classes="mg mt-8"
                        size={14}
                        lineHeight="normal"
                        fontWeight={400}
                        color={"secondary"}
                      >
                        신고 및 위반 사항이 없습니다.
                      </Typography>
                    </Box>
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={selectedLineOptions}
                    />
                  )} */}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* TopN */}
          <Box classes="mg mt-40 mb-40" width={"100%"}>
            <Box direction="column" width={"calc((100% - 130px)/3)"}>
              <Box alignItems="flex-end">
                <Typography size={16} fontWeight={700}>
                  {t("dashboard.clickUserTopTen")}
                </Typography>
              </Box>
              <Box classes="mg mt-16" direction="column">
                {statsTop?.clickedUser && statsTop?.clickedUser.length > 0 ? (
                  statsTop?.clickedUser?.map((item, index) => (
                    <Box
                      justifyContent="space-between"
                      alignItems="center"
                      height={52}
                      classes="dashboard_toplist"
                      onClick={() => {
                        navigate(`/manageTrain/result`);
                        window.sessionStorage.setItem(
                          "resultFilter",
                          `configId=${targetConfigId}&userName=${item.name}`
                        );
                      }}
                    >
                      <Box alignItems="center" width={"calc(100% - 30px)"}>
                        <Typography width="28px" size={14} fontWeight={500}>
                          {(index < 10 ? "0" : "") + (index + 1)}
                        </Typography>
                        <Avatar name={item.name} userId={item.email} />
                        <ToolTip
                          message={`${item.name} (${item.email})`}
                          maxWidth={"calc(100% - 70px)"}
                        >
                          <Typography
                            classes="mg ml-8"
                            maxWidth="150px"
                            ellipsis
                            lineHeight="1.4"
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            classes="mg ml-8"
                            maxWidth="200px"
                            ellipsis
                            lineHeight="1.4"
                          >
                            ({item.email})
                          </Typography>
                        </ToolTip>
                      </Box>
                      <Typography>
                        {item.count.toLocaleString()}{" "}
                        {t("program.programDataUnit")}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <NoInfo />
                )}
              </Box>
            </Box>
            <Divider direction="col" classes="mg ml-32 mr-32" />
            <Box direction="column" width={"calc((100% - 130px)/3)"}>
              <Box alignItems="flex-end">
                <Typography size={16} fontWeight={700}>
                  {t("dashboard.noReportUserTopTen")}
                </Typography>
              </Box>
              <Box classes="mg mt-16" direction="column">
                {statsTop?.nothingUser && statsTop?.nothingUser.length > 0 ? (
                  statsTop?.nothingUser?.map((item, index) => (
                    <Box
                      justifyContent="space-between"
                      alignItems="center"
                      height={52}
                      classes="dashboard_toplist"
                      onClick={() => {
                        navigate(`/manageTrain/result`);
                        window.sessionStorage.setItem(
                          "resultFilter",
                          `configId=${targetConfigId}&userName=${item.name}&callResult`
                        );
                      }}
                    >
                      <Box alignItems="center" width={"calc(100% - 30px)"}>
                        <Typography width="28px" size={14} fontWeight={500}>
                          {(index < 10 ? "0" : "") + (index + 1)}
                        </Typography>
                        <Avatar name={item.name} userId={item.email} />
                        <ToolTip
                          message={`${item.name} (${item.email})`}
                          maxWidth={"calc(100% - 70px)"}
                        >
                          <Box alignItems="center" height={20}>
                            <Typography
                              classes="mg ml-8"
                              maxWidth="150px"
                              ellipsis
                              lineHeight="1.4"
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              classes="mg ml-8"
                              maxWidth="200px"
                              ellipsis
                              lineHeight="1.4"
                            >
                              ({item.email})
                            </Typography>
                          </Box>
                        </ToolTip>
                      </Box>
                      <Typography>
                        {item.count.toLocaleString()}{" "}
                        {t("program.programDataUnit")}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <NoInfo />
                )}
              </Box>
            </Box>
            <Divider direction="col" classes="mg ml-32 mr-32" />
            <Box direction="column" width={"calc((100% - 130px)/3)"}>
              <Box alignItems="flex-end">
                <Typography size={16} fontWeight={700}>
                  {t("dashboard.clickScenarioTopTen")}
                </Typography>
              </Box>
              <Box classes="mg mt-16" direction="column">
                {statsTop?.scenario && statsTop?.scenario.length > 0 ? (
                  statsTop?.scenario.map((item, index) => (
                    <Box
                      justifyContent="space-between"
                      alignItems="center"
                      height={52}
                      classes="dashboard_toplist"
                      onClick={() => (
                        changeOpenSenarioId(parseInt(item.id)),
                        setScenarioModalType("DETAIL")
                      )}
                    >
                      <Box alignItems="center" width={"calc(100% - 30px)"}>
                        <Typography width="28px" size={14} fontWeight={500}>
                          {(index < 10 ? "0" : "") + (index + 1)}
                        </Typography>
                        <ToolTip
                          message={`${item.name}`}
                          maxWidth={"calc(100% - 70px)"}
                          // width={"100%"}
                        >
                          <Typography
                            classes="mg ml-8"
                            ellipsis
                            maxWidth="350px"
                            lineHeight="1.4"
                          >
                            {item.name}
                          </Typography>
                        </ToolTip>
                      </Box>
                      <Typography>
                        {item.count.toLocaleString()}{" "}
                        {t("program.programDataUnit")}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <NoInfo />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box direction="column" classes="mg mt-40">
          <Box width="100%" height={256} justifyContent="center">
            <NoTemplateImage width={288} height={160} />
            <Box width={800} direction="column" classes="mg ml-24">
              <Typography size={20} fontWeight={700} classes="mg mb-12">
                {t("dashboard.createTrainingTitle")}
              </Typography>
              <Box width={310}>
                <Typography size={16} color="secondary" classes="mg mb-24">
                  {t("dashboard.msg.createTrainingTitleMsg")}
                </Typography>
              </Box>
              <Button
                startIcon={
                  <Icons variant="common" label="plus" stroke="#FFFFFF" />
                }
                color="primary"
                width={115}
                onClick={() => navigate("/manageTrain/list")}
              >
                {t("menu.createTraining")}
              </Button>
            </Box>
          </Box>
          <Box width="100%" height={256} justifyContent="center">
            <ScenarioInfoImage />
            <Box width={800} direction="column" classes="mg ml-24">
              <Typography size={20} fontWeight={700} classes="mg mb-12">
                {t("menu.templateManagement")}
              </Typography>
              <Box width={320}>
                <Typography size={16} color="secondary" classes="mg mb-24">
                  {t("dashboard.msg.scenarioTitleMsg")}
                </Typography>
              </Box>
              <Button
                startIcon={
                  <Icons variant="file" label="file1" stroke="#FFFFFF" />
                }
                color="primary"
                width={141}
                onClick={() => navigate("/manageTrain/scenario")}
              >
                {t("menu.templateManagement")}
              </Button>
            </Box>
          </Box>
          <Box width="100%" height={256} justifyContent="center">
            <StatInfoImage />
            <Box width={800} direction="column" classes="mg ml-24">
              <Typography size={20} fontWeight={700} classes="mg mb-24">
                {t("dashboard.reportTitle")}
              </Typography>
              <Box width={260}>
                <Typography size={16} color="secondary" classes="mg mb-24">
                  {t("dashboard.msg.reportTitleMsg")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Dashboard;
