import {
  Box,
  Button,
  Calendar,
  Divider,
  Modal,
  Select,
  Tab,
  TabBox,
  TabPanel,
  Tabs,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import dayjs from "dayjs";
import StaticsPage from "./StaticsPage";
import useReportRankStore from "../../../redux/dispatcher/useReportRankStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import utilsCommon from "../../../shared/utils/common";

const ReportStatistics: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { getRankByPeriod } = useReportRankStore();

  const [tab] = useState(0);
  const [openDateModal, toggleDateModal] = useState(false);
  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "PERIOD_6MONTH",
    start: dayjs()
      .subtract(6, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const changeDateFilter = (type: string) => {
    if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(type === "PERIOD_6MONTH" ? 6 : 12, "month")
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };
  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        isDivider={false}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              text={t("common.button.cancel")}
            />
            <Button
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }

                if (tempPeriod.end - tempPeriod.start > 31556926) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              classes={"mg ml-8"}
              text={t("common.button.check")}
            />
          </Box>
        }
      >
        <Box alignItems="center" justifyContent="center">
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                start: dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .valueOf(),
              })
            }
            lang={i18n.language}
            placeholder={t("common.info.startDate")}
            selected={new Date(tempPeriod.start)}
            dateFormat="yyyy-MM-dd"
            maxDate={
              tempPeriod && tempPeriod.end
                ? new Date(tempPeriod.end)
                : undefined
            }
          />
          <Box
            style={{ color: "#717985" }}
            alignItems="center"
            classes="mg ml-4 mr-4"
          >
            ~
          </Box>
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                end: dayjs(date)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .valueOf(),
              })
            }
            lang={i18n.language}
            selected={new Date(tempPeriod.end)}
            placeholder={t("common.info.endDate")}
            dateFormat="yyyy-MM-dd"
            minDate={
              tempPeriod && tempPeriod.start
                ? new Date(tempPeriod.start)
                : undefined
            }
          />
        </Box>
      </Modal>
    );
  };
  useEffect(() => {
    getRankByPeriod({
      startDate: selectedPeriod.start / 1000,
      endDate: selectedPeriod.end / 1000,
      intervalTime: utilsCommon.getIntervalTime(),
    });
  }, [selectedPeriod]);
  return (
    <Box classes="main reportStatistics">
      {openDateModal && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[t("menu.reportManagement"), t("menu.reportGraph")]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.reportGraph")}
          </Typography>
        </Box>
      </Box>
      <Box width={"100%"}>
        <TabBox style={{ height: "calc(100% - 112px)" }}>
          <Tabs value={0}>
            <Tab
              style={{ display: "flex" }}
              labelComp={
                <Box alignItems="center">
                  <Select
                    transparent
                    // width={20}
                    autoWidth
                    align={"left"}
                    height={20}
                    title={
                      selectedPeriod.value === "PERIOD_6MONTH"
                        ? t("report.stats.reportRecent6Month")
                        : selectedPeriod.value === "PERIOD_1YEAR"
                        ? t("report.stats.reportRecent1Year")
                        : t("graph.phishingCustomScope")
                    }
                    onClick={changeDateFilter}
                    stroke={tab === 0 ? "#252d38" : "#717985"}
                    classes="tab__select"
                  >
                    <SelectItem eventKey="PERIOD_6MONTH">
                      {" "}
                      {t("report.stats.reportRecent6Month")}
                    </SelectItem>
                    <SelectItem eventKey="PERIOD_1YEAR">
                      {t("report.stats.reportRecent1Year")}
                    </SelectItem>
                    <Divider direction="row" classes="mg mt-2 mb-2" />
                    <SelectItem eventKey="PERIOD_CUSTOM">
                      {t("graph.phishingCustomScope")}
                    </SelectItem>
                  </Select>
                </Box>
              }
              index={0}
              fontSize={16}
              fontWeight={700}
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <StaticsPage period={selectedPeriod} />
          </TabPanel>
        </TabBox>
      </Box>
    </Box>
  );
};

export default ReportStatistics;
