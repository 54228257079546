import { Avatar, Box, Icons, Typography } from "fasoo-ui-component-next";

interface OrganCardProps {
  type?: "USER" | "DEPT";
  userId?: string;
  userName?: string;
  deptName?: string;
  height?: number;
  jobTitle?: string;
  userEmail?: string;
  deleteEvent?: () => void;
}
const OrganCard: React.FC<OrganCardProps> = ({
  type,
  userId,
  userName,
  deptName,
  height,
  jobTitle,
  userEmail,
  deleteEvent,
}) => {
  return (
    <Box
      width={"100%"}
      height={height ?? 52}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box width={"100%"}>
        <Avatar
          userId={userId}
          name={userName}
          variant={type === "DEPT" ? "team" : undefined}
        />
        <Box classes="mg ml-8" direction="column" justifyContent="center">
          <Box>
            <Typography>
              {type === "DEPT" ? `${userName}` : `${userName} (${userEmail})`}
            </Typography>
          </Box>
          {deptName && (
            <Box classes="mg mt-2">
              <Typography color="secondary" size={12}>
                {jobTitle ? `${deptName} / ${jobTitle}` : `${deptName}`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {deleteEvent && (
        <Box style={{ cursor: "pointer" }} onClick={deleteEvent}>
          <Icons variant="mindsat" label="icon_trash" classes="mg mr-4" />
        </Box>
      )}
    </Box>
  );
};

export default OrganCard;
