import {
  Box,
  Button,
  Checkbox,
  FormArea,
  IconButton,
  Icons,
  Modal,
  ScrollBox,
  Select,
  Tag,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import "./index.scss";
import { useEffect, useState } from "react";
import useReportStore from "../../../redux/dispatcher/useReportStore";
import { AttachmentType, ReportMail } from "../../../types/Report";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import CommonModal from "../../../component/CommonModal";
import copy from "clipboard-copy";
import utilsCommon from "../../../shared/utils/common";
import MailBody from "../../../component/MailBody";
import { nanoid } from "@reduxjs/toolkit";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { ReportReply } from "../../../types/Reply";
import WSEdit from "../../../component/WSEdit";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
interface ReportViewDetailProps {
  targetId: number;
  getNewReportMails: () => void;
  setTargetId: (id: number) => void;
}
const ReportViewDetail: React.FC<ReportViewDetailProps> = ({
  targetId,
  setTargetId,
  getNewReportMails,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    reportState,
    getReportMailDetail,
    sendManualReply,
    updateReportMail,
    downloadAttachment,
  } = useReportStore();
  const { replyState, getReplys } = useReplyStore();
  const [targetMail, changeTargetMail] = useState<ReportMail | undefined>();
  const [manualReplyList, changeManualReplyList] = useState<ReportReply[]>([]);
  const [modalOpenStatus, changeModalOpenStatus] = useState("CLOSE");
  const [comment, changeComment] = useState("");
  const [targetTemplateId, changeTaregetTemplateId] = useState("none");
  const [tempCC, changeTempCC] = useState("");
  const [CCList, changeCCList] = useState<string[]>([]);
  const [tempBCC, changeTempBCC] = useState("");
  const [BCCList, changeBCCList] = useState<string[]>([]);
  const [replyBody, changeReplyBody] = useState("<p></p>");

  const navigate = useNavigate();

  useEffect(() => {
    getReportMailDetail(targetId);
    getReplys({ autoYn: false });
  }, [targetId]);
  useEffect(() => {
    changeTargetMail(reportState.reportDetail);
    changeComment(reportState.reportDetail?.spamComment ?? "");
  }, [reportState.reportDetail]);
  useEffect(() => {
    changeManualReplyList(replyState?.replyListWithCnt?.list);
    if (
      replyState?.replyListWithCnt?.list.filter(
        (item: any) => item.replyId + "" === targetTemplateId
      )?.length > 0
    ) {
      changeReplyBody(
        replyState?.replyListWithCnt?.list.filter(
          (item: any) => item.replyId + "" === targetTemplateId
        )[0].replyBody
      );
    }
  }, [replyState.replyListWithCnt]);
  const handleUpdateComment = async () => {
    let resp: any = await updateReportMail({
      mailReportId: targetId,
      spamComment: comment,
    });
    if (resp.payload?.data) {
      toast.toastMsg(
        nanoid(),
        t("report.msg.reportCommentAddSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(
        nanoid(),
        t("report.msg.reportCommentAddFailMsg"),
        "error"
      );
    }
  };
  const handleUpdateReportStatus = async (value: boolean) => {
    let resp: any = await updateReportMail({
      mailReportId: targetId,
      spamFlag: value,
    });
    if (resp.payload?.data) {
      toast.toastMsg(
        nanoid(),
        value
          ? t("report.msg.reportMaliciousUpdateSuccessMsg")
          : t("report.msg.reportMaliciousUpdateCancelMsg"),
        "success"
      );
      targetMail && changeTargetMail({ ...targetMail, spamFlag: value });
    } else {
      toast.toastMsg(
        nanoid(),
        t("report.msg.reportMaliciousUpdateFailMsg"),
        "error"
      );
    }
  };
  const handleUpdateReportComplete = async () => {
    let resp: any = await updateReportMail({
      mailReportId: targetId,
      processComplete: true,
    });
    if (resp.payload?.data) {
      toast.toastMsg(
        nanoid(),
        t("report.msg.reportCompleteUpdateSuccessMsg"),
        "success"
      );
      targetMail && changeTargetMail({ ...targetMail, processComplete: true });
    } else {
      toast.toastMsg(
        nanoid(),
        t("report.msg.reportCompleteUpdateFailMsg"),
        "error"
      );
    }
  };

  const [isDownloadConfirm, setIsDownloadConfirm] = useState(false);
  const [targetDownload, setTargetDownload] = useState<AttachmentType | null>(
    null
  );

  const onDownload = async (item: AttachmentType | null) => {
    if (item) {
      const resp: any = await downloadAttachment(item);
      setIsDownloadConfirm(false);
    }
  };
  const handleSendReportMail = async (isComplete?: boolean) => {
    if (!targetMail?.mailReportId) return;

    let resp: any = await sendManualReply(
      isComplete
        ? {
            ...targetMail,
            body: replyBody
              .replaceAll(
                "<table",
                "<table style='width: 100%;max-width: 100%;margin-bottom: 20px;border-collapse: collapse;'"
              )
              .replaceAll(
                "<td",
                "<td style='border: 1px solid #ddd; padding: 15px;'"
              ),
            reportDate: dayjs().format("YYYYY-MM-DD HH:mm:ss.000"),
            ccAddress: CCList,
            bccAddress: BCCList,
            processComplete: true,
          }
        : {
            ...targetMail,
            body: replyBody
              .replaceAll(
                "<table",
                "<table style='width: 100%;max-width: 100%;margin-bottom: 20px;border-collapse: collapse;'"
              )
              .replaceAll(
                "<td",
                "<td style='border: 1px solid #ddd; padding: 15px;'"
              ),
            reportDate: dayjs().format("YYYYY-MM-DD HH:mm:ss.000"),
            ccAddress: CCList,
            bccAddress: BCCList,
          }
    );
    if (resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("reply.msg.reportReplySuccessMsg"), "success");
      getNewReportMails();
    } else {
      toast.toastMsg(nanoid(), t("reply.msg.reportReplyFailMsg"), "error");
    }
    changeModalOpenStatus("CLOSE");
  };
  return (
    <Box classes="mg mt-24 reportView-detail" direction="column">
      {modalOpenStatus === "CONFIRM_SAVE_COMMENT" && (
        <CommonModal
          open={modalOpenStatus === "CONFIRM_SAVE_COMMENT"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportCommentSave")}
          subMessage={t("report.msg.reportCommentAddConfirmMsg")}
          afterSubmitButton={handleUpdateComment}
        />
      )}
      {modalOpenStatus === "SET_REPLY_COMPLETE" && (
        <CommonModal
          open={modalOpenStatus === "SET_REPLY_COMPLETE"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportComplete")}
          subMessage={t("report.msg.reportCompleteUpdateConfirmMsg")}
          afterSubmitButton={() => handleUpdateReportComplete()}
        />
      )}
      {modalOpenStatus === "CONFIRM_SAVE_SPAMFLAG" && (
        <CommonModal
          open={modalOpenStatus === "CONFIRM_SAVE_SPAMFLAG"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportMaliciousYN")}
          subMessage={
            targetMail?.spamFlag
              ? t("report.msg.reportMaliciousUpdateCancelConfirmMsg")
              : t("report.msg.reportMaliciousUpdateConfirmMsg")
          }
          afterSubmitButton={() =>
            handleUpdateReportStatus(!targetMail?.spamFlag && false)
          }
        />
      )}
      {modalOpenStatus === "SEND_REPLY_MAIL" && (
        <Modal
          open={modalOpenStatus === "SEND_REPLY_MAIL"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportReply")}
          width={536}
          footer={
            <Box justifyContent="flex-end">
              <Button
                color="secondary"
                onClick={() => changeModalOpenStatus("CLOSE")}
              >
                {t("common.button.cancel")}
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  handleSendReportMail(true);
                  changeModalOpenStatus("CLOSE");
                }}
                classes="mg ml-4"
              >
                {t("report.reportReplyAndComplete")}
              </Button>
              <Button onClick={() => handleSendReportMail()} classes="mg ml-4">
                {t("report.reportReply")}
              </Button>
            </Box>
          }
        >
          {t("report.msg.reportReplyConfirmMsg")}
        </Modal>
      )}
      {modalOpenStatus === "VIEW_MAIL_HEADER" && (
        <Modal
          open={modalOpenStatus === "VIEW_MAIL_HEADER"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportMailHeaderInfo")}
          width={536}
          isDivider={false}
          footer={
            <Box justifyContent="space-between">
              <Button
                color={"secondary"}
                onClick={() => {
                  toast.toastMsg(
                    nanoid(),
                    t("report.msg.reportHeaderCopySuccessMsg"),
                    "success"
                  );
                  copy(targetMail?.mailHeader ?? "");
                }}
              >
                {t("report.reportHeaderCopy")}
              </Button>
              <Button onClick={() => changeModalOpenStatus("CLOSE")}>
                {t("common.button.check")}
              </Button>
            </Box>
          }
          contentHeight={400}
        >
          <Typography lineHeight="normal">{targetMail?.mailHeader}</Typography>
        </Modal>
      )}

      {modalOpenStatus === "DOWNLOAD_ATTACH" && (
        <>
          <Modal
            open={modalOpenStatus === "DOWNLOAD_ATTACH"}
            onClose={() => changeModalOpenStatus("CLOSE")}
            title={t("report.reportFileDownload")}
            width={536}
            isDivider={false}
            footer={
              <Box justifyContent="flex-end">
                <Button
                  color="secondary"
                  onClick={() => changeModalOpenStatus("CLOSE")}
                >
                  {t("common.button.close")}
                </Button>
              </Box>
            }
            contentHeight={400}
          >
            <ScrollBox width="100%">
              {targetMail?.hasAttachments && (
                <Box
                  classes="mg mt-20"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="60%">
                    <Typography ellipsis width="500px" lineHeight="1.5">
                      {targetMail.fileName}
                    </Typography>
                  </Box>
                  <Button
                    color="warning"
                    onClick={() => {
                      setTargetDownload({
                        fileName: targetMail.fileName,
                        filePath: targetMail.filePath,
                      });
                      setIsDownloadConfirm(true);
                    }}
                  >
                    {t("attach.download")}
                  </Button>
                </Box>
              )}
              {targetMail?.attachments &&
                targetMail.attachments.map((item) => (
                  <Box
                    classes="mg mt-20"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box width="60%">
                      <Typography ellipsis width="500px" lineHeight="1.5">
                        {item.fileName}
                      </Typography>
                    </Box>
                    <Button
                      color="warning"
                      onClick={() => {
                        setTargetDownload(item);
                        setIsDownloadConfirm(true);
                      }}
                    >
                      {t("attach.download")}
                    </Button>
                  </Box>
                ))}
            </ScrollBox>
          </Modal>

          {isDownloadConfirm && (
            <Modal
              open={isDownloadConfirm}
              onClose={() => setIsDownloadConfirm(false)}
              title={t("report.reportFileDownload")}
              // width={536}
              isDivider={false}
              footer={
                <Box justifyContent="flex-end">
                  <Button
                    color="secondary"
                    onClick={() => setIsDownloadConfirm(false)}
                  >
                    {t("common.button.cancel")}
                  </Button>
                  <Button
                    color="warning"
                    classes="mg ml-8"
                    onClick={() => onDownload(targetDownload)}
                  >
                    {t("attach.download")}
                  </Button>
                </Box>
              }
            >
              <Typography lineHeight="1.5">
                {t("report.msg.reportFileDownloadWarningMsg")}
              </Typography>
            </Modal>
          )}
        </>
      )}
      <Box alignItems="center" height={37} classes="mg mb-10">
        <Typography size={16} isBold>
          {t("report.reportDetail")}
        </Typography>
        <Tag
          name={
            targetMail?.trainingFlag
              ? t("report.reportTraining")
              : t("report.reportNotTraining")
          }
          isBgFilled={false}
          size="ms"
          color={targetMail?.trainingFlag ? "green" : "purple"}
          radius={6}
          classes="mg ml-10"
        />
      </Box>
      {targetMail?.trainingFlag && (
        <Box width={"100%"}>
          <Box
            width={"50%"}
            direction={"column"}
            classes="reportView-detail-item"
          >
            <Typography isBold lineHeight="normal">
              {t("program.programName")}
            </Typography>
            {targetMail?.configName ? (
              <Typography classes="mg mt-12" lineHeight="normal">
                {targetMail.configName}
              </Typography>
            ) : (
              <Typography
                classes="mg mt-12"
                lineHeight="normal"
                color="secondary"
              >
                삭제된 훈련입니다.
              </Typography>
            )}
          </Box>
          <Box
            width={"50%"}
            direction={"column"}
            classes="reportView-detail--item"
          >
            <Typography isBold lineHeight="normal">
              {t("program.trainingDurationOrder")}
            </Typography>
            {targetMail.sendStartDate ? (
              <Typography classes="mg mt-12" lineHeight="normal">
                {`${utilsCommon.getShortDate(
                  targetMail.sendStartDate + "+00:00"
                )}
                ~ ${utilsCommon.getShortDate(targetMail.sendEndDate + "+00:00")}
                / ${t("report.reportConfigId", {
                  round: targetMail.configId,
                })}
                `}
              </Typography>
            ) : (
              <Typography
                classes="mg mt-12"
                lineHeight="normal"
                color="secondary"
              >
                삭제된 훈련입니다.
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Box width={"100%"}>
        <Box
          width={"50%"}
          direction={"column"}
          classes="reportView-detail-item"
        >
          <Typography isBold lineHeight="normal">
            {t("report.reportCallUser")}
          </Typography>
          <Typography classes="mg mt-12" lineHeight="normal">
            {`${targetMail?.userName}(${targetMail?.userEmail})`}
          </Typography>
        </Box>
        <Box
          width={"50%"}
          direction={"column"}
          classes="reportView-detail--item"
        >
          <Typography isBold lineHeight="normal">
            {t("report.reportForwardedTime")}
          </Typography>
          <Typography classes="mg mt-12" lineHeight="normal">
            {utilsCommon.getFullDateStr(
              targetMail?.datetimeForwarded + "+00:00"
            )}
          </Typography>
        </Box>
      </Box>
      <Box width={"100%"}>
        <Box
          width={"50%"}
          direction={"column"}
          classes="reportView-detail-item"
        >
          <Typography isBold lineHeight="normal">
            {t("report.reportAutoReply")}
          </Typography>
          {targetMail?.sendAutoReply ? (
            <Box classes="mg mt-12">
              <Icons variant="common" label="check" stroke={"#05c072"} />
              <Typography classes={"mg ml-4"} lineHeight="normal">
                {t("report.reportSendReply")}
              </Typography>
            </Box>
          ) : (
            <Box classes="mg mt-12">
              <Icons variant="file" label="new" stroke="red" />
              <Typography classes={"mg ml-4"} lineHeight="normal">
                {t("report.reportNotSendReply")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          width={"50%"}
          direction={"column"}
          classes="reportView-detail--item"
        >
          <Typography isBold lineHeight="normal">
            {t("report.reportManualReply")}
          </Typography>
          {targetMail?.sendManualReply ? (
            <Box classes="mg mt-12">
              <Icons variant="common" label="check" stroke={"#05c072"} />
              <Typography classes={"mg ml-4"} lineHeight="normal">
                {t("report.reportSendReply")}
              </Typography>
            </Box>
          ) : (
            <Box classes="mg mt-12">
              <Icons variant="file" label="new" stroke="red" />
              <Typography classes={"mg ml-4"} lineHeight="normal">
                {t("report.reportNotSendReply")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Typography isBold lineHeight="normal">
        {t("report.reportMailTitle")}
      </Typography>
      <Typography classes="mg mt-12 mb-28" lineHeight="normal">
        {targetMail?.subject}
      </Typography>
      <Typography isBold lineHeight="normal">
        {t("report.reportMailContent")}
      </Typography>
      {targetMail?.body && (
        <MailBody
          id={targetId}
          body={targetMail?.body ?? ""}
          classes={"mg mt-12 mb-28"}
        />
      )}
      <Box width="100%">
        <Box direction="column" classes="mg mb-28" width="50%">
          <Typography isBold lineHeight="normal">
            {t("report.reportHeader")}
          </Typography>
          <Typography color={"secondary"} classes="mg mt-12 mb-16" size={12}>
            {t("reply.msg.reportHeaderInfoMsg")}
          </Typography>
          <Button
            color={"secondary"}
            fullWidth={false}
            width={150}
            onClick={() => changeModalOpenStatus("VIEW_MAIL_HEADER")}
          >
            {t("report.reportHeaderButton")}
          </Button>
        </Box>

        {targetMail?.hasAttachments && (
          <Box direction="column" classes="mg mb-28">
            <Typography isBold lineHeight="normal">
              {t("attach.attachSimple")}
            </Typography>
            <Typography classes="mg mt-12 mb-16">
              {targetMail?.fileName}{" "}
              {targetMail.attachments &&
                targetMail.attachments?.length > 0 &&
                t("common.msg.moreCnt", {
                  length: targetMail.attachments.length,
                })}
            </Typography>
            <Button
              color={"secondary"}
              fullWidth={false}
              width={160}
              onClick={() => changeModalOpenStatus("DOWNLOAD_ATTACH")}
            >
              {t("report.reportFileDownload")}
            </Button>
          </Box>
        )}
      </Box>
      <Box direction="column" classes="mg mb-28">
        <Typography isBold lineHeight="normal">
          {`${t("report.reportComment")} (${t("common.msg.lengthLimitMsg", {
            length: 255,
          })})`}
        </Typography>
        <Typography color={"secondary"} classes="mg mt-12 mb-12" size={12}>
          {t("report.msg.reportCommentInfoMsg")}
        </Typography>
        <FormArea
          padding={12}
          rows={3}
          value={comment}
          maxLength={255}
          onChange={(e) => {
            changeComment(e.target.value);
          }}
        />
        <Button
          color={"secondary"}
          autoWidth
          classes="mg mt-12"
          onClick={() => changeModalOpenStatus("CONFIRM_SAVE_COMMENT")}
        >
          {t("report.reportCommentSave")}
        </Button>
      </Box>
      <Box direction="column" classes="mg mb-28">
        <Typography isBold lineHeight="normal">
          {t("report.reportMaliciousYN")}
        </Typography>
        <Typography color={"secondary"} classes="mg mt-12 mb-12" size={12}>
          {t("reply.msg.reportMaliciousFlagInfoMsg")}
        </Typography>
        <Box alignItems="center">
          <Checkbox
            wrapsodyCheckbox
            checked={targetMail?.spamFlag}
            onChange={() => changeModalOpenStatus("CONFIRM_SAVE_SPAMFLAG")}
          />
          <Typography classes="mg ml-4">
            {t("report.stats.commonMaliciousMail")}
          </Typography>
        </Box>
      </Box>
      <Box direction="column" classes="mg mb-28">
        <Box justifyContent="space-between">
          <Typography isBold lineHeight="normal">
            {t("report.reportReply")}
          </Typography>
          <Box alignItems="center">
            <Typography>{t("reply.reportSelectManualTemplate")}</Typography>
            <Select
              onClick={(e) => changeTaregetTemplateId(e)}
              classes="mg ml-8"
            >
              <SelectItem eventKey={"none"}>
                {t("common.button.notSelected")}
              </SelectItem>
              {manualReplyList?.map((item) => (
                <SelectItem eventKey={item.replyId + ""}>
                  {item.replyTitle}
                </SelectItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Typography color={"secondary"} classes="mg mt-12 mb-12" size={12}>
          {t("reply.msg.reportReplyInfoMsg")}
        </Typography>
        <Box alignItems="center">
          <Typography size={12} color={"secondary"}>
            {t("report.cc")}
          </Typography>
          <TextField
            width={200}
            size={"lg"}
            classes="mg ml-10 mr-10"
            onChange={(e) => changeTempCC(e.target.value)}
            text={tempCC}
            hasText
            onKeyPress={(e) => {
              if (e.key === "Enter" && tempCC.trim().length > 0) {
                if (CCList.includes(tempCC)) {
                  toast.toastMsg(
                    nanoid(),
                    "이미 추가된 사용자입니다.",
                    "error"
                  );
                } else if (!utilsCommon.emailValidation(tempCC)) {
                  toast.toastMsg(
                    nanoid(),
                    t("user.msg.userInvalidEmailMsg"),
                    "error"
                  );
                } else {
                  changeCCList([tempCC, ...CCList]);
                  changeTempCC("");
                }
              }
            }}
          />
          <Typography size={12} color={"secondary"}>
            {t("report.bcc")}
          </Typography>
          <TextField
            width={200}
            size={"lg"}
            classes="mg ml-10 mr-10"
            onChange={(e) => changeTempBCC(e.target.value)}
            text={tempBCC}
            hasText
            onKeyPress={(e) => {
              if (e.key === "Enter" && tempBCC.trim().length > 0) {
                if (CCList.includes(tempCC)) {
                  toast.toastMsg(
                    nanoid(),
                    "이미 추가된 사용자입니다.",
                    "error"
                  );
                } else if (!utilsCommon.emailValidation(tempBCC)) {
                  toast.toastMsg(
                    nanoid(),
                    t("user.msg.userInvalidEmailMsg"),
                    "error"
                  );
                } else {
                  changeBCCList([tempBCC, ...BCCList]);
                  changeTempBCC("");
                }
              }
            }}
          />
        </Box>
      </Box>
      <Typography>{t("report.cc")}</Typography>
      <ScrollBox maxHeight={307} classes="mg mt-8 gridList mail_ccList">
        {CCList.map((cc) => (
          <Box classes="mg mr-4 scenario__tag">
            <Typography>{cc}</Typography>
            <IconButton
              transparent
              noHover
              size={"xxsm"}
              onClick={() => changeCCList(CCList.filter((item) => item !== cc))}
            >
              <Icons
                variant="common"
                label="cancel"
                stroke={"#9d9fa4"}
                width={12}
              />
            </IconButton>
          </Box>
        ))}
      </ScrollBox>
      <Typography classes="mg mt-12">{t("report.bcc")}</Typography>
      <ScrollBox maxHeight={307} classes="mg mt-8 gridList mail_ccList">
        {BCCList.map((cc) => (
          <Box classes="mg mr-4 scenario__tag">
            <Typography>{cc}</Typography>
            <IconButton
              transparent
              noHover
              size={"xxsm"}
              onClick={() =>
                changeBCCList(BCCList.filter((item) => item !== cc))
              }
            >
              <Icons
                variant="common"
                label="cancel"
                stroke={"#9d9fa4"}
                width={12}
              />
            </IconButton>
          </Box>
        ))}
      </ScrollBox>
      <Box classes="mg mt-12">
        <WSEdit
          initBody={
            manualReplyList.filter(
              (item) => item.replyId + "" === targetTemplateId
            )[0]?.replyBody ?? undefined
          }
          onChange={(e) => changeReplyBody(e)}
          isShowButtons={false}
        />
      </Box>
      <Button
        color={"secondary"}
        autoWidth
        classes="mg mt-12"
        onClick={() => changeModalOpenStatus("SEND_REPLY_MAIL")}
      >
        {t("report.reportReply")}
      </Button>
      <Box justifyContent="flex-end">
        <Button
          color={"secondary"}
          autoWidth
          classes="mg ml-10 mr-10"
          onClick={() => setTargetId(-1)}
        >
          {t("report.reportBackToList")}
        </Button>
        {!targetMail?.trainingFlag && (
          <Button
            color={"secondary"}
            autoWidth
            classes="mg ml-10 mr-10"
            onClick={() => {
              navigate(`/manageTrain/scenario/write?mailId=${targetId}`);
            }}
          >
            {t("report.reportSaveToTemplate")}
          </Button>
        )}
        <Button
          autoWidth
          classes="mg ml-10"
          onClick={() => changeModalOpenStatus("SET_REPLY_COMPLETE")}
        >
          {t("report.reportComplete")}
        </Button>
      </Box>
    </Box>
  );
};

export default ReportViewDetail;
