import "./index.scss";
import {
  Box,
  Button,
  DropdownItem,
  IconButton,
  Icons,
  SettingButton,
  Table,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useTranslation } from "react-i18next";
import TitlePath from "../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  QuizCourseDTO,
  QuizSendHistoryDTO,
  QuizSendHistoryReqDTO,
} from "../../types/Quiz";
import useQuizStore from "../../redux/dispatcher/useQuizStore";
import utilsCommon from "../../shared/utils/common";
import CategoryTag from "../../component/CategoryTag";
import NoDashboardImage from "../../shared/image/NoDashboardImage";
import NoSearchResultImage from "../../shared/image/NoSearchResultImage";
import CommonModal from "../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";

const QuizResult: React.FC = () => {
  const PAGING_SIZE = 100;
  const { t, i18n } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const {
    quizState,
    getQuizSendHistory,
    getQuizCourses,
    exportQuizSendHistory,
    resendQuizSendHistory,
  } = useQuizStore();

  const [historyList, setHistoryList] = useState<QuizSendHistoryDTO[]>([]);
  const [historyListTotal, setHistoryListTotal] = useState<number>(0);
  const [courseList, setCourseList] = useState<number[]>();
  const [buttonClick, setButtonClick] = useState(0);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [filterData, setFilterData] = useState<QuizSendHistoryReqDTO>(
    {} as QuizSendHistoryReqDTO
  );

  const [tempUserName, setTempUserName] = useState<string>("");
  const [tempDeptName, setTempDeptName] = useState<string>("");
  const [tempCourseName, setTempCourseName] = useState<string>("");

  const [isResendModalOpen, setIsResendModalOpen] = useState<number>(-1);

  const toast = useToast();

  const resetFilter = () => {
    setFilterData({} as QuizSendHistoryReqDTO);
  };

  useEffect(() => {
    getQuizSendHistory({ ...filterData, intervalTime: 9 });
  }, [filterData]);
  useEffect(() => {
    // getQuizSendHistory({});
    getQuizCourses({});
  }, []);

  useEffect(() => {
    if (quizState.quizSendHistoryList.list) {
      setHistoryList(quizState.quizSendHistoryList.list);
      setHistoryListTotal(quizState.quizSendHistoryList.total);
    }
  }, [quizState.quizSendHistoryList]);

  useEffect(() => {
    if (quizState.quizCourseList.list.length > 0) {
      setCourseList(
        quizState.quizCourseList.list.map((i: QuizCourseDTO) => i.courseId)
      );
    }
  }, [quizState.quizCourseList]);

  const onResendMail = async () => {
    if (isResendModalOpen !== -1) {
      const resp: any = await resendQuizSendHistory({
        sendHistoryId: isResendModalOpen,
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.resendSuccess"), "success");
        setIsResendModalOpen(-1);
        getQuizSendHistory(filterData);
      }
    }
  };

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [historyList]);

  const isItemLoaded = (index: number): boolean => {
    return index < historyList.length && historyList[index] !== undefined;
  };

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  // const loadMoreItems = async () => {
  //   if (isNextPageLoading) return;

  //   setIsNextPageLoading(true);

  //   setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);

  //   try {
  //     const resp: any =
  //       !window.sessionStorage.getItem("resultFilter") &&
  //       (await getTrainingResult({
  //         ...tempFilter,
  //         size: PAGING_SIZE,
  //         cursor: historyList[historyList.length - 1].cursor,
  //       }));

  //     if (resp && resp.payload && resp.payload.data && resp.payload.data.list) {
  //       setHistoryList((prev) => {
  //         return [...prev, ...resp.payload.data.list];
  //       });

  //       if (resp.payload.data.list.length < PAGING_SIZE) {
  //         setHasNextPage(false);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Failed to load more items:", error);
  //   } finally {
  //     setIsNextPageLoading(false);
  //   }

  //   changeCopyFilter(tempFilter);
  // };

  const [tableHeight, setTableHeight] = useState(660);

  useEffect(() => {
    const handleResize = () => {
      const tableElement = document.querySelector(
        ".table-scroll"
      ) as HTMLElement;
      const tempTableHeight = tableElement ? tableElement.offsetHeight : 0;
      tableElement && setTableHeight(tempTableHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filter = (
    <Box classes="mg mt-12" alignItems="center">
      {/* courseId  */}
      <SettingButton
        buttonClick={buttonClick}
        height={200}
        button={
          <Button
            color={
              filterData.courseId && filterData.courseId > 0
                ? "tertiary-click"
                : "tertiary"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.session")}
            {filterData.courseId === undefined
              ? " · " + t("quiz.label.all")
              : " · " +
                t("common.list.numOrder", { round: filterData.courseId })}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={filterData.courseId === undefined}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, courseId: undefined }))
          }
        >
          {filterData.courseId === undefined ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        {courseList &&
          courseList.map((c: number) => (
            <DropdownItem
              isChecked={filterData.courseId === c}
              onClick={() =>
                setFilterData((prev) => ({ ...prev, courseId: c }))
              }
            >
              {filterData.courseId === c ? (
                <Icons
                  variant="mindsat"
                  label="icon_check"
                  stroke="#3182F6"
                  classes="mg mr-4"
                />
              ) : (
                <Box width={20} classes="mg mr-4" />
              )}
              {t("common.list.numOrder", { round: c })}
            </DropdownItem>
          ))}
      </SettingButton>
      {/* end courseId  */}

      {/* userName  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.userNames && filterData.userNames.length > 0
                ? "tertiary-click"
                : "tertiary"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.targets")}
            {filterData.userNames === undefined ||
            filterData.userNames.length === 0
              ? " · " + t("quiz.label.all")
              : ` · ${filterData.userNames.length}`}
          </Button>
        }
        listPosition="right"
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
        >
          <TextField
            width={200}
            startLabel={
              <Box justifyContent="center" alignItems="center" height={30}>
                <Icons variant="mindsat" label="icon_search" width={20} />
              </Box>
            }
            isSearch
            borderNone
            placeholder={"대상자를 입력하세요."}
            text={tempUserName}
            hasText
            onChange={(e) => setTempUserName(e.target.value)}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                if (e.target.value === "") return;
                if (
                  filterData.userNames &&
                  filterData.userNames.includes(e.target.value)
                ) {
                  return;
                }
                setFilterData((prev) => ({
                  ...prev,
                  userNames: prev.userNames
                    ? [...prev.userNames, tempUserName]
                    : [tempUserName],
                }));
                setTempUserName("");
              }
            }}
          />
        </Box>
        <Box>
          {filterData.userNames &&
            filterData.userNames.map((item) => (
              <CategoryTag
                key={item}
                text={item}
                endIcon={<Icons variant="common" label="delete" />}
                onClick={() =>
                  setFilterData((prev) => ({
                    ...prev,
                    userNames: prev.userNames?.filter((q) => q !== item),
                  }))
                }
              />
            ))}
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setTempUserName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                if (tempUserName === "") return;
                setTempUserName("");
                if (
                  filterData.userNames &&
                  filterData.userNames.includes(tempUserName)
                ) {
                  return;
                }
                setFilterData((prev) => ({
                  ...prev,
                  userNames: prev.userNames
                    ? [...prev.userNames, tempUserName]
                    : [tempUserName],
                }));
              }}
            />
          </Box>
        </Box>
      </SettingButton>
      {/* end userName */}

      {/* deptName  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.deptNames === undefined ||
              filterData.deptNames.length === 0
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.depts")}
            {filterData.deptNames === undefined ||
            filterData.deptNames.length === 0
              ? " · " + t("quiz.label.all")
              : ` · ${filterData.deptNames.length}`}
          </Button>
        }
        listPosition="right"
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
        >
          <TextField
            width={200}
            startLabel={
              <Box justifyContent="center" alignItems="center" height={30}>
                <Icons variant="mindsat" label="icon_search" width={20} />
              </Box>
            }
            isSearch
            borderNone
            placeholder={t("dept.msg.undefinedNameMsg")}
            text={tempDeptName}
            hasText
            onChange={(e) => setTempDeptName(e.target.value)}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                if (e.target.value === "") return;
                if (
                  filterData.deptNames &&
                  filterData.deptNames.includes(e.target.value)
                ) {
                  return;
                }
                setFilterData((prev) => ({
                  ...prev,
                  deptNames: prev.deptNames
                    ? [...prev.deptNames, tempDeptName]
                    : [tempDeptName],
                }));
                setTempDeptName("");
              }
            }}
          />
        </Box>
        <Box>
          {filterData.deptNames &&
            filterData.deptNames.map((item) => (
              <CategoryTag
                key={item}
                text={item}
                endIcon={<Icons variant="common" label="delete" />}
                onClick={() =>
                  setFilterData((prev) => ({
                    ...prev,
                    deptNames: prev.deptNames?.filter((q) => q !== item),
                  }))
                }
              />
            ))}
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setTempDeptName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                if (tempDeptName === "") return;
                setTempDeptName("");
                if (
                  filterData.deptNames &&
                  filterData.deptNames.includes(tempDeptName)
                ) {
                  return;
                }
                setFilterData((prev) => ({
                  ...prev,
                  deptNames: prev.deptNames
                    ? [...prev.deptNames, tempDeptName]
                    : [tempDeptName],
                }));
              }}
            />
          </Box>
        </Box>
      </SettingButton>
      {/* end deptName  */}

      {/* courseName  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.courseNames === undefined ||
              filterData.courseNames.length === 0
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.courseTitle")}
            {filterData.courseNames === undefined ||
            filterData.courseNames.length === 0
              ? " · " + t("quiz.label.all")
              : ` · ${filterData.courseNames.length}`}
          </Button>
        }
        listPosition="right"
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
        >
          <TextField
            width={200}
            startLabel={
              <Box justifyContent="center" alignItems="center" height={30}>
                <Icons variant="mindsat" label="icon_search" width={20} />
              </Box>
            }
            isSearch
            borderNone
            placeholder={t("quiz.label.courseTitleHolder")}
            text={tempCourseName}
            hasText
            onChange={(e) => setTempCourseName(e.target.value)}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                if (e.target.value === "") return;
                if (
                  filterData.courseNames &&
                  filterData.courseNames.includes(e.target.value)
                ) {
                  return;
                }
                setFilterData((prev) => ({
                  ...prev,
                  courseNames: prev.courseNames
                    ? [...prev.courseNames, tempCourseName]
                    : [tempCourseName],
                }));
                setTempCourseName("");
              }
            }}
          />
        </Box>
        <Box>
          {filterData.courseNames &&
            filterData.courseNames.map((item) => (
              <CategoryTag
                key={item}
                text={item}
                endIcon={<Icons variant="common" label="delete" />}
                onClick={() =>
                  setFilterData((prev) => ({
                    ...prev,
                    courseNames: prev.courseNames?.filter((q) => q !== item),
                  }))
                }
              />
            ))}
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setTempCourseName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                if (tempCourseName === "") return;
                setTempCourseName("");
                if (
                  filterData.courseNames &&
                  filterData.courseNames.includes(tempCourseName)
                ) {
                  return;
                }
                setFilterData((prev) => ({
                  ...prev,
                  courseNames: prev.courseNames
                    ? [...prev.courseNames, tempCourseName]
                    : [tempCourseName],
                }));
              }}
            />
          </Box>
        </Box>
      </SettingButton>
      {/* end courseName  */}

      {/* history Result  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.historyResult === undefined
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.delivery")}
            {filterData.historyResult === undefined
              ? " · " + t("quiz.label.all")
              : filterData.historyResult === 1
              ? " · " + t("program.programSendWait")
              : filterData.historyResult === 2
              ? " · " + t("program.programSendSuccess")
              : " · " + t("program.programSendFail")}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={filterData.historyResult === undefined}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, historyResult: undefined }))
          }
        >
          {filterData.historyResult === undefined ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.historyResult === 1}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, historyResult: 1 }))
          }
        >
          {filterData.historyResult === 1 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.programSendWait")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.historyResult === 2}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, historyResult: 2 }))
          }
        >
          {filterData.historyResult === 2 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.programSendSuccess")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.historyResult === 3}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, historyResult: 3 }))
          }
        >
          {filterData.historyResult === 3 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.programSendFail")}
        </DropdownItem>
      </SettingButton>
      {/* end History Result  */}

      {/* completion Flag  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.completionFlag === undefined
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.learning")}
            {filterData.completionFlag === undefined
              ? " · " + t("quiz.label.all")
              : filterData.completionFlag === false
              ? " · " + t("quiz.label.notStarted")
              : " · " + t("quiz.label.completed")}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={filterData.completionFlag === undefined}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, completionFlag: undefined }))
          }
        >
          {filterData.completionFlag === undefined ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.completionFlag === false}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, completionFlag: false }))
          }
        >
          {filterData.completionFlag === false ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("quiz.label.notStarted")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.completionFlag}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, completionFlag: true }))
          }
        >
          {filterData.completionFlag ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("quiz.label.completed")}
        </DropdownItem>
      </SettingButton>
      {/* end completion Flag  */}
      <IconButton transparent onClick={resetFilter} classes="mg ml-8">
        <Icons variant="common" label="refresh" stroke="#B5BBC2" />
      </IconButton>
    </Box>
  );

  return (
    <Box classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.trainingSendAndResult")}
          </Typography>
        </Box>
        <Box>
          {historyList.length > 0 && (
            <Button
              color="secondary"
              startIcon={<Icons variant="file" label="download" />}
              onClick={() => exportQuizSendHistory(filterData)}
            >
              {t("common.button.exportToExcel")}
            </Button>
          )}
        </Box>
      </Box>
      <Box classes="mg mt-8" justifyContent="space-between">
        {/* {historyList.length > 0 && ( */}
        <>
          <Box alignItems="center">
            <Typography fontWeight={700} size={16}>
              {t("program.sendMail")}
            </Typography>
            <Typography
              classes="mg ml-8"
              size={16}
              color="green"
              fontWeight={700}
            >
              {historyListTotal?.toLocaleString()}
            </Typography>
          </Box>

          <Box>
            <Button
              startIcon={
                <Icons variant="common" label="filter" stroke="#252d38" />
              }
              color="transparent"
              onClick={() => {
                setIsFilterOpen((prev) => !prev);
              }}
            >
              {t("common.list.filter")}
            </Button>
          </Box>
        </>
        {/* )} */}
      </Box>

      {/* filter start  */}
      {isFilterOpen && filter}
      {/* filter end */}

      {/* table start  */}

      {/* table end  */}

      <Box
        direction="column"
        height={isFilterOpen ? "calc(100% - 170px)" : "calc(100% - 110px)"}
      >
        <Table classes="default-table mg mt-12">
          <colgroup>
            {/* 회차  */}
            <col style={{ width: "76px" }}></col>
            {/* 대상자 이름 */}
            <col style={{ width: "160px" }}></col>
            {/* 대상자 부서*/}
            <col style={{ width: "120px" }}></col>
            {/* 훈련 이름 */}
            <col style={{ width: "100%" }}></col>
            {/* 발송 회차 */}
            <col style={{ width: "80px" }}></col>
            {/* 발송 일시 */}
            <col style={{ width: "265px" }}></col>
            {/* 발송 결과 */}
            <col style={{ width: "80px" }}></col>
            {/* 학습 여부  */}
            <col style={{ width: `calc(120px + ${scrollbarWidth}px)` }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t("quiz.table.session")}</th>
              <th>{t("quiz.table.targets")}</th>
              <th>{t("quiz.table.depts")}</th>
              <th>{t("quiz.table.courseTitle")}</th>
              <th>{t("quiz.table.sent")}</th>
              <th>{t("quiz.table.sentDate")}</th>
              <th>{t("quiz.table.delivery")}</th>
              <th>{t("quiz.table.learning")}</th>
            </tr>
          </thead>
        </Table>
        <Box classes="table-scroll">
          {historyList.length === 0 ? (
            JSON.stringify(filterData) !==
            JSON.stringify({} as QuizSendHistoryReqDTO) ? (
              <Box
                width={"100%"}
                direction="column"
                // justifyContent="center"
                alignItems="center"
                classes="mg mt-60"
              >
                <NoSearchResultImage width={200} />
                <Typography classes="mg mt-36" size={18} fontWeight={700}>
                  {t("quiz.label.noSearch")}
                </Typography>
                <Typography classes="mg mt-8" size={16} color="secondary">
                  {t("quiz.msg.noFilterQuizSendHistory")}
                </Typography>
                <Button
                  classes="mg mt-16"
                  startIcon={
                    <Icons variant="common" label="refresh" stroke="white" />
                  }
                  onClick={resetFilter}
                >
                  {t("common.button.resetFilter")}
                </Button>
              </Box>
            ) : (
              <Box
                width={"100%"}
                direction="column"
                alignItems="center"
                classes="mg mt-60"
              >
                <NoDashboardImage />
                <Typography fontWeight={700} size={18}>
                  {t("quiz.msg.noItems")}
                </Typography>
                <Typography classes="mg mt-24" color={"secondary"}>
                  {t("program.msg.noSendMailInfoMsg")}
                </Typography>
              </Box>
            )
          ) : (
            <Table>
              <colgroup>
                {/* 회차  */}
                <col style={{ width: "76px" }}></col>
                {/* 대상자 이름 */}
                <col style={{ width: "160px" }}></col>
                {/* 대상자 부서*/}
                <col style={{ width: "120px" }}></col>
                {/* 훈련 이름 */}
                <col style={{ width: "100%" }}></col>
                {/* 발송 회차 */}
                <col style={{ width: "80px" }}></col>
                {/* 발송 일시 */}
                <col style={{ width: "265px" }}></col>
                {/* 발송 결과 */}
                <col style={{ width: "80px" }}></col>
                {/* 학습 여부  */}
                <col
                  style={{ width: `calc(120px + ${scrollbarWidth}px)` }}
                ></col>
              </colgroup>
              <tbody>
                {historyList.map((h) => (
                  <tr>
                    <td>{h.courseId}</td>
                    <td>{h.userName}</td>
                    <td>{h.deptName}</td>
                    <td>{h.courseName}</td>
                    <td>{`${h.courseSendIdx} / ${h.courseSendCount}`}</td>
                    <td>{utilsCommon.getFullDateStr(h.sendingDate)}</td>
                    <td>
                      {h.historyResult === 1 ? (
                        <Box classes="dot__container">
                          <Box classes="dot yellow__dot" />
                          <Typography>
                            {t("program.programSendWait")}
                          </Typography>
                        </Box>
                      ) : h.historyResult === 2 ? (
                        <Box classes="dot__container">
                          <Box classes="dot green__dot" />
                          <Typography>
                            {t("program.programSendSuccess")}
                          </Typography>
                        </Box>
                      ) : (
                        <Box classes="dot__container">
                          <Box classes="dot red__dot" />
                          <Typography>
                            {t("program.programSendFail")}
                          </Typography>
                        </Box>
                      )}
                    </td>
                    <td>
                      {h.historyResult === 2 ? (
                        h.completionFlag ? (
                          <Box classes="dot__container">
                            <Box classes="dot blue__dot" />
                            <Typography>{t("quiz.label.completed")}</Typography>
                          </Box>
                        ) : (
                          <Box alignItems="center">
                            <Box
                              classes={
                                h.resendFlag
                                  ? `dot__container resend__container`
                                  : `dot__container`
                              }
                            >
                              <Box classes="dot gray__dot" />
                              <Typography classes="resend__text">
                                {t("quiz.label.notStarted")}
                              </Typography>
                              <Box
                                classes="resend__button"
                                onClick={() =>
                                  setIsResendModalOpen(h.sendHistoryId)
                                }
                              >
                                재전송
                              </Box>
                            </Box>
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Box>
      </Box>
      {isResendModalOpen !== -1 && (
        <CommonModal
          open={isResendModalOpen !== -1}
          title={t("quiz.label.resendMail")}
          subMessage={t("quiz.msg.resendConfirm")}
          onClose={() => setIsResendModalOpen(-1)}
          afterSubmitButton={onResendMail}
          footer={
            <Box justifyContent="flex-end">
              <Button
                text={t("common.button.cancel")}
                color="secondary"
                onClick={() => setIsResendModalOpen(-1)}
              />

              <Button
                text={t("quiz.label.resend")}
                classes={"mg ml-8"}
                color="primary"
                onClick={onResendMail}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default QuizResult;
