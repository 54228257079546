const NoSelectedUserImage = ({
  classes,
  width,
  height,
}: {
  classes?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width || "200"}
      height={height || "120"}
      className={classes}
      viewBox="0 0 200 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.535 94.285H75.4999C71.6711 94.285 68.5596 91.1734 68.5596 87.3447V31.7528C68.5596 27.9241 71.6711 24.8125 75.4999 24.8125H117.535C121.364 24.8125 124.475 27.9241 124.475 31.7528V87.3447C124.475 91.185 121.364 94.285 117.535 94.285Z"
        fill="#DDE1E7"
      />
      <path
        d="M96.5185 58.6337C99.9363 58.6337 102.707 55.863 102.707 52.4452C102.707 49.0275 99.9363 46.2568 96.5185 46.2568C93.1007 46.2568 90.3301 49.0275 90.3301 52.4452C90.3301 55.863 93.1007 58.6337 96.5185 58.6337Z"
        fill="#A5AEB8"
      />
      <path
        d="M90.6648 61.1309H102.371C105.957 61.1309 108.86 64.0342 108.86 67.62C108.86 70.5118 106.523 72.8484 103.632 72.8484H89.3924C86.5007 72.8484 84.1641 70.5118 84.1641 67.62C84.1756 64.0342 87.079 61.1309 90.6648 61.1309Z"
        fill="#A5AEB8"
      />
      <path
        d="M126.814 31.0371H124.188V41.4476H126.814C127.919 41.4476 128.814 40.5521 128.814 39.4476V33.0371C128.814 31.9325 127.919 31.0371 126.814 31.0371Z"
        fill="#B2BAC2"
      />
      <path
        d="M126.814 46.0742H124.188V56.4847H126.814C127.919 56.4847 128.814 55.5892 128.814 54.4847V48.0742C128.814 46.9696 127.919 46.0742 126.814 46.0742Z"
        fill="#B2BAC2"
      />
      <path
        d="M126.814 61.1133H124.188V71.5237H126.814C127.919 71.5237 128.814 70.6283 128.814 69.5237V63.1133C128.814 62.0087 127.919 61.1133 126.814 61.1133Z"
        fill="#B2BAC2"
      />
      <path
        d="M56.472 21.7026C55.7201 21.7026 55.1187 21.0895 55.1187 20.3492V17.3533C55.1187 16.6015 55.7317 16 56.472 16C57.2239 16 57.8254 16.613 57.8254 17.3533V20.3492C57.8369 21.0895 57.2239 21.7026 56.472 21.7026Z"
        fill="#FFCC75"
      />
      <path
        d="M56.472 30.9214C55.7201 30.9214 55.1187 30.3083 55.1187 29.568V26.5721C55.1187 25.8202 55.7317 25.2188 56.472 25.2188C57.2239 25.2188 57.8254 25.8318 57.8254 26.5721V29.568C57.8369 30.3199 57.2239 30.9214 56.472 30.9214Z"
        fill="#FFCC75"
      />
      <path
        d="M58.2319 23.4588C58.2319 22.7069 58.845 22.1055 59.5853 22.1055H62.5812C63.333 22.1055 63.9345 22.7185 63.9345 23.4588C63.9345 24.2107 63.3215 24.8122 62.5812 24.8122H59.5853C58.845 24.8122 58.2319 24.1991 58.2319 23.4588Z"
        fill="#FFCC75"
      />
      <path
        d="M49 23.4588C49 22.7069 49.6131 22.1055 50.3534 22.1055H53.3493C54.1011 22.1055 54.7026 22.7185 54.7026 23.4588C54.7026 24.2107 54.0896 24.8122 53.3493 24.8122H50.3534C49.6131 24.8122 49 24.1991 49 23.4588Z"
        fill="#FFCC75"
      />
      <path
        d="M139.998 103.999C145.843 103.999 150.582 99.2604 150.582 93.4151C150.582 87.5697 145.843 82.8311 139.998 82.8311C134.153 82.8311 129.414 87.5697 129.414 93.4151C129.414 99.2604 134.153 103.999 139.998 103.999Z"
        fill="#ADCDFB"
      />
      <path
        d="M136.666 89.7255C136.863 89.3206 137.129 88.9967 137.476 88.7422C137.823 88.4877 138.216 88.3027 138.667 88.187C139.107 88.0713 139.569 88.002 140.044 88.002C140.68 88.002 141.27 88.106 141.813 88.3142C142.357 88.5224 142.785 88.8463 143.109 89.2743C143.433 89.7023 143.595 90.2344 143.595 90.8822C143.595 91.3333 143.514 91.715 143.363 92.0157C143.213 92.3165 143.016 92.5709 142.773 92.7791C142.53 92.9873 142.288 93.1725 142.045 93.346C141.744 93.5426 141.513 93.7161 141.316 93.8665C141.131 94.0168 140.992 94.1672 140.911 94.3407C140.83 94.5143 140.784 94.734 140.784 95.0117V95.3817H138.632L138.621 95.0347C138.586 94.6415 138.609 94.2945 138.702 93.9822C138.794 93.6699 138.945 93.3922 139.153 93.1493C139.361 92.9064 139.616 92.675 139.928 92.49C140.286 92.2586 140.576 92.0389 140.795 91.8307C141.015 91.634 141.119 91.3564 141.119 91.021C141.119 90.7549 141.061 90.5467 140.946 90.3848C140.83 90.2228 140.68 90.1072 140.495 90.0262C140.31 89.9452 140.113 89.9105 139.905 89.9105C139.697 89.9105 139.523 89.9452 139.373 90.0031C139.222 90.0725 139.095 90.1534 138.991 90.2807C138.887 90.3963 138.806 90.5351 138.748 90.6971C138.69 90.859 138.667 91.0209 138.667 91.206H136.435C136.388 90.6161 136.469 90.1303 136.666 89.7255ZM138.667 96.631C138.933 96.3997 139.303 96.284 139.766 96.284C140.24 96.284 140.61 96.3997 140.876 96.631C141.154 96.8624 141.281 97.1747 141.281 97.5564C141.281 97.9265 141.142 98.2273 140.876 98.4702C140.599 98.7016 140.229 98.8172 139.766 98.8172C139.303 98.8172 138.945 98.7016 138.667 98.4702C138.401 98.2389 138.262 97.9381 138.262 97.5564C138.262 97.1747 138.401 96.8739 138.667 96.631Z"
        fill="white"
      />
    </svg>
  );
};

export default NoSelectedUserImage;
